import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CreateFooter, PageHeader } from "../../../components/layout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Loader } from "rsuite";
import { AdNetworkCodeType, AdNetworkType } from "../../../utils/types";
import AdNetworkUnitForm, { AdNetworkUnitFormProps } from "./AdNetworkUnitForm";
import { initFormValue } from "./CreateAdNetworkUnit";
import { AdNetworkUnitService } from "../../../utils/api";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";

const EditAdNetworkUnit = () => {
  const { id = "" } = useParams<{ id: string }>();
  const [adNetwork, setAdNetwork] = useState<
    | {
        id: number;
        enabled: boolean;
        code: AdNetworkCodeType;
        type: AdNetworkType;
        publisher: {
          id: number;
          name: string;
        };
      }
    | undefined
  >(undefined);
  const [formValue, setFormValue] =
    useState<AdNetworkUnitFormProps["formValue"]>(initFormValue);
  const [placements, setPlacements] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  const fetchData = useCallback(async () => {
    const { data } = await new AdNetworkUnitService().getDetail(id);
    setFormValue({
      id: data.id,
      name: data.name,
      screen:
        data.platform === "PC" || data.platform === "IPTV"
          ? data.platform
          : "MOBILE",
      platform: data.application,
      mobile_os:
        data.platform === "PC" || data.platform === "IPTV"
          ? null
          : data.platform,
      width: data.width === 0 ? "" : data.width.toString(),
      height: data.height === 0 ? "" : data.height.toString(),
      type: data.type,
      skip_option: data.skip_option,
      pub_key: data.spec?.pub_key,
      media_id: data.spec?.media_id,
      section_id: data.spec?.section_id,
      app_id: data.spec?.app_id,
      unit_id: data.spec?.unit_id,
      mc: data.spec?.mc,
      site_id: data.spec?.site_id,
      ad_id: data.spec?.ad_id,
      store_url: data.spec?.store_url,
      bundle: data.spec?.bundle,
      mcode: data.spec?.mcode,
      pkgname: data.spec?.pkgname,
      media_key: data.spec?.media_key,
      adunit_id: data.spec?.adunit_id,
      pack_name: data.spec?.pack_name,
      tag: data.spec?.tag,
      bid_floor: data.spec?.bid_floor,
      app_name: data.spec?.app_name,
      ifa_type: data.spec?.ifa_type,
    });
    setPlacements(data.placements);
    setAdNetwork({
      id: data.ad_network.id,
      code: data.ad_network.code,
      enabled: data.ad_network.enabled,
      type: data.ad_network.type,
      publisher: { ...data.publisher },
    });
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = useCallback(
    async (validate: boolean) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { hasError } = await formRef?.current?.checkAsync();
      if (!validate || hasError) return;

      try {
        setLoading(true);
        if (!formValue) return;
        const { name } = formValue;

        await new AdNetworkUnitService().editName(id as string, name);

        navigate(`/inventory/ad-networks?selected-tab=ad-network-unit`, {
          state: {
            adNetwork: adNetwork?.id,
            publisher: adNetwork?.publisher.id,
          },
        });
      } catch (e) {
        setErrorModalOpen(true);
      } finally {
        setLoading(false);
      }
    },
    [formValue, id, navigate, adNetwork, setErrorModalOpen]
  );

  return (
    <>
      <PageHeader title={"유닛 조회"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}
      <AdNetworkUnitForm
        editMode={true}
        adNetwork={adNetwork}
        formValue={formValue}
        onChange={setFormValue}
        placements={placements}
        renderFooter={() => (
          <CreateFooter>
            <Link
              to={"/inventory/ad-networks?selected-tab=ad-network-unit"}
              state={{
                adNetwork: adNetwork?.id,
                publisher: adNetwork?.publisher.id,
              }}
            >
              <Button>취소</Button>
            </Link>
            <Button appearance={"primary"} type="submit">
              저장
            </Button>
          </CreateFooter>
        )}
        onSubmit={handleSubmit}
        formRef={formRef}
      />
    </>
  );
};

export default EditAdNetworkUnit;
