import React from "react";
import { Table, CellProps, Checkbox } from "rsuite";
import { EllipsisPopup } from "components";
import moment from "moment";
import { ValueType } from "rsuite/Checkbox";
import { Link, LinkProps } from "react-router-dom";
import { AD_REVIEW_STATUS } from "../../utils/variables";
import { Icon } from "@rsuite/icons";
import { VscCircleLargeFilled } from "react-icons/vsc";
import { AdReviewStatusType } from "../../utils/types";
import { numberWithCommas } from "../../utils";
const { Cell } = Table;

const TextCell = ({ rowData, dataKey, text, ...props }: TextCellProps) => {
  const dataKeys = dataKey?.split(".") || [];
  const value = dataKeys.reduce(
    (acc: any, key: string) => (acc ? acc[key] : rowData[key]),
    undefined
  );

  return (
    <Cell {...props}>
      <EllipsisPopup text={text ? text : value ? value : "-"} />
    </Cell>
  );
};

const DateCell = ({ rowData, dataKey, format, ...props }: DateCellProps) => {
  const dataKeys = dataKey?.split(".") || [];
  const value = dataKeys.reduce(
    (acc: any, key: string) => (acc ? acc[key] : rowData[key]),
    undefined
  );

  return (
    <Cell {...props}>
      <EllipsisPopup
        text={
          value ? moment(value).format(format || "YYYY-MM-DD HH:mm:ss") : "-"
        }
      />
    </Cell>
  );
};

const CheckCell = ({
  rowData,
  onChange,
  checkedKeys,
  dataKey,
  disabledKeys,
  ...props
}: CheckCellProps) => {
  const dataKeys = dataKey?.split(".") || [];
  const value = dataKeys.reduce(
    (acc: any, key: string) => (acc ? acc[key] : rowData[key]),
    undefined
  );

  return (
    <Cell {...props} style={{ padding: 0 }}>
      <div style={{ lineHeight: "46px" }}>
        <Checkbox
          value={value ? value : undefined}
          inline
          onChange={onChange}
          checked={checkedKeys.some((item) => dataKey && item === value)}
          disabled={disabledKeys?.some((item) => dataKey && item === value)}
        />
      </div>
    </Cell>
  );
};

const LinkCell = ({ rowData, dataKey, to, state, ...props }: LinkCellProps) => {
  const dataKeys = dataKey?.split(".") || [];
  const value = dataKeys.reduce(
    (acc: any, key: string) => (acc ? acc[key] : rowData[key]),
    undefined
  );

  return (
    <Cell {...props}>
      <EllipsisPopup
        text={
          value ? (
            <Link to={to} state={state}>
              {value}
            </Link>
          ) : null
        }
      />
    </Cell>
  );
};

const EnabledCell = ({ rowData, dataKey, ...props }: CellProps) => (
  <Cell {...props}>
    {dataKey ? (rowData[dataKey] ? "활성" : "비활성") : ""}
  </Cell>
);

const MapCell = ({ rowData, dataKey, mapper, ...props }: MapCellProps) => {
  const dataKeys = dataKey?.split(".") || [];
  const value = dataKeys.reduce(
    (acc: any, key: string) => (acc ? acc[key] : rowData[key]),
    undefined
  );

  return (
    <Cell {...props}>
      <EllipsisPopup text={value && mapper[value] ? mapper[value] : "-"} />
    </Cell>
  );
};

const NumberCell = ({ rowData, dataKey = "", ...props }: CellProps) => {
  const isFloat = (n: number) => Number(n) === n && n % 1 !== 0;
  const result =
    rowData[dataKey] === undefined || rowData[dataKey] === null
      ? "-"
      : isFloat(rowData[dataKey])
      ? Math.floor(rowData[dataKey])
      : rowData[dataKey];

  return (
    <Cell {...props}>
      <EllipsisPopup text={numberWithCommas(result)} />
    </Cell>
  );
};

const FloatCell = ({ rowData, dataKey = "", ...props }: CellProps) => {
  const count = Math.pow(10, 2);
  const result =
    rowData[dataKey] === undefined || rowData[dataKey] === null
      ? "-"
      : Math.floor(rowData[dataKey] * count) / count; //소수점 2번째까지, 3번째에서 버림

  return (
    <Cell {...props}>
      <EllipsisPopup text={numberWithCommas(result)} />
    </Cell>
  );
};

const PercentCell = ({ rowData, dataKey = "", ...props }: CellProps) => {
  const count = Math.pow(10, 2);
  const result =
    rowData[dataKey] === undefined || rowData[dataKey] === null
      ? "-"
      : Math.round(rowData[dataKey] * count) / count; //소수점 2번째까지,  3번째에서 반올림

  return (
    <Cell {...props}>
      <EllipsisPopup
        text={result === "-" ? result : `${numberWithCommas(result)}%`}
      />
    </Cell>
  );
};

const ReviewStatusCell = ({
  rowData,
  dataKey,
  ...props
}: ReviewStatusCellProps) => {
  const mapper = AD_REVIEW_STATUS;
  const status: AdReviewStatusType | "" = dataKey ? rowData[dataKey] : "";
  const colors: { [key in AdReviewStatusType]: string } = {
    AUTO_APPROVED: "var(--rs-state-success)",
    BLOCKED: "var(--rs-state-error)",
    WAITING_REVIEW: "var(--rs-state-warning)",
    APPROVED: "var(--rs-state-success)",
    DECLINED: "var(--rs-state-error)",
  };

  return (
    <Cell {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon
          as={VscCircleLargeFilled}
          style={{ color: status ? colors[status] : "", marginRight: 5 }}
        />
        {status && mapper[status] ? mapper[status] : "-"}
      </div>
    </Cell>
  );
};
export {
  TextCell,
  DateCell,
  CheckCell,
  LinkCell,
  EnabledCell,
  MapCell,
  ReviewStatusCell,
  NumberCell,
  FloatCell,
  PercentCell,
};

interface TextCellProps extends CellProps {
  text?: React.ReactElement | string;
}
interface DateCellProps extends CellProps {
  format?: string;
}
interface CheckCellProps extends CellProps {
  onChange?: (value: ValueType | undefined, checked: boolean) => void;
  checkedKeys: ValueType[];
  disabledKeys?: ValueType[];
}
interface LinkCellProps extends CellProps {
  to: LinkProps["to"];
  state?: LinkProps["state"];
}
interface MapCellProps extends CellProps {
  mapper: {
    [key: string]: string;
  };
}

type ReviewStatusCellProps = CellProps;
