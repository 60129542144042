import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, CellProps, Modal, Popover, Stack, Whisper } from "rsuite";
import { Filter, InfoTooltip, Search, SelectPicker, Table } from "components";
import { Link, useLocation } from "react-router-dom";
import { DateCell, LinkCell, MapCell, TextCell } from "components/table/Cells";
import {
  CREATIVE_TYPE,
  MOBILE_OS_TYPE,
  PLATFORM_TYPE,
  SCREEN_TYPE,
} from "utils/variables";
import { AdNetworkCodeType, SortType } from "utils/types";
import { ValueType } from "rsuite/Checkbox";
import {
  AccountService,
  AdNetworkService,
  AdNetworkUnitService,
} from "../../../utils/api";
import { getFilterStringFromObject, underToUpper } from "../../../utils";
import {
  getComparatorsString,
  getOperatorsString,
} from "../../../front-utils/filter/dynamicFilter";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { AuthContext } from "../../../utils/context/AuthContext";
import { TableColumns } from "../../../components/table/Table";
import fileDownload from "js-file-download";
import moment from "moment";
import { TableActionsContainer } from "components/layout";
import _ from "lodash";
import { StarFilled, StarOutlined } from "@ant-design/icons";

const AdNetworkUnitTable = (props: { publisher: any }) => {
  const state = useLocation().state || {};
  const { adNetwork: adNetworkByLocation = null } = state as LocationState;
  const [adNetworks, setAdNetworks] = useState<
    {
      code: AdNetworkCodeType;
      id: number;
      [key: string]: any;
    }[]
  >([]);
  const [adNetworkPickerData, setAdNetworkPickerData] = useState<
    { label: string; value: number; isDefault: boolean }[]
  >([]);
  const [adNetwork, setAdNetwork] = useState<number | null>(
    adNetworkByLocation
  );
  const [filterValue, setFilterValue] = useState<ValueType[]>([]);
  const [filterTempValue, setFilterTempValue] = useState<ValueType[]>([]);
  const [disabledItemValues, setDisabledItemValues] = useState<ValueType[]>([
    "IOS",
    "ANDROID",
  ]);
  const [data, setData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [checkedKeys, setCheckedKeys] = useState<ValueType[]>([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  }>({
    sortColumn: "created_at",
    sortType: "desc",
  });
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [appliedSearchValue, setAppliedSearchValue] =
    useState<string>(searchValue);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const { myInfo } = useContext(AuthContext);
  const [tableLoading, setTableLoading] = useState(false);
  const { publisher } = props;

  const handleFilterSelect = useCallback((value: ValueType[]) => {
    setFilterValue(value);
  }, []);

  const handleFilterItemCheck = useCallback((value: ValueType[]) => {
    if (!value.includes("MOBILE")) {
      setFilterTempValue(value.filter((s) => s !== "IOS" && s !== "ANDROID"));
      setDisabledItemValues((prev) => prev.concat(["IOS", "ANDROID"]));
    } else {
      setDisabledItemValues((prev) =>
        prev.filter((s) => s !== "IOS" && s !== "ANDROID")
      );
    }
  }, []);

  const handleCheck = useCallback(
    (value: ValueType | undefined, checked: boolean) => {
      if (!value) return;
      const nextCheckedKeys = checked
        ? [...checkedKeys, value]
        : checkedKeys.filter((item) => item !== value);

      setCheckedKeys(nextCheckedKeys);
    },
    [checkedKeys]
  );

  const handleCheckAll = useCallback(
    (_, checked: boolean) => {
      const allCheckedKeys = checked ? data.map((item) => item.id) : [];
      setCheckedKeys(allCheckedKeys);
    },
    [data]
  );

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  const fetchAdNetworks = useCallback(async () => {
    try {
      const { data } = await new AdNetworkService().getNameCode({
        sort: "name,asc",
        filter: publisher ? `publisher.id : ${publisher}` : "",
      });

      setAdNetworks(data);
    } catch (e) {
      console.log(e);
    }
  }, [publisher]);
  const getFetchParams = useCallback(() => {
    return {
      page,
      size,
      sort: underToUpper(sort.sortColumn) + "," + sort.sortType,
      filter: getOperatorsString(
        "and",
        [
          publisher ? getComparatorsString(":", "publisher.id", publisher) : "",
          adNetwork ? getComparatorsString(":", "adNetwork.id", adNetwork) : "",
          appliedSearchValue !== "" && searchKey === "id"
            ? getComparatorsString(":", searchKey, appliedSearchValue)
            : "",
          appliedSearchValue !== "" && searchKey === "name"
            ? getComparatorsString("~", searchKey, `%${appliedSearchValue}%`)
            : "",
          ...getFilterStringFromObject(
            filterValue,
            filterData,
            (key, value) => {
              if (key === "platform" && value.includes("MOBILE")) {
                const newValue = [...value.filter((v) => v !== "MOBILE")];

                if (!value.includes("IOS") && !value.includes("ANDROID")) {
                  newValue.push("IOS");
                  newValue.push("ANDROID");
                }
                return newValue;
              } else return value;
            }
          ),
        ].filter((v) => v !== "")
      ),
    };
  }, [
    adNetwork,
    appliedSearchValue,
    filterValue,
    page,
    publisher,
    searchKey,
    size,
    sort.sortColumn,
    sort.sortType,
  ]);

  const fetchAdNetworkUnits = useCallback(async () => {
    try {
      if (!adNetwork) return;
      setTableLoading(true);
      const { data } = await new AdNetworkUnitService().get(getFetchParams());

      setData(data.content);
      setTotal(data.total_elements);
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }, [adNetwork, getFetchParams]);

  const columns: () => TableColumns[] = useMemo(
    () => () => {
      const columns: TableColumns[] = [
        {
          title: "네트워크 유닛 ID",
          key: "id",
          sortable: true,
          width: 150,
        },
        {
          title: "네트워크 유닛",
          key: "name",
          sortable: true,
          width: 300,
          flexGrow: 1,
          Cell: (props: CellProps) => (
            <LinkCell
              to={`/inventory/ad-network-unit/edit/${props.rowData.id}`}
              {...props}
            />
          ),
        },
        {
          title: "스크린",
          key: "platform",
          sortable: true,
          width: 120,
          Cell: (props: CellProps) => (
            <MapCell mapper={SCREEN_TYPE} {...props} />
          ),
        },
        {
          title: "플랫폼",
          key: "application",
          sortable: true,
          width: 120,
          Cell: (props: CellProps) => (
            <MapCell mapper={PLATFORM_TYPE} {...props} />
          ),
        },
        {
          title: "모바일OS",
          key: "platform",
          sortable: true,
          width: 130,
          Cell: (props: CellProps) => (
            <MapCell mapper={MOBILE_OS_TYPE} {...props} />
          ),
        },
        {
          title: "소재 사이즈",
          key: "width",
          sortable: true,
          width: 110,
          Cell: (props: CellProps) => (
            <TextCell
              text={
                props.rowData?.width
                  ? `${props.rowData?.width}x${props.rowData?.height}`
                  : "미지정"
              }
              {...props}
            />
          ),
        },
        {
          title: "소재 타입",
          key: "type",
          sortable: true,
          width: 120,
          Cell: (props: CellProps) => (
            <MapCell mapper={CREATIVE_TYPE} {...props} />
          ),
        },
        ...getSpecColumns(adNetworks.find((d) => d.id === adNetwork)?.code),
        {
          title: "게재위치",
          key: "placement_count",
          sortable: true,
          width: 100,
        },
        {
          title: "등록일",
          key: "created_at",
          sortable: true,
          width: 150,
          Cell: DateCell,
        },
        {
          title: "업데이트일",
          key: "updated_at",
          sortable: true,
          width: 160,
          Cell: DateCell,
        },
      ];

      if (myInfo?.role === "MASTER") {
        columns.unshift({
          title: "매체사",
          key: "publisher.name",
          sortable: true,
          width: 150,
        } as TableColumns);
      }
      return columns;
    },
    [adNetwork, adNetworks, myInfo?.role]
  );

  const handleClickRemove = useCallback(async () => {
    if (
      data
        .filter((d) => checkedKeys.includes(d.id))
        .some((d) => d.placement_count > 0)
    ) {
      setOpenAlertModal(true);
      return;
    }

    try {
      await new AdNetworkUnitService().deleteList(checkedKeys);
      await fetchAdNetworkUnits();
      setCheckedKeys([]);
    } catch (e) {
      setErrorModalOpen(true);
    }
  }, [checkedKeys, data, fetchAdNetworkUnits, setErrorModalOpen]);

  const fetchData = useCallback(async () => {
    await fetchAdNetworks();
    await fetchAdNetworkUnits();
    setCheckedKeys([]);
  }, [fetchAdNetworkUnits, fetchAdNetworks]);

  const handleClickDownload = useCallback(async () => {
    try {
      if (!adNetwork) return;
      const { data } = await new AdNetworkUnitService().download({
        ..._.omit(getFetchParams(), ["page", "size"]),
        "ad-network-id": adNetwork,
      });
      fileDownload(
        data,
        `${
          adNetworks.find((n) => n.id === adNetwork)?.name || ""
        }_네트워크유닛_${moment().format("YYYYMMDD")}.xlsx`
      );
    } catch (e) {
      console.log(e);
    }
  }, [adNetwork, adNetworks, getFetchParams]);

  const handleSearchKeyChange = useCallback(
    (v: "code" | "name" | "placementGroup.name" | "lastUpdater") => {
      setSearchKey(v);
      setSearchValue("");
    },
    []
  );

  const delayedSetSearchValue = useMemo<any>(
    () => _.debounce((v: string) => setAppliedSearchValue(v), 500),
    []
  );

  const handleSearchValueChange = useCallback((v: string) => {
    setSearchValue(v);
  }, []);

  const handleClickStar = useCallback(
    async (e: any, value: number) => {
      e.stopPropagation();
      try {
        await new AccountService().editDefaultAdNetworkId(value);
        setAdNetworkPickerData((adNetworks) =>
          adNetworks.map((adNetwork) => ({
            ...adNetwork,
            isDefault: value === adNetwork.value,
          }))
        );
      } catch {
        setErrorModalOpen(true);
      }
    },
    [setErrorModalOpen]
  );

  useEffect(() => {
    //디폴트 네트워크 선택
    if (myInfo?.default_ad_network_id && adNetwork === null) {
      setAdNetwork(myInfo?.default_ad_network_id);
    } else if (
      adNetworks.length > 0 &&
      (adNetwork === null ||
        !adNetworks.find((d: { id: number }) => d.id === adNetwork))
    ) {
      setAdNetwork(adNetworks[0]?.id);
    }

    setAdNetworkPickerData(
      adNetworks.map((d) => ({
        label: `${d.name}(${d.type})`,
        value: d.id,
        isDefault: myInfo?.default_ad_network_id === d.id,
      }))
    );
  }, [adNetwork, adNetworks, myInfo?.default_ad_network_id]);

  useEffect(() => {
    setPage(1);
  }, [size, adNetwork, props.publisher]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    delayedSetSearchValue(searchValue);
  }, [searchValue, delayedSetSearchValue]);

  return (
    <>
      <Stack
        style={{
          padding: "9px 25px",
          borderBottom: "1px solid var(--rs-border-primary)",
        }}
        spacing={8}
      >
        <span
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "var(--rs-gray-800)",
          }}
        >
          네트워크 선택
        </span>
        <SelectPicker
          value={adNetwork}
          onChange={setAdNetwork}
          data={adNetworkPickerData}
          cleanable={false}
          renderValue={
            adNetworkPickerData.find((pg) => pg.value === adNetwork)
              ? (value: number, item: any, selectedElement: ReactNode) => (
                  <div>
                    {selectedElement}
                    <Whisper
                      trigger={"hover"}
                      placement={"top"}
                      speaker={
                        <Popover>
                          선택한 게재위치그룹이 광고 검수 기본 페이지가 됩니다,
                        </Popover>
                      }
                    >
                      <span
                        style={{
                          marginLeft: 10,
                          float: "right",
                          color: "var(--rs-yellow-500)",
                        }}
                      >
                        {item?.isDefault ? (
                          <StarFilled />
                        ) : (
                          <StarOutlined
                            onClick={(e) => handleClickStar(e, item.value)}
                          />
                        )}
                      </span>
                    </Whisper>
                  </div>
                )
              : undefined
          }
        />
      </Stack>
      <TableActionsContainer>
        <Stack spacing={8}>
          <Button
            disabled={checkedKeys.length === 0}
            onClick={handleClickRemove}
          >
            삭제
          </Button>
          <Link
            to={{ pathname: "/inventory/ad-network-unit/new" }}
            state={{ adNetwork, publisher }}
          >
            <Button appearance="primary" disabled={!adNetwork}>
              유닛 등록
            </Button>
          </Link>
        </Stack>
        <Stack spacing={8}>
          <Filter
            data={filterData}
            value={filterValue}
            checkedValue={filterTempValue}
            setCheckedValue={setFilterTempValue}
            onSelect={handleFilterSelect}
            onItemCheck={handleFilterItemCheck}
            placement={"bottomEnd"}
            disabledItemValues={disabledItemValues}
            renderGroupTitle={(title: string) => {
              if (title === "모바일OS")
                return (
                  <>
                    {title}{" "}
                    <InfoTooltip
                      inner={"스크린 'Mobile' 선택시 활성화됩니다."}
                    />
                  </>
                );
              return title;
            }}
          />
          <Search
            data={searchKeys}
            onSearchKeyChange={handleSearchKeyChange}
            searchKey={searchKey}
            searchValue={searchValue}
            onSearchValueChange={handleSearchValueChange}
          />
          <Button onClick={handleClickDownload}>다운로드</Button>
        </Stack>
      </TableActionsContainer>
      <Table
        data={data}
        columns={columns()}
        checkable={true}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        checkedKeys={checkedKeys}
        onCheck={handleCheck}
        onCheckAll={handleCheckAll}
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
        onSortColumn={handleSortChange}
        loading={tableLoading}
      />

      <Modal
        size={"xs"}
        open={openAlertModal}
        onClose={() => setOpenAlertModal(false)}
        role="alertdialog"
      >
        <Modal.Header>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          해당 유닛을 이용중인 게재위치가 있어 삭제할 수 없습니다.
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenAlertModal(false)} appearance="primary">
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AdNetworkUnitTable;

const getSpecColumns = (code: AdNetworkCodeType | undefined) => {
  if (code === "MEZZO")
    return [
      {
        title: "publisher id",
        key: "pub_key",
        width: 120,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.pub_key} {...props} />
        ),
      },
      {
        title: "media id",
        key: "media_id",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.media_id} {...props} />
        ),
      },
      {
        title: "section id",
        key: "section_id",
        width: 120,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.section_id} {...props} />
        ),
      },
    ];
  if (code === "EXELBID")
    return [
      {
        title: "bundle",
        key: "app_id",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.app_id} {...props} />
        ),
      },
      {
        title: "unit id",
        key: "unit_id",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.unit_id} {...props} />
        ),
      },
    ];
  if (code === "DIGITALCAMP")
    return [
      {
        title: "mc",
        key: "mc",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.mc} {...props} />
        ),
      },
    ];
  if (code === "CAULY")
    return [
      {
        title: "unit id",
        key: "unit_id",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.unit_id} {...props} />
        ),
      },
    ];
  if (code === "UPLUSAD")
    return [
      {
        title: "unit id",
        key: "unit_id",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.unit_id} {...props} />
        ),
      },
    ];
  if (code === "PUBMATIC")
    return [
      {
        title: "site id",
        key: "site_id",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.site_id} {...props} />
        ),
      },
      {
        title: "ad id",
        key: "ad_id",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.ad_id} {...props} />
        ),
      },
      {
        title: "storeurl",
        key: "store_url",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.store_url} {...props} />
        ),
      },
      {
        title: "bundle",
        key: "bundle",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.bundle} {...props} />
        ),
      },
    ];
  if (code === "REALCLICK")
    return [
      {
        title: "mcode",
        key: "mcode",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.mcode} {...props} />
        ),
      },
      {
        title: "pkgname",
        key: "pkgname",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.pkgname} {...props} />
        ),
      },
    ];
  if (code === "NASMEDIA")
    return [
      {
        title: "media key",
        key: "media_key",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.media_key} {...props} />
        ),
      },
      {
        title: "adunit id",
        key: "adunit_id",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.adunit_id} {...props} />
        ),
      },
      {
        title: "pack name",
        key: "pack_name",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.pack_name} {...props} />
        ),
      },
    ];
  if (code === "PTBWA")
    return [
      {
        title: "tag",
        key: "tag",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.tag} {...props} />
        ),
      },
      {
        title: "bid floor",
        key: "bid_floor",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.bid_floor} {...props} />
        ),
      },
      {
        title: "bundle",
        key: "bundle",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.bundle} {...props} />
        ),
      },
      {
        title: "app name",
        key: "app_name",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.app_name} {...props} />
        ),
      },
      {
        title: "ifa type",
        key: "ifa_type",
        width: 100,
        Cell: (props: CellProps) => (
          <TextCell text={props.rowData?.spec?.ifa_type} {...props} />
        ),
      },
    ];
  return [];
};

type LocationState = {
  adNetwork?: number;
};
const searchKeys = [
  {
    label: "네트워크 유닛 ID",
    value: "id",
  },
  {
    label: "네트워크 유닛",
    value: "name",
  },
];

const filterData = [
  { label: "PC", value: "PC", group: "스크린", field: "platform" },
  { label: "Mobile", value: "MOBILE", group: "스크린", field: "platform" },
  { label: "IPTV", value: "IPTV", group: "스크린", field: "platform" },
  { label: "Web", value: "WEB", group: "플랫폼", field: "application" },
  { label: "Application", value: "APP", group: "플랫폼", field: "application" },
  { label: "iOS", value: "IOS", group: "모바일OS", field: "platform" },
  { label: "Android", value: "ANDROID", group: "모바일OS", field: "platform" },
  { label: "이미지", value: "IMAGE", group: "소재 타입", field: "type" },
  { label: "동영상", value: "VIDEO", group: "소재 타입", field: "type" },
];
