import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TableActionsContainer } from "../../../components/layout";
import { Button, CellProps, Dropdown, Stack } from "rsuite";
import { Link } from "react-router-dom";
import { Filter, Table } from "../../../components";
import { ValueType } from "rsuite/Checkbox";
import { SortType } from "../../../utils/types";
import {
  DateCell,
  EnabledCell,
  LinkCell,
  TextCell,
} from "components/table/Cells";
import { AuthContext } from "../../../utils/context/AuthContext";
import { TableColumns } from "../../../components/table/Table";
import { AdNetworkService } from "../../../utils/api";
import { getFilterStringFromObject, underToUpper } from "../../../utils";
import {
  getComparatorsString,
  getOperatorsString,
} from "../../../front-utils/filter/dynamicFilter";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";

const AdNetworkTable = (props: { publisher: any }) => {
  const [filterValue, setFilterValue] = useState<ValueType[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<ValueType[]>([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  }>({
    sortColumn: "created_at",
    sortType: "desc",
  });
  const { myInfo } = useContext(AuthContext);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const [tableLoading, setTableLoading] = useState(false);
  const { publisher } = props;

  const columns: () => TableColumns[] = useMemo(
    () => () => {
      const columns: TableColumns[] = [
        {
          title: "연동 상태",
          key: "enabled",
          sortable: true,
          width: 100,
          Cell: EnabledCell,
        },
        {
          title: "네트워크",
          key: "name",
          sortable: true,
          width: 200,
          flexGrow: 1,
          Cell: (props: CellProps) => (
            <LinkCell
              to={`/inventory/ad-network/edit/${props.rowData.id}`}
              state={{ publisher }}
              {...props}
            />
          ),
        },
        {
          title: "연동 방식",
          key: "type",
          sortable: true,
          width: 100,
          flexGrow: 1,
        },
        {
          title: "미검수 설정",
          key: "placement_groups",
          width: 100,
          flexGrow: 1,
          Cell: (props: CellProps) => {
            const placementGroups = props.rowData.placement_groups;
            return (
              <TextCell
                {...props}
                text={
                  placementGroups.length === 0
                    ? "없음"
                    : `${placementGroups[0].name}${
                        placementGroups.length > 1
                          ? ` 외 ${placementGroups.length - 1}건`
                          : ""
                      }`
                }
              />
            );
          },
        },
        {
          title: "등록일",
          key: "created_at",
          sortable: true,
          width: 150,
          flexGrow: 1,
          Cell: DateCell,
        },
        {
          title: "업데이트일",
          key: "updated_at",
          sortable: true,
          width: 150,
          flexGrow: 1,
          Cell: DateCell,
        },
      ];

      if (myInfo?.role === "MASTER") {
        columns.unshift({
          title: "매체사",
          key: "publisher.name",
          sortable: true,
          width: 150,
        } as TableColumns);
      }
      return columns;
    },
    [myInfo?.role, publisher]
  );

  const fetchData = useCallback(async () => {
    try {
      setTableLoading(true);
      const { data } = await new AdNetworkService().get({
        page,
        size,
        sort: underToUpper(sort.sortColumn) + "," + sort.sortType,
        filter: getOperatorsString(
          "and",
          [
            publisher
              ? getComparatorsString(":", "publisher.id", publisher)
              : "",
            ...getFilterStringFromObject(filterValue, filterData),
          ].filter((v) => v !== "")
        ),
      });
      setData(data.content);
      setTotal(data.total_elements);
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }, [filterValue, page, publisher, size, sort.sortColumn, sort.sortType]);

  useEffect(() => {
    fetchData();
    setCheckedKeys([]);
  }, [fetchData]);

  useEffect(() => {
    setPage(1);
  }, [size, filterValue, publisher]);

  const handleEnabledSelect = useCallback(
    async (eventKey: "ACTIVE" | "INACTIVE") => {
      try {
        await new AdNetworkService().editEnabled({
          ids: checkedKeys,
          enabled: eventKey === "ACTIVE",
        });

        fetchData();
        setCheckedKeys([]);
      } catch (e: any) {
        setErrorModalOpen(true);
      }
    },
    [checkedKeys, fetchData, setErrorModalOpen]
  );

  const handleCheck = useCallback(
    (value: ValueType | undefined, checked: boolean) => {
      if (!value) return;
      const nextCheckedKeys = checked
        ? [...checkedKeys, value]
        : checkedKeys.filter((item) => item !== value);

      setCheckedKeys(nextCheckedKeys);
    },
    [checkedKeys]
  );

  const handleCheckAll = useCallback(
    (_, checked: boolean) => {
      const allCheckedKeys = checked ? data.map((item) => item.id) : [];
      setCheckedKeys(allCheckedKeys);
    },
    [data]
  );

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  return (
    <>
      <TableActionsContainer style={{ justifyContent: "space-between" }}>
        <Stack spacing={8}>
          <Dropdown
            title={"상태 변경"}
            menuStyle={{ width: "100%" }}
            disabled={checkedKeys.length === 0}
            onSelect={handleEnabledSelect}
          >
            <Dropdown.Item eventKey={"ACTIVE"}>활성</Dropdown.Item>
            <Dropdown.Item eventKey={"INACTIVE"}>비활성</Dropdown.Item>
          </Dropdown>
          {myInfo?.role === "MASTER" && (
            <Link
              to={{ pathname: "/inventory/ad-network/new" }}
              state={{ publisher }}
            >
              <Button appearance="primary" disabled={!publisher}>
                등록
              </Button>
            </Link>
          )}
        </Stack>

        <Filter
          data={filterData}
          value={filterValue}
          onSelect={setFilterValue}
          placement={"bottomEnd"}
        />
      </TableActionsContainer>
      <Table
        data={data}
        columns={columns()}
        checkable={true}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        checkedKeys={checkedKeys}
        onCheck={handleCheck}
        onCheckAll={handleCheckAll}
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
        onSortColumn={handleSortChange}
        loading={tableLoading}
      />
    </>
  );
};

export default AdNetworkTable;

const filterData = [
  { label: "활성", value: "true", group: "상태", field: "enabled" },
  { label: "비활성", value: "false", group: "상태", field: "enabled" },
];
