import React, { useCallback, useEffect, useState } from "react";
import { Input, InputProps } from "rsuite";
import { numberWithCommas } from "utils";

const InputNumber = (props: InputNumberProps) => {
  const [value, setValue] = useState(numberWithCommas(props.value) || "");
  const {
    updateToPropValue,
    setUpdateToPropValue,
    acceptFloat,
    min,
    max,
    ...restProps
  } = props;

  useEffect(() => {
    if (updateToPropValue) {
      setValue(props.value);
      setUpdateToPropValue?.(false);
    }
  }, [updateToPropValue, setUpdateToPropValue, props.value]);

  const handleChange = useCallback(
    (v, e) => {
      const regexpForNumber = acceptFloat ? /^[\d|.]*$/ : /^[\d]*$/;
      if (!regexpForNumber.test(v)) return;
      let newValue = "";
      const value =
        v === ""
          ? v
          : acceptFloat
          ? v.endsWith(".") || isNaN(v)
            ? v
            : parseFloat(v).toString()
          : parseInt(v).toString();

      const isExist = (value: any) =>
        value !== "" && value !== undefined && value !== null;

      if (
        isExist(value) &&
        isExist(min) &&
        (min as number) > parseFloat(value)
      ) {
        newValue = (min as number).toString();
        setValue(newValue);
      } else if (
        isExist(value) &&
        isExist(max) &&
        (max as number) < parseFloat(value)
      ) {
        newValue = (max as number).toString();
        setValue(newValue);
      } else {
        newValue = value;
        setValue(newValue);
      }
      props.onChange?.(newValue, e);
    },
    [acceptFloat, max, min, props]
  );
  const handleFocus = useCallback(
    (e) => {
      setValue(value.toString().replace(/,/g, ""));
      props.onFocus?.(e);
    },
    [props, value]
  );
  const handleBlur = useCallback(
    (e) => {
      setValue(numberWithCommas(value));
      props.onBlur?.(e);
    },
    [props, value]
  );

  return (
    <Input
      {...restProps}
      style={{ textAlign: "right", ...restProps.style }}
      value={value}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

export default InputNumber;

interface InputNumberProps extends InputProps {
  updateToPropValue?: boolean;
  setUpdateToPropValue?: (v: boolean) => void;
  acceptFloat?: boolean;
}
