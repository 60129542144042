import React, { useContext, useState } from "react";
import { PageHeader } from "../../../components/layout";
import { Tab, Tabs } from "../../../components";
import UserTable from "./UserTable";
import { CompanyType } from "../../../utils/types";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { AuthContext } from "utils/context/AuthContext";

const Users = () => {
  const query: {
    "selected-tab"?: string;
  } = queryString.parse(useLocation().search);
  const { myInfo } = useContext(AuthContext);
  const activeKeyFromQuery = query["selected-tab"];
  const [activeKey, setActiveKey] = useState<CompanyType>(
    myInfo?.role === "ADMIN"
      ? "PUBLISHER"
      : (activeKeyFromQuery as CompanyType) || "SYSTEM_PROVIDER"
  );

  return (
    <>
      <PageHeader title={"사용자"} />
      {myInfo?.role === "MASTER" && (
        <Tabs
          activeKey={activeKey}
          onSelect={setActiveKey}
          style={{ marginTop: 5 }}
        >
          <Tab eventKey={"SYSTEM_PROVIDER"}>운영사</Tab>
          <Tab eventKey={"PUBLISHER"}>매체사</Tab>
        </Tabs>
      )}
      <UserTable companyType={activeKey} />
    </>
  );
};

export default Users;
