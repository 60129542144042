import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CreateFooter, PageHeader } from "../../../components/layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AdNetworkCodeType,
  AdNetworkType,
  MobileOSType,
} from "../../../utils/types";
import AdNetworkUnitForm, { AdNetworkUnitFormProps } from "./AdNetworkUnitForm";
import { Button, Loader } from "rsuite";
import { AdNetworkService, AdNetworkUnitService } from "../../../utils/api";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";

export const initFormValue: AdNetworkUnitFormProps["formValue"] = {
  name: "",
  screen: "PC",
  platform: "WEB",
  mobile_os: null,
  width: "",
  height: "",
  type: "IMAGE",
  skip_option: "UNAVAILABLE",
  // spec..
  pub_key: "",
  media_id: "",
  section_id: "",
  app_id: "",
  unit_id: "",
  mc: "",
  site_id: "",
  ad_id: "",
  store_url: "",
  bundle: "",
  mcode: "",
  pkgname: "",
  media_key: "",
  adunit_id: "",
  pack_name: "",
  tag: "",
  bid_floor: "",
  app_name: "",
  ifa_type: "",
};

const CreateAdNetworkUnit = () => {
  const location = useLocation();
  const { adNetwork: adNetworkId, publisher } = location.state as LocationState;
  const [adNetwork, setAdNetwork] = useState<
    | {
        id: number;
        enabled: boolean;
        code: AdNetworkCodeType;
        type: AdNetworkType;
        publisher: {
          id: number;
          name: string;
        };
      }
    | undefined
  >(undefined);
  const [formValue, setFormValue] =
    useState<AdNetworkUnitFormProps["formValue"]>(initFormValue);
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  const fetchAdNetwork = useCallback(async () => {
    const { data } = await new AdNetworkService().getDetail(adNetworkId);

    setAdNetwork(data);
  }, [adNetworkId]);

  useEffect(() => {
    fetchAdNetwork();
  }, [fetchAdNetwork]);

  const handleSubmit = useCallback(
    async (validate: boolean) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { hasError } = await formRef?.current?.checkAsync();
      if (!validate || hasError) return;

      try {
        setLoading(true);
        if (!formValue || !adNetwork) return;
        const {
          name,
          screen,
          platform,
          mobile_os,
          width,
          height,
          type,
          skip_option,
          pub_key,
          media_id,
          section_id,
          app_id,
          unit_id,
          mc,
          site_id,
          ad_id,
          store_url,
          bundle,
          mcode,
          pkgname,
          media_key,
          adunit_id,
          pack_name,
          tag,
          bid_floor,
          app_name,
          ifa_type,
        } = formValue;

        await new AdNetworkUnitService().create({
          publisher_id: adNetwork.publisher.id,
          ad_network_id: adNetwork.id,
          name,
          platform: screen === "MOBILE" ? (mobile_os as MobileOSType) : screen,
          application: platform,
          type,
          width: width === "" ? 0 : parseInt(width),
          height: height === "" ? 0 : parseInt(height),
          skip_option,
          spec: {
            pub_key,
            media_id,
            section_id,
            app_id,
            unit_id,
            mc,
            site_id,
            ad_id,
            store_url,
            bundle,
            mcode,
            pkgname,
            media_key,
            adunit_id,
            pack_name,
            tag,
            bid_floor,
            app_name,
            ifa_type,
          },
        });

        navigate(`/inventory/ad-networks?selected-tab=ad-network-unit`, {
          state: { adNetwork: adNetworkId, publisher },
        });
      } catch (e) {
        setErrorModalOpen(true);
      } finally {
        setLoading(false);
      }
    },
    [adNetwork, adNetworkId, formValue, navigate, publisher, setErrorModalOpen]
  );

  return (
    <>
      <PageHeader title={"유닛 등록"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}

      <AdNetworkUnitForm
        adNetwork={adNetwork}
        formValue={formValue}
        onChange={setFormValue}
        renderFooter={() => (
          <CreateFooter>
            <Link
              to={"/inventory/ad-networks?selected-tab=ad-network-unit"}
              state={{
                adNetwork: adNetworkId,
                publisher,
              }}
            >
              <Button>취소</Button>
            </Link>
            <Button type={"submit"} appearance={"primary"}>
              등록
            </Button>
          </CreateFooter>
        )}
        onSubmit={handleSubmit}
        formRef={formRef}
      />
    </>
  );
};

export default CreateAdNetworkUnit;

type LocationState = {
  adNetwork: number;
  publisher?: number;
};
