import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AccountRoleType, CompanyType } from "../../../utils/types";
import { CheckPicker, Form, InputCount, SelectPicker } from "components";
import {
  CreateItemContainer,
  CreateItemTitle,
  CreateItemValue,
} from "../../../components/layout";
import { CreateItemTitleText, formatDate } from "../../../components/text";
import { COMPANY_TYPE, regexpForPhoneNumber } from "../../../utils/variables";
import {
  Button,
  Divider,
  FormControlProps,
  Input,
  InputGroup,
  Radio,
  RadioGroup,
  Schema,
  Stack,
  Toggle,
} from "rsuite";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { EmailControl, PhoneControl } from "components/form/FormControls";
import { CompanyFormProps } from "../company/CompanyForm";
import {
  AccountService,
  CompanyService,
  PlacementGroupService,
} from "../../../utils/api";
import { AuthContext } from "../../../utils/context/AuthContext";

const UserForm = (props: UserFormProps) => {
  const { myInfo } = useContext(AuthContext);
  const {
    companyType,
    formValue,
    onChange,
    mode,
    onSubmit,
    placementGroupNames,
  } = props;
  const [companies, setCompanies] = useState<{ label: string; value: any }[]>(
    []
  );
  const [isCheckedDuplicated, setIsCheckedDuplicated] = useState(
    mode !== "CREATE"
  );
  const [isDuplicated, setIsDuplicated] = useState<boolean>();
  const [placementGroups, setPlacementGroups] = useState<
    { label: string; value: number }[]
  >([]);
  const [showNewPasswordField, setShowNewPasswordField] = useState(false);

  const model = useMemo(
    () =>
      Schema.Model({
        company_id: Schema.Types.NumberType().addRule(
          (value) => {
            if (companyType === "PUBLISHER") {
              return value !== null;
            } else return true;
          },
          "회사를 선택하세요.",
          true
        ),
        login_id: Schema.Types.StringType()
          .isRequired("아이디를 입력하세요.")
          .rangeLength(6, 12, "아이디는 6~12자로 입력하세요.")
          .pattern(/^[a-z0-9]*$/, "영문 소문자, 숫자 사용 가능합니다."),
        password: Schema.Types.StringType()
          .addRule(
            (value) => {
              if (mode === "CREATE" || showNewPasswordField) {
                return value !== "";
              } else return true;
            },
            "비밀번호를 입력하세요.",
            true
          )
          .pattern(
            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,\-./:;<=>?@[＼\]^_`{|}~\\])[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[＼\]^_`{|}~\\]{8,15}$/,
            "영문(대소), 숫자, 특수문자 조합으로 입력하세요."
          ),
        password_check: Schema.Types.StringType()
          .addRule((value, data) => {
            return value === data.password;
          }, "비밀번호와 일치하지 않습니다.")

          .addRule(
            (value) => {
              if (mode === "CREATE" || showNewPasswordField) {
                return value !== "";
              } else return true;
            },
            "비밀번호 확인을 입력하세요.",
            true
          )
          .pattern(
            /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+,\-./:;<=>?@[＼\]^_`{|}~\\])[a-zA-Z0-9!"#$%&'()*+,\-./:;<=>?@[＼\]^_`{|}~\\]{8,15}$/,
            "영문(대소), 숫자, 특수문자 조합으로 입력하세요."
          ),
        name: Schema.Types.StringType()
          .isRequired("이름을 입력하세요.")
          .rangeLength(2, 16, "이름은  2~16자로 입력하세요.")
          .pattern(
            /^[ㄱ-ㅎ가-힣ㅏ-ㅣa-zA-Z\s]*$/,
            "영문(대소), 한글, 띄어쓰기 사용 가능합니다."
          ),
        email: Schema.Types.StringType()
          .addRule((value) => {
            return value !== "@" && value !== "";
          }, "이메일을 입력하세요.")
          .pattern(
            /^[a-zA-Z0-9_.@\\-]*$/,
            "영문(대소), 숫자, 언더바(_), ., - 사용 가능합니다."
          )
          .isEmail("이메일 형식에 맞게 입력하세요."),
        phone: Schema.Types.StringType()
          .addRule((value) => {
            return value !== "--" && value !== "";
          }, "연락처를 입력하세요.")
          .pattern(regexpForPhoneNumber, "연락처 형식에 맞게 입력하세요."),
        role: Schema.Types.StringType().isRequired("담당을 선택하세요."),
        placement_group_ids: Schema.Types.ArrayType().addRule((value, data) => {
          if (data.role === "VIEWER") return value.length > 0;
          return true;
        }, "담당을 선택하세요."),
      }),
    [companyType, mode, showNewPasswordField]
  );
  const handleFormChange = useCallback(
    (formValue: any) => {
      onChange({
        ...formValue,
        placement_group_ids:
          formValue.company_id !== props.formValue?.company_id
            ? []
            : formValue.placement_group_ids,
      });
    },

    [onChange, props.formValue?.company_id]
  );

  const fetchCompanies = useCallback(async () => {
    const { data } = await new CompanyService().getIdNameMeta({
      filter: "type : 'PUBLISHER'",
    });

    setCompanies(
      data.map((d: { id: number; name: string }) => ({
        label: d.name,
        value: d.id,
      }))
    );
  }, []);

  const fetchPlacementGroups = useCallback(async () => {
    const companyId = props.formValue?.company_id;
    const { data } = await new PlacementGroupService().getIdNameMeta({
      filter: `${companyId ? `publisher.id : ${companyId}` : ""}`,
    });

    setPlacementGroups(
      data.map((d: { id: number; name: string }) => ({
        label: d.name,
        value: d.id,
      }))
    );
  }, [props.formValue?.company_id]);

  useEffect(() => {
    if (companyType === "PUBLISHER") {
      fetchCompanies();
      if (myInfo?.role !== "VIEWER") fetchPlacementGroups();
    }
  }, [companyType, fetchCompanies, fetchPlacementGroups, myInfo?.role]);

  const checkDuplicatedLoginId = useCallback(async () => {
    if (!props.formValue?.login_id) return;

    try {
      const {
        data: { result },
      } = await new AccountService().existLoginId(props.formValue.login_id);
      setIsCheckedDuplicated(true);
      setIsDuplicated(result);
    } catch (e) {
      console.log(e);
    }
  }, [props.formValue?.login_id]);

  return (
    <>
      <Form
        layout="horizontal"
        model={model}
        formValue={formValue}
        onChange={handleFormChange}
        onSubmit={onSubmit}
      >
        {mode === "EDIT" && myInfo?.role !== "VIEWER" && (
          <Form.Group controlId="enabled">
            <Form.ControlLabel
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <CreateItemTitleText>상태</CreateItemTitleText>
            </Form.ControlLabel>
            <CreateItemValue>
              <Stack spacing={10}>
                <span
                  style={{
                    width: 40,
                    display: "inline-block",
                    color: formValue?.enabled
                      ? "var(--rs-text-active)"
                      : undefined,
                    float: "left",
                  }}
                >
                  {formValue?.enabled ? "활성" : "비활성"}
                </span>
                <Form.Control
                  name="enabled"
                  accepter={Toggle}
                  checked={formValue?.enabled}
                  disabled={formValue?.id === myInfo?.id}
                />
              </Stack>
            </CreateItemValue>
          </Form.Group>
        )}
        {companyType === "PUBLISHER" && myInfo?.role === "MASTER" && (
          <CreateItemContainer style={{ padding: 0, marginBottom: 24 }}>
            <CreateItemTitle>
              <CreateItemTitleText>회사 유형</CreateItemTitleText>
            </CreateItemTitle>
            <CreateItemValue>{COMPANY_TYPE[companyType]}</CreateItemValue>
          </CreateItemContainer>
        )}
        {companyType === "PUBLISHER" && myInfo?.role === "MASTER" && (
          <Form.Group controlId="company_id">
            <Form.ControlLabel>
              <CreateItemTitleText required>회사</CreateItemTitleText>
            </Form.ControlLabel>
            <Form.Control
              name="company_id"
              accepter={SelectPicker}
              data={companies}
              style={{ width: 500 }}
              placeholder={"회사 선택"}
              plaintext={mode !== "CREATE"}
              cleanable={false}
            />
          </Form.Group>
        )}
        <Form.Group controlId="login_id">
          <Form.ControlLabel>
            <CreateItemTitleText required>아이디</CreateItemTitleText>
          </Form.ControlLabel>
          <LoginIdFormControl
            name="login_id"
            accepter={LoginIdControl}
            isDuplicated={isDuplicated}
            setIsDuplicated={setIsDuplicated}
            isCheckedDuplicated={isCheckedDuplicated}
            setIsCheckedDuplicated={setIsCheckedDuplicated}
            mode={mode}
          />

          {mode === "CREATE" && (
            <Button
              appearance={"primary"}
              style={{ marginLeft: 8 }}
              disabled={
                formValue?.login_id ? formValue.login_id.length < 6 : true
              }
              onClick={checkDuplicatedLoginId}
            >
              중복 확인
            </Button>
          )}
        </Form.Group>
        {(mode === "EDIT" || mode === "VIEW") && !showNewPasswordField && (
          <Form.Group>
            <Form.ControlLabel>
              <CreateItemTitleText>비밀번호</CreateItemTitleText>
            </Form.ControlLabel>
            <CreateItemValue>
              <Button
                onClick={() => setShowNewPasswordField(true)}
                disabled={mode === "VIEW"}
              >
                비밀번호 변경
              </Button>
            </CreateItemValue>
          </Form.Group>
        )}
        {(mode === "CREATE" || showNewPasswordField) && (
          <>
            <Form.Group controlId={"password"}>
              <Form.ControlLabel>
                <CreateItemTitleText required>
                  {showNewPasswordField ? "새 비밀번호" : "비밀번호"}
                </CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control name="password" accepter={PasswordControl} />
            </Form.Group>
            <Form.Group controlId={"password_check"}>
              <Form.ControlLabel>
                <CreateItemTitleText required>
                  {showNewPasswordField ? "새 비밀번호 확인" : "비밀번호 확인"}
                </CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control name="password_check" accepter={PasswordControl} />
            </Form.Group>
          </>
        )}
        <Form.Group controlId="name">
          <Form.ControlLabel>
            <CreateItemTitleText required>이름</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="name"
            accepter={InputCount}
            style={{ width: 500 }}
            maxLength={16}
            plaintext={mode === "VIEW"}
          />
          {mode !== "VIEW" && (
            <Form.HelpText>영문(대소), 한글, 띄어쓰기 사용 가능</Form.HelpText>
          )}
        </Form.Group>
        <Form.Group controlId="email">
          <Form.ControlLabel>
            <CreateItemTitleText required>이메일</CreateItemTitleText>
          </Form.ControlLabel>
          <ModeFormControl name="email" accepter={EmailControl} mode={mode} />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.ControlLabel>
            <CreateItemTitleText required>연락처</CreateItemTitleText>
          </Form.ControlLabel>
          <ModeFormControl name="phone" accepter={PhoneControl} mode={mode} />
        </Form.Group>
        <Form.Group controlId="role">
          <Form.ControlLabel>
            <CreateItemTitleText>역할</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="role"
            accepter={RadioGroup}
            inline
            plaintext={
              mode === "VIEW" || (mode === "EDIT" && myInfo?.role === "VIEWER")
            }
          >
            {companyType === "SYSTEM_PROVIDER" && (
              <Radio value={"MASTER"}>마스터</Radio>
            )}
            {companyType === "PUBLISHER" && (
              <>
                <Radio value={"ADMIN"}>관리자</Radio>
                <Radio value={"VIEWER"}>뷰어</Radio>
              </>
            )}
          </Form.Control>
        </Form.Group>
        {companyType === "PUBLISHER" && formValue?.role === "VIEWER" && (
          <Form.Group controlId="placement_group_ids">
            <Form.ControlLabel>
              <CreateItemTitleText required>담당</CreateItemTitleText>
            </Form.ControlLabel>
            {mode === "VIEW" ||
            (mode === "EDIT" && myInfo?.role === "VIEWER") ? (
              <span>{placementGroupNames?.join(", ")}</span>
            ) : (
              <Form.Control
                name="placement_group_ids"
                accepter={CheckPicker}
                data={placementGroups}
                style={{ width: 500 }}
                placeholder={"게재위치 그룹 선택"}
              />
            )}
          </Form.Group>
        )}

        {((mode === "EDIT" && myInfo?.role !== "VIEWER") ||
          mode === "VIEW") && (
          <>
            <Divider />
            <CreateItemContainer style={{ padding: 0 }}>
              <CreateItemTitle>
                <CreateItemTitleText>생성일</CreateItemTitleText>
              </CreateItemTitle>
              <CreateItemValue>
                <span>{formatDate(formValue?.created_at)}</span>
              </CreateItemValue>
            </CreateItemContainer>
            <CreateItemContainer style={{ padding: "25px 0 0 0" }}>
              <CreateItemTitle>
                <CreateItemTitleText>마지막 수정일</CreateItemTitleText>
              </CreateItemTitle>
              <CreateItemValue>
                <span>{formatDate(formValue?.updated_at)}</span>
              </CreateItemValue>
            </CreateItemContainer>
            <CreateItemContainer style={{ padding: "25px 0 0 0" }}>
              <CreateItemTitle>
                <CreateItemTitleText>마지막 접속일</CreateItemTitleText>
              </CreateItemTitle>
              <CreateItemValue>
                <span>{formatDate(formValue?.last_login_at)}</span>
              </CreateItemValue>
            </CreateItemContainer>
          </>
        )}

        <Divider />
        {props.renderFooter()}
      </Form>
    </>
  );
};

export default UserForm;

const LoginIdControl = (props: LoginIdControlProps) => {
  const {
    onChange,
    value = "",
    isDuplicated,
    setIsDuplicated,
    setIsCheckedDuplicated,
    isCheckedDuplicated,
    mode,
  } = props;

  const handleChange = useCallback(
    (v) => {
      onChange(v);
      setIsDuplicated(false);
      setIsCheckedDuplicated(false);
    },
    [onChange, setIsCheckedDuplicated, setIsDuplicated]
  );

  return mode !== "CREATE" ? (
    <span>{value}</span>
  ) : (
    <>
      <InputCount
        maxLength={12}
        value={value}
        onChange={(v) => handleChange(v)}
        style={{
          width: 500,
          display: "inline-block",
        }}
        placeholder={"영문 소문자/숫자 6~12자"}
      />
      {isDuplicated && (
        <Form.ErrorMessage show placement={"bottomStart"}>
          이미 사용중인 아이디입니다. 다른 아이디를 입력하세요.
        </Form.ErrorMessage>
      )}
      {!isCheckedDuplicated && value.length >= 6 && (
        <Form.ErrorMessage show placement={"bottomStart"}>
          아이디 중복 확인을 진행하세요.
        </Form.ErrorMessage>
      )}
      {isDuplicated === false && isCheckedDuplicated && (
        <Form.ErrorMessage show placement={"bottomStart"}>
          <span style={{ color: "var(--rs-state-success)" }}>
            사용 가능한 아이디입니다.
          </span>
        </Form.ErrorMessage>
      )}
    </>
  );
};

const PasswordControl = (props: FormControlProps) => {
  const { value, onChange, name } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleChange = useCallback(
    (v, e) => {
      onChange?.(v, e);
    },
    [onChange]
  );

  return (
    <InputGroup style={{ width: 500 }}>
      <Input
        type={passwordVisible ? "text" : "password"}
        value={value}
        onChange={handleChange}
        placeholder={
          name === "password" ? "영문, 숫자, 특수문자 조합 8~15자" : undefined
        }
        maxLength={15}
      />
      <InputGroup.Button
        onClick={() => {
          setPasswordVisible(!passwordVisible);
        }}
      >
        {passwordVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      </InputGroup.Button>
    </InputGroup>
  );
};

export type UserFormProps = {
  companyType: CompanyType;
  formValue?: {
    company_id: number | null;
    login_id: string;
    password: string;
    password_check: string;
    name: string;
    email: string;
    phone: string;
    role: AccountRoleType;
    placement_group_ids: number[];
    id?: number;
    enabled?: boolean;
    created_at?: string | Date;
    updated_at?: string | Date;
    last_login_at?: string | Date;
  };
  onChange: (formValue: UserFormProps["formValue"]) => void;
  renderFooter: () => ReactElement;
  mode: "CREATE" | "VIEW" | "EDIT";
  onSubmit?: (validate: boolean) => Promise<void>;
  placementGroupNames?: string[];
};

type LoginIdControlProps = {
  value: string;
  onChange: (value: string) => void;
  isDuplicated?: boolean;
  isCheckedDuplicated: boolean;
  setIsDuplicated: (v: boolean) => void;
  setIsCheckedDuplicated: (v: boolean) => void;
  mode: UserFormProps["mode"];
};

const LoginIdFormControl = (props: LoginIdFormControlProps) => {
  return <Form.Control {...props}>{props.children}</Form.Control>;
};

interface LoginIdFormControlProps extends FormControlProps {
  isDuplicated?: boolean;
  isCheckedDuplicated: boolean;
  setIsDuplicated: (v: boolean) => void;
  setIsCheckedDuplicated: (v: boolean) => void;
  mode: UserFormProps["mode"];
}

const ModeFormControl = (props: ModeFormControlProps) => {
  return <Form.Control {...props}>{props.children}</Form.Control>;
};

interface ModeFormControlProps extends FormControlProps {
  mode: CompanyFormProps["mode"];
}
