import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "pages/NotFound";
import PublicLayout from "pages/PublicLayout";
import Login from "pages/Login";
import {
  AdNetworks,
  Companies,
  CreateAdNetwork,
  CreateAdNetworkUnit,
  CreateCompany,
  CreatePlacement,
  CreatePlacementGroup,
  CreateUser,
  EditAdNetwork,
  EditAdNetworkUnit,
  EditCompany,
  EditPlacement,
  EditPlacementGroup,
  EditUser,
  ErrorReport,
  NetworkReport,
  OperationReport,
  Placements,
  ReviewAd,
  Settlements,
  Users,
  ViewCompany,
  ViewUser,
} from "pages";
import { ErrorModal } from "./components/modal";
import { ErrorModalContext } from "./utils/context/ErrorModalContext";

const RoutesContainer = () => {
  const { open, setErrorModalOpen } = useContext(ErrorModalContext);
  return (
    <>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route
            path="/"
            element={<Navigate to="/report/operation" replace />}
          />
          <Route path="/inventory/placements" element={<Placements />} />
          <Route
            path="/inventory/placement-group/new"
            element={<CreatePlacementGroup />}
          />
          <Route
            path="/inventory/placement-group/edit/:id"
            element={<EditPlacementGroup />}
          />
          <Route
            path="/inventory/placement/new"
            element={<CreatePlacement />}
          />
          <Route
            path="/inventory/placement/edit/:id"
            element={<EditPlacement />}
          />
          <Route path="/inventory/ad-networks" element={<AdNetworks />} />
          <Route
            path="/inventory/ad-network/new"
            element={<CreateAdNetwork />}
          />
          <Route
            path="/inventory/ad-network/edit/:id"
            element={<EditAdNetwork />}
          />
          <Route
            path="/inventory/ad-network-unit/new"
            element={<CreateAdNetworkUnit />}
          />
          <Route
            path="/inventory/ad-network-unit/edit/:id"
            element={<EditAdNetworkUnit />}
          />
          <Route path="/manage/review" element={<ReviewAd />} />
          <Route path="/manage/settlement" element={<Settlements />} />
          <Route path="/account/company" element={<Companies />} />
          <Route path="/account/company/new" element={<CreateCompany />} />
          <Route path="/account/company/edit/:id" element={<EditCompany />} />
          <Route path="/account/company/view/:id" element={<ViewCompany />} />
          <Route path="/account/user" element={<Users />} />
          <Route path="/account/user/new" element={<CreateUser />} />
          <Route path="/account/user/edit/:id" element={<EditUser />} />
          <Route path="/account/user/view/:id" element={<ViewUser />} />
          <Route path="/report/operation" element={<OperationReport />} />
          <Route path="/report/network" element={<NetworkReport />} />
          <Route path="/report/error" element={<ErrorReport />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ErrorModal
        open={open}
        onClose={() => {
          setErrorModalOpen(false);
        }}
      />
    </>
  );
};

export default RoutesContainer;
