import React from "react";
import { Whisper, Popover } from "rsuite";
import { Icon } from "@rsuite/icons";
import { TypeAttributes } from "rsuite/cjs/@types/common";
import { BsExclamationCircle } from "react-icons/bs";

const WarnTooltip = ({
  inner,
  iconStyle = {},
  placement = "top",
  trigger = "click",
  preventOverflow,
  popoverStyle = {},
  onClick,
}: {
  inner: any;
  iconStyle?: any;
  placement?: TypeAttributes.Placement;
  trigger?: "click" | "hover" | "focus" | "active";
  preventOverflow?: boolean;
  popoverStyle?: any;
  onClick?: (e: React.SyntheticEvent) => void;
}) => {
  return (
    <Whisper
      trigger={trigger}
      placement={placement}
      preventOverflow={preventOverflow}
      speaker={<Popover style={{ ...popoverStyle }}>{inner}</Popover>}
    >
      <span>
        <Icon
          as={BsExclamationCircle}
          style={{
            fontSize: "14px",
            color: "var(--rs-form-errormessage-text)",
            marginLeft: 4,
            verticalAlign: "middle",
            cursor: "pointer",
            ...iconStyle,
          }}
          onClick={onClick}
        />
      </span>
    </Whisper>
  );
};

export default WarnTooltip;
