import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import AdNetworkForm, { AdNetworkFormProps } from "./AdNetworkForm";
import { CreateFooter, PageHeader } from "../../../components/layout";
import { Button, Loader, Modal } from "rsuite";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { AdNetworkService } from "../../../utils/api";
import { AdNetworkCodeType, PublisherState } from "../../../utils/types";

const EditAdNetwork = () => {
  const { id = "" } = useParams<{ id: string }>();
  const state = useLocation().state || {};
  const { publisher: publisherByLocation } = state as PublisherState;
  const [publisher, setPublisher] = useState<number | string>("");
  const [formValue, setFormValue] =
    useState<AdNetworkFormProps["formValue"]>(undefined);
  const [adNetworkName, setAdNetworkName] = useState("");
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const [placementGroups, setPlacementGroups] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    const { data } = await new AdNetworkService().getDetail(id);

    setFormValue({
      enabled: data.enabled,
      code: data.code,
      type: data.type,
      memo: data.memo,
      placement_group_ids:
        data.placement_groups?.map(
          (pg: { id: number; name: string }) => pg.id
        ) || [],
      contract_info: data.contract_info?.map((ci: { price?: number }) => ({
        ...ci,
        price: ci.price?.toString() || "",
      })) || [
        {
          name: "",
          bid_type: "CPM",
          sales_type: "FIXED",
          currency: "KRW",
          price: "",
        },
      ],
    });

    setPublisher(data.publisher.id);
    setAdNetworkName(data.name);
    setPlacementGroups(data.placement_groups);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const edit = useCallback(async () => {
    try {
      setLoading(true);
      setOpenConfirm(false);
      if (!formValue) return;
      const { enabled, code, type, placement_group_ids, memo, contract_info } =
        formValue;

      await new AdNetworkService().edit(id, {
        id,
        enabled: enabled as boolean,
        code: code as AdNetworkCodeType,
        type,
        placement_group_ids,
        memo,
        contract_info: contract_info.map((ci) => ({
          ...ci,
          price: parseFloat(ci.price),
        })),
      });

      navigate(`/inventory/ad-networks?selected-tab=ad-network`, {
        state: { publisher: publisherByLocation },
      });
    } catch (e) {
      setErrorModalOpen(true);
    } finally {
      setLoading(false);
    }
  }, [formValue, id, navigate, publisherByLocation, setErrorModalOpen]);

  const handleSubmit = useCallback(
    async (validate: boolean) => {
      if (!validate) return;
      if (
        placementGroups
          .map((pg: { id: number }) => pg.id)
          .sort()
          .join(",") !== formValue?.placement_group_ids.sort().join(",")
      ) {
        setOpenConfirm(true);
      } else {
        await edit();
      }
    },
    [edit, formValue?.placement_group_ids, placementGroups]
  );

  return (
    <>
      <PageHeader title={"네트워크 조회"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}
      <AdNetworkForm
        editMode={true}
        formValue={formValue}
        onChange={setFormValue}
        publisher={publisher}
        renderFooter={() => (
          <CreateFooter>
            <Link
              to={"/inventory/ad-networks?selected-tab=ad-network"}
              state={{ publisher: publisherByLocation }}
            >
              <Button>취소</Button>
            </Link>
            <Button appearance={"primary"} type="submit">
              저장
            </Button>
          </CreateFooter>
        )}
        adNetworkName={adNetworkName}
        onSubmit={handleSubmit}
        placementGroups={placementGroups}
      />
      <Modal
        size={"xs"}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
      >
        <Modal.Header>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>미검수 설정 변경 시, 기존 검수 리스트는 초기화됩니다.</div>
          <div>계속하시겠습니까?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpenConfirm(false)}
            style={{ marginRight: 5 }}
          >
            취소
          </Button>
          <Button appearance="primary" onClick={edit} loading={loading}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditAdNetwork;
