import React from "react";
import "rsuite/dist/rsuite.min.css";
import RoutesContainer from "Routes";
import { GlobalStyle } from "./styles";
import AuthContextProvider from "./utils/context/AuthContext";
import ErrorModalContextProvider from "./utils/context/ErrorModalContext";
import { init as initApm, apm } from "@elastic/apm-rum";

function App() {
  return (
    <AuthContextProvider>
      <ErrorModalContextProvider>
        <RoutesContainer />
        <GlobalStyle />
      </ErrorModalContextProvider>
    </AuthContextProvider>
  );
}

export default App;

initApm({
  serviceName: "lguplus-admin-front",
  serverUrl: "https://deapm.ssp.uplus.co.kr",
  environment: process.env.REACT_APP_HOST_ENV,
  serviceVersion: "0.0.1",
  ignoreTransactions: [/(\/api\/v1\/auth\/me)$/],
});

apm.addFilter((payload) => {
  if (payload.errors) {
    const index = payload.errors.findIndex(
      (error: any) =>
        error.exception.message ===
        "ResizeObserver loop completed with undelivered notifications."
    );

    if (index > -1) payload.errors.splice(index, 1);
  }
  return payload;
});
