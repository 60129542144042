import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AccountRoleType, CompanyType, SortType } from "../../../utils/types";
import { ValueType } from "rsuite/Checkbox";
import { Button, CellProps, Dropdown, Modal, Stack } from "rsuite";
import {
  DateCell,
  EnabledCell,
  LinkCell,
  MapCell,
  TextCell,
} from "../../../components/table/Cells";
import { Link } from "react-router-dom";
import { TableActionsContainer } from "components/layout";
import { Filter, Search, Table } from "../../../components";
import { ACCOUNT_ROLE_TYPE } from "../../../utils/variables";
import { AccountService } from "../../../utils/api";
import { getFilterStringFromObject, underToUpper } from "../../../utils";
import {
  getComparatorsString,
  getOperatorsString,
} from "../../../front-utils/filter/dynamicFilter";
import _ from "lodash";
import { AuthContext } from "../../../utils/context/AuthContext";
import { TableColumns } from "../../../components/table/Table";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";

const UserTable = (props: UserTableProps) => {
  const { companyType } = props;
  const { myInfo } = useContext(AuthContext);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const [filterValue, setFilterValue] = useState<ValueType[]>([]);
  const [filterTempValue, setFilterTempValue] = useState<ValueType[]>([]);
  const [searchKey, setSearchKey] = useState("loginId");
  const [searchValue, setSearchValue] = useState("");
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<ValueType[]>([]);
  const [disabledKeys, setDisabledKeys] = useState<ValueType[]>([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  }>({
    sortColumn: "created_at",
    sortType: "desc",
  });
  const [openInactiveDisableModal, setOpenInactiveDisableModal] =
    useState(false);
  const [openRemoveDisableModal, setOpenRemoveDisableModal] = useState(false);
  const [openRemoveCheckModal, setOpenRemoveCheckModal] = useState(false);

  const [appliedSearchValue, setAppliedSearchValue] =
    useState<string>(searchValue);

  const handleFilterSelect = useCallback((value: ValueType[]) => {
    setFilterValue(value);
  }, []);
  const [tableLoading, setTableLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setTableLoading(true);
      const { data } = await new AccountService().get({
        page,
        size,
        sort: underToUpper(sort.sortColumn) + "," + sort.sortType,
        filter: getOperatorsString(
          "and",
          [
            getComparatorsString(":", "company.type", companyType),
            appliedSearchValue !== ""
              ? getComparatorsString("~", searchKey, `%${appliedSearchValue}%`)
              : "",
            ...getFilterStringFromObject(filterValue, filterData(companyType)),
          ].filter((v) => v !== "")
        ),
      });
      setData(data.content);
      setTotal(data.total_elements);
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }, [
    searchKey,
    appliedSearchValue,
    page,
    size,
    sort.sortColumn,
    sort.sortType,
    filterValue,
    companyType,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPage(1);
  }, [size, appliedSearchValue, filterValue]);

  useEffect(() => {
    setCheckedKeys([]);
  }, [fetchData]);

  useEffect(() => {
    if (myInfo) setDisabledKeys([myInfo?.id]);
  }, [myInfo]);

  useEffect(() => {
    setFilterValue([]);
    setFilterTempValue([]);
    setSearchValue("");
    setSearchKey("loginId");
    setPage(1);
    setSize(25);
    setSort({ sortColumn: "created_at", sortType: "desc" });
    setCheckedKeys([]);
  }, [companyType]);

  const handleCheck = useCallback(
    (value: ValueType | undefined, checked: boolean) => {
      if (!value) return;
      const nextCheckedKeys = checked
        ? [...checkedKeys, value]
        : checkedKeys.filter((item) => item !== value);

      setCheckedKeys(nextCheckedKeys);
    },
    [checkedKeys]
  );

  const handleCheckAll = useCallback(
    (_, checked: boolean) => {
      const allCheckedKeys = checked
        ? data.map((item) => item.id).filter((id) => !disabledKeys.includes(id))
        : [];
      setCheckedKeys(allCheckedKeys);
    },
    [data, disabledKeys]
  );

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  const handleRemoveCheck = useCallback(() => {
    if (
      checkedKeys.some(
        (id) => data.find((item) => item.id === id)?.enabled === true
      )
    ) {
      setOpenRemoveDisableModal(true);
      return;
    }
    setOpenRemoveCheckModal(true);
  }, [checkedKeys, data]);

  const handleRemove = useCallback(async () => {
    try {
      await new AccountService().delete(checkedKeys);

      fetchData();
      setOpenRemoveCheckModal(false);
      setCheckedKeys([]);
    } catch (e) {
      setErrorModalOpen(true);
    }
  }, [checkedKeys, fetchData, setErrorModalOpen]);

  const handleSearchKeyChange = useCallback(
    (v: "loginId" | "name" | "company.name") => {
      setSearchKey(v);
      setSearchValue("");
    },
    []
  );

  const delayedSetSearchValue = useMemo<any>(
    () => _.debounce((v: string) => setAppliedSearchValue(v), 500),
    []
  );

  const handleSearchValueChange = useCallback((v: string) => {
    setSearchValue(v);
  }, []);

  const handleEnabledSelect = useCallback(
    async (eventKey: "ACTIVE" | "INACTIVE") => {
      try {
        await new AccountService().editEnabled({
          ids: checkedKeys,
          enabled: eventKey === "ACTIVE",
          company_type: companyType,
        });
        setCheckedKeys([]);
        fetchData();
      } catch (e: any) {
        if (e.response.data?.error_code === "ALL_INACTIVE") {
          setOpenInactiveDisableModal(true);
        } else {
          setErrorModalOpen(true);
        }
      }
    },
    [checkedKeys, companyType, fetchData, setErrorModalOpen]
  );

  useEffect(() => {
    delayedSetSearchValue(searchValue);
  }, [searchValue, delayedSetSearchValue]);

  return (
    <div>
      <TableActionsContainer style={{ justifyContent: "space-between" }}>
        <Stack spacing={8}>
          <Dropdown
            title={"상태 변경"}
            menuStyle={{ width: "100%" }}
            disabled={checkedKeys.length === 0}
            onSelect={handleEnabledSelect}
          >
            <Dropdown.Item eventKey={"ACTIVE"}>활성</Dropdown.Item>
            <Dropdown.Item eventKey={"INACTIVE"}>비활성</Dropdown.Item>
          </Dropdown>
          <Button
            disabled={checkedKeys.length === 0}
            onClick={handleRemoveCheck}
          >
            삭제
          </Button>
          <Link
            to={{ pathname: "/account/user/new" }}
            state={{ companyType: companyType }}
          >
            <Button appearance="primary">생성</Button>
          </Link>
        </Stack>
        <Stack spacing={8}>
          <Filter
            data={filterData(companyType)}
            value={filterValue}
            checkedValue={filterTempValue}
            setCheckedValue={setFilterTempValue}
            onSelect={handleFilterSelect}
            placement={"bottomEnd"}
          />
          <Search
            data={searchKeys(companyType, myInfo?.role)}
            onSearchKeyChange={handleSearchKeyChange}
            searchKey={searchKey}
            searchValue={searchValue}
            onSearchValueChange={handleSearchValueChange}
          />
        </Stack>
      </TableActionsContainer>
      <Table
        data={data}
        columns={columns(companyType, myInfo?.role)}
        checkable={true}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        checkedKeys={checkedKeys}
        onCheck={handleCheck}
        onCheckAll={handleCheckAll}
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
        onSortColumn={handleSortChange}
        disabledKeys={disabledKeys}
        loading={tableLoading}
      />
      <Modal
        size={"xs"}
        open={openInactiveDisableModal}
        onClose={() => setOpenInactiveDisableModal(false)}
        role="alertdialog"
      >
        <Modal.Header>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`활성 상태인 ${
            companyType === "SYSTEM_PROVIDER" ? "운영사" : "관리자"
          } 계정이 단 1개일 경우 비활성화할 수 없습니다.`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpenInactiveDisableModal(false)}
            appearance="primary"
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size={"xs"}
        open={openRemoveDisableModal}
        onClose={() => setOpenRemoveDisableModal(false)}
        role="alertdialog"
      >
        <Modal.Header>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          활성 상태인 계정은 삭제가 불가합니다. 비활성 상태로 변경 후 다시
          시도해주세요.
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpenRemoveDisableModal(false)}
            appearance="primary"
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size={"xs"}
        open={openRemoveCheckModal}
        onClose={() => setOpenRemoveCheckModal(false)}
        role="alertdialog"
      >
        <Modal.Header>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>선택한 계정이 삭제됩니다. 계속하시겠습니까?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setOpenRemoveCheckModal(false)}>취소</Button>
          <Button onClick={handleRemove} appearance="primary">
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserTable;

type UserTableProps = {
  companyType: CompanyType;
};

const columns = (type: CompanyType, role?: AccountRoleType) => {
  const columns: TableColumns[] =
    type === "SYSTEM_PROVIDER"
      ? [
          {
            title: "상태",
            key: "enabled",
            sortable: true,
            width: 70,
            Cell: EnabledCell,
          },
          {
            title: "아이디",
            key: "login_id",
            sortable: true,
            width: 200,
            flexGrow: 1,
            Cell: (props: CellProps) => (
              <LinkCell
                to={`/account/user/edit/${props.rowData.id}`}
                {...props}
              />
            ),
          },
          {
            title: "이름",
            key: "name",
            sortable: true,
            width: 200,
            flexGrow: 1,
          },
          {
            title: "생성일",
            key: "created_at",
            sortable: true,
            width: 160,
            flexGrow: 1,
            Cell: DateCell,
          },
          {
            title: "마지막 수정일",
            key: "updated_at",
            sortable: true,
            width: 160,
            flexGrow: 1,
            Cell: DateCell,
          },
          {
            title: "마지막 접속일",
            key: "last_login_at",
            sortable: true,
            width: 160,
            flexGrow: 1,
            Cell: DateCell,
          },
        ]
      : [
          {
            title: "상태",
            key: "enabled",
            sortable: true,
            width: 70,
            Cell: EnabledCell,
          },
          {
            title: "아이디",
            key: "login_id",
            sortable: true,
            width: 200,
            flexGrow: 1,
            Cell: (props: CellProps) => (
              <LinkCell
                to={`/account/user/edit/${props.rowData.id}`}
                {...props}
              />
            ),
          },
          {
            title: "역할",
            key: "role",
            sortable: true,
            width: 100,
            Cell: (props: CellProps) => (
              <MapCell mapper={ACCOUNT_ROLE_TYPE} {...props} />
            ),
          },
          {
            title: "담당",
            key: "account_placement_groups",
            width: 300,
            flexGrow: 2,
            Cell: (props: CellProps) => (
              <TextCell
                text={
                  props.rowData.account_placement_groups
                    .map((g: { id: number; name: string }) => g.name)
                    .join(", ") || "-"
                }
                {...props}
              />
            ),
          },
          {
            title: "생성일",
            key: "created_at",
            sortable: true,
            width: 160,
            flexGrow: 1,
            Cell: DateCell,
          },
          {
            title: "마지막 수정일",
            key: "updated_at",
            sortable: true,
            width: 160,
            flexGrow: 1,
            Cell: DateCell,
          },
          {
            title: "마지막 접속일",
            key: "last_login_at",
            sortable: true,
            width: 160,
            flexGrow: 1,
            Cell: DateCell,
          },
        ];

  if (type === "PUBLISHER" && role === "MASTER") {
    columns.unshift({
      title: "매체사",
      key: "company_name",
      sortable: true,
      width: 150,
    } as TableColumns);
  }
  return columns;
};

const filterData = (type: CompanyType) =>
  type === "SYSTEM_PROVIDER"
    ? [
        { label: "활성", value: "true", group: "상태", field: "enabled" },
        { label: "비활성", value: "false", group: "상태", field: "enabled" },
      ]
    : [
        { label: "관리자", value: "ADMIN", group: "역할", field: "role" },
        { label: "뷰어", value: "VIEWER", group: "역할", field: "role" },
        { label: "활성", value: "true", group: "상태", field: "enabled" },
        { label: "비활성", value: "false", group: "상태", field: "enabled" },
      ];

const searchKeys = (type: CompanyType, role?: AccountRoleType) => {
  const keys =
    type === "SYSTEM_PROVIDER"
      ? [
          {
            label: "아이디",
            value: "loginId",
          },
          {
            label: "이름",
            value: "name",
          },
        ]
      : [
          {
            label: "아이디",
            value: "loginId",
          },
        ];

  if (type === "PUBLISHER" && role === "MASTER") {
    keys.unshift({
      label: "매체사명",
      value: "company.name",
    });
  }
  return keys;
};
