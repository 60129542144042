import React from "react";
import { CheckPicker as RsuiteCheckPicker } from "rsuite";
import styled from "styled-components";
import { CheckPickerComponent } from "rsuite/esm/CheckPicker/CheckPicker";

const StyledCheckPicker: CheckPickerComponent = styled(RsuiteCheckPicker)`
  &.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  &.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: var(--rs-text-heading);
  }

  &.error.rs-picker-default .rs-picker-toggle {
    border-color: var(--rs-form-errormessage-text);
  }
`;

const CheckPicker: CheckPickerComponent = React.forwardRef((props, ref) => {
  return (
    <StyledCheckPicker
      cleanable={false}
      searchable={false}
      ref={ref}
      {...props}
    />
  );
});
CheckPicker.displayName = "CheckPicker";

export default CheckPicker;
