import React from "react";
import { Nav, NavProps } from "rsuite";
import styled from "styled-components";

const StyledNav = styled(Nav)`
  &.rs-nav.rs-nav-horizontal .rs-nav-item {
    min-width: 160px;
    text-align: center;
  }

  .rs-nav-item {
    font-size: 13px;
    padding: 12px;
    height: 44px;
  }

  &.rs-nav-subtle.rs-nav-horizontal .rs-nav-item::before {
    height: 2px;
  }

  &.rs-nav .rs-nav-item-active {
    font-weight: bold;
  }
`;

const { Item: Tab } = StyledNav;
const Tabs = (props: NavProps) => (
  <StyledNav appearance="subtle" {...props}>
    {props.children}
  </StyledNav>
);

export default Tabs;
export { Tab };
