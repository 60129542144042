import React, { useCallback, useContext, useRef, useState } from "react";
import { CreateFooter, PageHeader } from "components/layout";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Loader } from "rsuite";
import PlacementGroupForm, {
  PlacementGroupFormProps,
} from "./PlacementGroupForm";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { PlacementGroupService } from "../../../utils/api";
import { PublisherState } from "../../../utils/types";

export const initFormValue = {
  name: "",
  representative_address: null,
  pc_address: "",
  mobile_address: "",
  android_address: "",
  ios_address: "",
  account_ids: [],
  memo: "",
};

const CreatePlacementGroup = () => {
  const state = useLocation().state || {};
  const { publisher } = state as PublisherState;
  const [formValue, setFormValue] =
    useState<PlacementGroupFormProps["formValue"]>(initFormValue);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = useCallback(
    async (validate: boolean) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { hasError } = await formRef?.current?.checkAsync();
      if (!validate || hasError) return;

      try {
        setLoading(true);
        if (!formValue || !publisher) return;
        const {
          name,
          representative_address,
          pc_address,
          mobile_address,
          android_address,
          ios_address,
          account_ids,
          memo,
        } = formValue;

        await new PlacementGroupService().create({
          publisher_id: publisher as number,
          name,
          representative_address: representative_address as string,
          pc_address,
          mobile_address,
          android_address,
          ios_address,
          account_ids,
          memo,
        });

        navigate(`/inventory/placements?selected-tab=placement-group`, {
          state: { publisher },
        });
      } catch (e) {
        setErrorModalOpen(true);
      } finally {
        setLoading(false);
      }
    },
    [formValue, navigate, publisher, setErrorModalOpen]
  );

  const handleClickContinue = useCallback(async () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { hasError } = await formRef?.current?.checkAsync();
    if (hasError) return;

    try {
      setLoading(true);
      if (!formValue || !publisher) return;
      const {
        name,
        representative_address,
        pc_address,
        mobile_address,
        android_address,
        ios_address,
        account_ids,
        memo,
      } = formValue;

      const { data } = await new PlacementGroupService().create({
        publisher_id: publisher as number,
        name,
        representative_address: representative_address as string,
        pc_address,
        mobile_address,
        android_address,
        ios_address,
        account_ids,
        memo,
      });

      navigate("/inventory/placement/new", {
        state: { publisher, placementGroup: data.id },
      });
    } catch (e) {
      setErrorModalOpen(true);
    } finally {
      setLoading(false);
    }
  }, [formValue, navigate, publisher, setErrorModalOpen]);

  return (
    <>
      <PageHeader title={"게재위치 그룹 생성"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}
      <PlacementGroupForm
        formValue={formValue}
        onChange={setFormValue}
        publisher={publisher}
        onSubmit={handleSubmit}
        formRef={formRef}
        mode={"CREATE"}
        renderFooter={() => (
          <CreateFooter>
            <Link
              to={"/inventory/placements?selected-tab=placement-group"}
              state={{ publisher }}
            >
              <Button>취소</Button>
            </Link>
            <Button appearance={"primary"} type="submit">
              생성
            </Button>
            <Button appearance={"primary"} onClick={handleClickContinue}>
              계속
            </Button>
          </CreateFooter>
        )}
      />
    </>
  );
};

export default CreatePlacementGroup;
