import { FormErrorType } from "./types";
import { ValueType } from "rsuite/Checkbox";
import { getComparatorsString } from "../front-utils/filter/dynamicFilter";

export const numberWithCommas = (x: any) => {
  if (x === null || x === undefined) return "";
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const isAllValidate = (formError: FormErrorType = {}) => {
  if (Object.keys(formError).length === 0) return true;
  for (const name in formError) {
    if (typeof formError[name] === "string") return false;
  }
  return true;
};

// 언더스코어로 구성되어있는 property를 camelCase로 바꾸기.
export const underToUpper = (value: string) => {
  const _regexp = /_([a-z])/g;
  function replacer(match: string) {
    return match.replace("_", "").toUpperCase();
  }
  return value.replace(_regexp, replacer);
};

export const emptyStringToNull = (obj: any) => {
  const newObj: any = {};

  for (const key in obj) {
    newObj[key] = obj[key] === "" ? null : obj[key];
  }

  return newObj;
};

export const getFilterStringFromObject = (
  filterValue: ValueType[],
  filterData: { value: ValueType; field: string }[],
  func?: (key: string, value: ValueType[]) => any
) => {
  const filterObject = filterValue.reduce(
    (acc: { [key: string]: ValueType[] }, val) => {
      const key = filterData.find((item) => item.value === val)?.field || "";
      return {
        ...acc,
        [key]: acc[key] ? [...acc[key], val] : [val],
      };
    },
    {}
  );

  return Object.keys(filterObject).map((field: string) =>
    getComparatorsString(
      "in",
      field,
      filterObject[field].includes("true") ||
        filterObject[field].includes("false")
        ? filterObject[field].map((v) => v === "true")
        : func
        ? func(field, filterObject[field])
        : filterObject[field]
    )
  );
};
