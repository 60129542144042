import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PageHeader, TableActionsContainer } from "../../../components/layout";
import {
  DateRangePicker,
  InfoTooltip,
  MultiplePicker,
  Search,
  SelectPicker,
  Table,
} from "../../../components";
import {
  Button,
  CellProps,
  Divider,
  Stack,
  Table as RsuiteTable,
} from "rsuite";
import { DateRange } from "rsuite/cjs/DateRangePicker/types";
import moment from "moment";
import {
  MobileOSType,
  PeriodType,
  PlatformType,
  ScreenType,
  SortType,
} from "../../../utils/types";
import { ItemDataType } from "rsuite/cjs/@types/common";
import { InfoMessage } from "components/text";
import {
  DateCell,
  EnabledCell,
  MapCell,
  NumberCell,
  PercentCell,
  TextCell,
} from "../../../components/table/Cells";
import {
  MOBILE_OS_TYPE,
  PLATFORM_TYPE,
  SCREEN_TYPE,
} from "../../../utils/variables";
import { TableColumns } from "../../../components/table/Table";
import { AuthContext } from "../../../utils/context/AuthContext";
import {
  CompanyService,
  PlacementGroupService,
  ReportService,
} from "../../../utils/api";
import { underToUpper } from "../../../utils";
import _ from "lodash";
import fileDownload from "js-file-download";
const { Cell } = RsuiteTable;

const OperationReport = () => {
  const [date, setDate] = useState<DateRange | null>([
    moment().toDate(),
    moment().toDate(),
  ]);
  const [period, setPeriod] = useState<PeriodType>("DAILY");
  const [status, setStatus] = useState([true]);
  const [screen, setScreen] = useState<ScreenType[]>(["PC", "MOBILE", "IPTV"]);
  const [platform, setPlatform] = useState<PlatformType[]>(["WEB", "APP"]);
  const [mobileOS, setMobileOS] = useState<MobileOSType[]>(["IOS", "ANDROID"]);
  const [placementGroupIds, setPlacementGroupIds] = useState<number[]>([]);
  const [placementGroups, setPlacementGroups] = useState<ItemDataType[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  } | null>(null);
  const [publishers, setPublishers] = useState<{ label: string; value: any }[]>(
    []
  );
  const { myInfo } = useContext(AuthContext);
  const [publisher, setPublisher] = useState<any>(
    myInfo?.company_type === "PUBLISHER" ? myInfo?.company_id : null
  );
  const [searchKey, setSearchKey] = useState<
    "placement-name" | "placement-group-name"
  >("placement-name");
  const [searchValue, setSearchValue] = useState<string>("");
  const [appliedSearchValue, setAppliedSearchValue] =
    useState<string>(searchValue);
  const [appliedParams, setAppliedParams] = useState({
    date,
    period,
    status,
    screen,
    platform,
    mobileOS,
    placementGroupIds,
  });
  const [tableLoading, setTableLoading] = useState(false);

  const fetchPublishers = useCallback(async () => {
    if (myInfo?.role !== "MASTER") return;
    const { data } = await new CompanyService().getIdNameMeta({
      filter: "type : 'PUBLISHER'",
    });

    setPublishers(
      data.map((d: { id: number; name: string }) => ({
        label: d.name,
        value: d.id,
      }))
    );
  }, [myInfo?.role]);

  useEffect(() => {
    fetchPublishers();
  }, [fetchPublishers]);

  useEffect(() => {
    setPage(1);
  }, [size, appliedParams, publisher, appliedSearchValue]);

  const fetchPlacementGroups = useCallback(async () => {
    const { data } = await new PlacementGroupService().getIdNameMeta({
      filter: `${publisher ? `publisher.id : ${publisher}` : ""}`,
    });

    setPlacementGroups(
      data.map((d: { id: number; name: string }) => ({
        label: d.name,
        value: d.id,
      }))
    );
    setPlacementGroupIds(data.map((d: { id: number; name: string }) => d.id));
  }, [publisher]);

  useEffect(() => {
    if (myInfo?.company_type === "PUBLISHER" && !publisher) {
      setPublisher(myInfo?.company_id);
    }
  }, [myInfo?.company_id, myInfo?.company_type, publisher]);

  const fetchReport = useCallback(async () => {
    try {
      setTableLoading(true);
      const { data } = await new ReportService().getOperation({
        page,
        size,
        sort: sort
          ? underToUpper(sort.sortColumn) + "," + sort.sortType
          : ["placementGroupName,asc", "placementName,asc", "date,desc"],
        "period-type": appliedParams.period,
        "start-date": moment(appliedParams.date?.[0]).format("YYYY-MM-DD"),
        "end-date": moment(appliedParams.date?.[1]).format("YYYY-MM-DD"),
        "company-id": publisher,
        "placement-enables": appliedParams.status,
        "placement-group-ids": appliedParams.placementGroupIds,
        screens: appliedParams.screen,
        platforms: appliedParams.platform,
        "mobile-oss":
          appliedParams.screen.includes("PC") ||
          appliedParams.screen.includes("IPTV")
            ? []
            : appliedParams.mobileOS,
        "placement-name":
          searchKey === "placement-name" ? appliedSearchValue : "",
        "placement-group-name":
          searchKey === "placement-group-name" ? appliedSearchValue : "",
      });

      const reportData = data.reports.content;
      if (reportData.length > 0)
        reportData.unshift({
          type: "TOTAL",
          ...data.summary,
        });

      setData(reportData);
      setTotal(data.reports.total_elements);
    } finally {
      setTableLoading(false);
    }
  }, [
    page,
    size,
    sort,
    appliedParams.period,
    appliedParams.date,
    appliedParams.status,
    appliedParams.placementGroupIds,
    appliedParams.screen,
    appliedParams.platform,
    appliedParams.mobileOS,
    publisher,
    searchKey,
    appliedSearchValue,
  ]);

  useEffect(() => {
    fetchPlacementGroups();
  }, [fetchPlacementGroups]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  const columns: () => TableColumns[] = useMemo(
    () => () => {
      const columns: TableColumns[] = [
        {
          title: "상태",
          key: "placement_enabled",
          sortable: true,
          width: 80,
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : (
              <EnabledCell {...props} />
            ),
        },
        {
          title: "게재위치",
          key: "placement_name",
          sortable: true,
          width: 250,
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : (
              <TextCell {...props} />
            ),
        },
        {
          title: "게재위치 그룹",
          key: "placement_group_name",
          sortable: true,
          width: 150,
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : (
              <TextCell {...props} />
            ),
        },
        {
          title: "스크린",
          key: "screen",
          sortable: true,
          width: 80,
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props}>합계</Cell>
            ) : (
              <MapCell mapper={SCREEN_TYPE} {...props} />
            ),
        },
        {
          title: "플랫폼",
          key: "platform",
          sortable: true,
          width: 85,
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : (
              <MapCell mapper={PLATFORM_TYPE} {...props} />
            ),
        },
        {
          title: "모바일OS",
          key: "mobile_os",
          sortable: true,
          width: 95,
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : (
              <MapCell mapper={MOBILE_OS_TYPE} {...props} />
            ),
        },
        {
          title: "일자",
          key: "date",
          sortable: true,
          width: 100,
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : appliedParams.period === "TOTAL" ? (
              <TextCell {...props} text={"총합"} />
            ) : (
              <DateCell
                format={
                  appliedParams.period === "DAILY" ? "YYYY-MM-DD" : "YYYY-MM"
                }
                {...props}
              />
            ),
        },
        {
          title: (
            <>
              요청수
              <TableColumnInfo
                inner={"게재위치가 광고서버로 광고를 요청한 횟수"}
              />
            </>
          ),
          key: "requests",
          sortable: true,
          width: 100,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              유효 요청수
              <TableColumnInfo
                inner={"게재위치의 광고 요청이 정상 접수된 횟수"}
              />
            </>
          ),
          key: "valid_requests",
          sortable: true,
          width: 120,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              전달수
              <TableColumnInfo
                inner={"광고서버가 게재위치에 광고를 전달한 횟수"}
              />
            </>
          ),
          key: "fills",
          sortable: true,
          width: 100,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              노출수
              <TableColumnInfo inner={"게재위치에 광고가 노출된 횟수"} />
            </>
          ),
          key: "impressions",
          sortable: true,
          width: 100,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              Fill rate
              <TableColumnInfo inner={"(노출수 ÷ 유효요청수) x 100"} />
            </>
          ),
          key: "fill_rate",
          sortable: true,
          width: 100,
          flexGrow: 1,
          Cell: PercentCell,
          align: "right",
        },
        {
          title: (
            <>
              동영상 조회수
              <TableColumnInfo
                inner={
                  "동영상을 15초 이상(동영상 광고가 15초 미만인 경우 광고 전체를) 시청한 횟수"
                }
              />
            </>
          ),
          key: "views",
          sortable: true,
          width: 140,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              클릭수
              <TableColumnInfo inner={"광고가 클릭된 횟수"} />
            </>
          ),
          key: "clicks",
          sortable: true,
          width: 100,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              CTR
              <TableColumnInfo inner={"(클릭수 ÷ 노출수) x 100"} />
            </>
          ),
          key: "ctr",
          sortable: true,
          width: 100,
          flexGrow: 1,
          Cell: PercentCell,
          align: "right",
        },
        {
          title: (
            <>
              VTR
              <TableColumnInfo inner={"(동영상 조회수 ÷ 노출수) x 100"} />
            </>
          ),
          key: "vtr",
          sortable: true,
          width: 100,
          flexGrow: 1,
          Cell: PercentCell,
          align: "right",
        },
        {
          title: (
            <>
              동영상 재생수(시작)
              <TableColumnInfo inner={"동영상 재생 시작 횟수"} />
            </>
          ),
          key: "start_views",
          sortable: true,
          width: 165,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              동영상 25% 재생수
              <TableColumnInfo
                inner={"동영상에서 전체 길이의 25% 지점까지 재생된 횟수"}
              />
            </>
          ),
          key: "first_quartile_views",
          sortable: true,
          width: 165,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              동영상 50% 재생수
              <TableColumnInfo
                inner={"동영상에서 전체 길이의 50% 지점까지 재생된 횟수"}
              />
            </>
          ),
          key: "midpoint_views",
          sortable: true,
          width: 165,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              동영상 75% 재생수
              <TableColumnInfo
                inner={"동영상에서 전체 길이의 75% 지점까지 재생된 횟수"}
              />
            </>
          ),
          key: "third_quartile_views",
          sortable: true,
          width: 165,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              동영상 100% 재생수
              <TableColumnInfo
                inner={"동영상에서 전체 길이의 100% 지점까지 재생된 횟수"}
              />
            </>
          ),
          key: "complete_views",
          sortable: true,
          width: 170,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              동영상 SKIP수
              <TableColumnInfo inner={"동영상 SKIP버튼이 클릭된 횟수"} />
            </>
          ),
          key: "skips",
          sortable: true,
          width: 165,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
      ];

      if (myInfo?.role === "MASTER") {
        columns.unshift({
          title: "매체사",
          key: "company_name",
          sortable: true,
          width: 150,
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : (
              <TextCell {...props} />
            ),
        } as TableColumns);
      }
      return columns;
    },
    [appliedParams.period, myInfo?.role]
  );

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  const handleSearchKeyChange = useCallback(
    (v: "placement-name" | "placement-group-name") => {
      setSearchKey(v);
      setSearchValue("");
    },
    []
  );

  const delayedSetSearchValue = useMemo<any>(
    () => _.debounce((v: string) => setAppliedSearchValue(v), 500),
    []
  );

  const handleSearchValueChange = useCallback((v: string) => {
    setSearchValue(v);
  }, []);

  useEffect(() => {
    delayedSetSearchValue(searchValue);
  }, [searchValue, delayedSetSearchValue]);

  const handleClickFetch = useCallback(() => {
    setAppliedParams({
      date,
      period,
      status,
      screen,
      platform,
      mobileOS,
      placementGroupIds,
    });
  }, [date, mobileOS, period, placementGroupIds, platform, screen, status]);

  const handleClickDownload = useCallback(async () => {
    try {
      const { data } = await new ReportService().downloadOperation({
        sort: sort
          ? underToUpper(sort.sortColumn) + "," + sort.sortType
          : ["placementGroupName,asc", "placementName,asc", "date,desc"],
        "period-type": appliedParams.period,
        "start-date": moment(appliedParams.date?.[0]).format("YYYY-MM-DD"),
        "end-date": moment(appliedParams.date?.[1]).format("YYYY-MM-DD"),
        "company-id": publisher,
        "placement-enables": appliedParams.status,
        "placement-group-ids":
          placementGroups.length === appliedParams.placementGroupIds.length
            ? undefined
            : appliedParams.placementGroupIds,
        screens: appliedParams.screen,
        platforms: appliedParams.platform,
        "mobile-oss":
          appliedParams.screen.includes("PC") ||
          appliedParams.screen.includes("IPTV")
            ? []
            : appliedParams.mobileOS,
        "placement-name":
          searchKey === "placement-name" ? appliedSearchValue : "",
        "placement-group-name":
          searchKey === "placement-group-name" ? appliedSearchValue : "",
      });

      fileDownload(
        data,
        `운영보고서-${
          myInfo?.role !== "MASTER"
            ? myInfo?.company_name
            : publisher
            ? publishers.find((pub) => pub.value === publisher)?.label
            : "매체사전체"
        }-${moment(appliedParams.date?.[0]).format("YYYYMMDD")}-${moment(
          appliedParams.date?.[1]
        ).format("YYYYMMDD")}.xlsx`
      );
    } catch (e) {
      console.log(e);
    }
  }, [
    appliedParams.date,
    appliedParams.mobileOS,
    appliedParams.period,
    appliedParams.placementGroupIds,
    appliedParams.platform,
    appliedParams.screen,
    appliedParams.status,
    appliedSearchValue,
    myInfo?.company_name,
    myInfo?.role,
    placementGroups.length,
    publisher,
    publishers,
    searchKey,
    sort,
  ]);

  return (
    <>
      <PageHeader title={"운영 보고서"}>
        {myInfo?.role === "MASTER" && (
          <SelectPicker
            placeholder={"매체사 선택"}
            data={publishers}
            value={publisher}
            onChange={setPublisher}
            style={{ width: 200, marginLeft: 10 }}
          />
        )}
      </PageHeader>
      <Stack
        spacing={8}
        style={{
          padding: "9px 25px",
          borderBottom: "1px solid var(--rs-border-primary)",
        }}
      >
        <DateRangePicker value={date} onChange={setDate} />
        <SelectPicker
          data={periodData}
          value={period}
          onChange={setPeriod}
          cleanable={false}
          renderValue={(value: any, items: ItemDataType) => (
            <span>{`시간 : ${items.label}`}</span>
          )}
        />
        <MultiplePicker
          data={statusData}
          label={"상태"}
          value={status}
          onValueChange={setStatus}
        />
        <MultiplePicker
          data={placementGroups}
          label={"게재위치 그룹"}
          value={placementGroupIds}
          onValueChange={setPlacementGroupIds}
        />
        <MultiplePicker
          data={screenData}
          label={"스크린"}
          value={screen}
          onValueChange={setScreen}
        />
        <MultiplePicker
          data={platformData}
          label={"플랫폼"}
          value={platform}
          onValueChange={setPlatform}
        />
        <MultiplePicker
          data={mobileOSData}
          label={"모바일OS"}
          value={mobileOS}
          onValueChange={setMobileOS}
          disabled={
            !screen.includes("MOBILE") ||
            screen.includes("PC") ||
            screen.includes("IPTV")
          }
        />

        <Button appearance={"primary"} onClick={handleClickFetch}>
          조회
        </Button>
      </Stack>
      <TableActionsContainer>
        <Stack>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "var(--rs-gray-800)",
              height: "50px",
            }}
          >
            조회 결과
          </span>
          <Divider vertical />
          <span>총 {total}개</span>
          <InfoMessage style={{ marginLeft: 20 }}>
            * 데이터는 매 10분마다 업데이트 되며, 값이 존재하지 않거나 집계가
            불가한 지표는 0으로 표시됩니다.
          </InfoMessage>
        </Stack>
        <Stack spacing={8}>
          <Search
            data={searchKeys}
            searchKey={searchKey}
            onSearchKeyChange={handleSearchKeyChange}
            searchValue={searchValue}
            onSearchValueChange={handleSearchValueChange}
          />
          <Button onClick={handleClickDownload}>다운로드</Button>
        </Stack>
      </TableActionsContainer>
      <Table
        data={data}
        columns={columns()}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        sortColumn={sort?.sortColumn}
        sortType={sort?.sortType}
        onSortColumn={handleSortChange}
        rowClassName={(rowData: any) => {
          if (!rowData) return "";
          return rowData.type === "TOTAL" ? "total" : "";
        }}
        loading={tableLoading}
      />
    </>
  );
};

export default OperationReport;

const periodData = [
  {
    label: "총합",
    value: "TOTAL",
  },
  {
    label: "일별",
    value: "DAILY",
  },
  {
    label: "월별",
    value: "MONTHLY",
  },
];

const statusData = [
  {
    label: "활성",
    value: true,
  },
  {
    label: "비활성",
    value: false,
  },
];

const screenData = [
  {
    label: "PC",
    value: "PC",
  },
  {
    label: "Mobile",
    value: "MOBILE",
  },
  {
    label: "IPTV",
    value: "IPTV",
  },
];

const platformData = [
  {
    label: "Web",
    value: "WEB",
  },
  {
    label: "Application",
    value: "APP",
  },
];
const mobileOSData = [
  {
    label: "iOS",
    value: "IOS",
  },
  {
    label: "Android",
    value: "ANDROID",
  },
];

const searchKeys = [
  {
    label: "게재위치",
    value: "placement-name",
  },
  {
    label: "게재위치 그룹",
    value: "placement-group-name",
  },
];

export const TableColumnInfo = (props: { inner: any }) => (
  <InfoTooltip
    inner={props.inner}
    trigger={"hover"}
    placement={"topEnd"}
    preventOverflow
  />
);
