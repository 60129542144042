import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Checkbox, CheckPicker, CheckPickerProps } from "rsuite";
import FunnelIcon from "@rsuite/icons/Funnel";
import styled from "styled-components";
import { PickerInstance } from "rsuite/esm/Picker";
import { RedoOutlined } from "@ant-design/icons";
import { ValueType } from "rsuite/Checkbox";

interface FilterPropsType extends CheckPickerProps<any> {
  data: {
    label: string;
    value: ValueType;
    group: string;
  }[];
  onSelect?: (value: ValueType[]) => void;
  onItemCheck?: (value: ValueType[]) => void;
  disabledItemValues?: ValueType[];
  checkedValue?: ValueType[];
  setCheckedValue?: (value: ValueType[]) => void;
  renderGroupTitle?: (title: string) => string | ReactElement;
}

const Filter = (props: FilterPropsType) => {
  const ref = useRef<PickerInstance>(null);
  const [stateValue, setStateValue] = useState<ValueType[]>(props.value || []);
  const [tempValue, setTempValue] = useState<ValueType[]>(props.value || []);
  const value = props.value !== undefined ? props.value : stateValue;
  const checkedValue =
    props.checkedValue !== undefined ? props.checkedValue : tempValue;
  const groups = useMemo(
    () => Array.from(new Set(props.data.map((item) => item.group))),
    [props.data]
  );

  const onClickApply = useCallback(() => {
    if (props.value !== undefined) {
      props.onSelect?.(checkedValue);
    } else {
      setStateValue(checkedValue);
    }
    ref.current?.close?.();
  }, [checkedValue, ref, props]);

  const onClickRelease = useCallback(() => {
    if (props.checkedValue !== undefined) {
      props.setCheckedValue?.([]);
    } else {
      setTempValue([]);
    }
    if (props.onItemCheck) props.onItemCheck([]);
  }, [props]);

  const onCheck = useCallback(
    (value, checked) => {
      const temp = [...checkedValue];
      if (checked) {
        temp.push(value);
      } else {
        temp.splice(temp.indexOf(value), 1);
      }

      if (props.checkedValue !== undefined) {
        props.setCheckedValue?.(temp);
      } else {
        setTempValue(temp);
      }
      if (props.onItemCheck) props.onItemCheck(temp);
    },
    [checkedValue, props]
  );

  const onClose = useCallback(() => {
    if (props.value !== undefined) {
      if (props.checkedValue !== undefined) {
        props.setCheckedValue?.(props.value);
      } else {
        setTempValue(props.value);
      }
    } else {
      if (props.checkedValue !== undefined) {
        props.setCheckedValue?.(stateValue);
      } else {
        setTempValue(stateValue);
      }
    }
    if (props.onClose) props.onClose();
  }, [props, stateValue]);

  const onClean = useCallback(() => {
    // onClickRelease
    if (props.checkedValue !== undefined) {
      props.setCheckedValue?.([]);
    } else {
      setTempValue([]);
    }
    if (props.onItemCheck) props.onItemCheck([]);
    //onClickApply
    if (props.value !== undefined) {
      props.onSelect?.([]);
    } else {
      setStateValue([]);
    }
    ref.current?.close?.();
  }, [props]);

  useEffect(() => {
    // value => checkedValue 한 방향 업데이트, 적용 누르고 나서 벌어짐

    if (props.value !== undefined) {
      if (props.checkedValue !== undefined) {
        props.setCheckedValue?.(props.value);
      } else {
        setTempValue(props.value);
      }
    } else {
      if (props.checkedValue !== undefined) {
        props.setCheckedValue?.(stateValue);
      } else {
        setTempValue(stateValue);
      }
    }
    // eslint-disable-next-line
  }, [stateValue, props.value]);

  return (
    <>
      <CheckPicker
        style={props.style}
        ref={ref}
        value={value}
        data={props.data}
        cleanable={true}
        searchable={false}
        onClose={onClose}
        placeholder={
          <PlaceholderContainer>
            <FunnelIcon style={{ marginRight: 5 }} />
            필터: 선택
          </PlaceholderContainer>
        }
        renderValue={() => {
          return (
            <div>
              <FunnelIcon style={{ marginRight: 5 }} />
              필터: 적용중
            </div>
          );
        }}
        renderExtraFooter={() => (
          <Footer>
            <Button
              appearance="subtle"
              onClick={onClickRelease}
              style={{ marginRight: 5 }}
            >
              <RedoOutlined
                style={{
                  verticalAlign: "text-top",
                  transform: "rotate(-45deg)",
                  marginRight: 5,
                }}
              />
              초기화
            </Button>
            <Button appearance="primary" onClick={onClickApply}>
              적용
            </Button>
          </Footer>
        )}
        renderMenu={() => {
          return (
            <div style={{ display: "flex" }}>
              {groups.map((title, index) => (
                <GroupContainer
                  key={`group_${index}`}
                  style={{ width: groups.length === 1 ? "100%" : undefined }}
                >
                  <GroupTitle>
                    {props.renderGroupTitle
                      ? props.renderGroupTitle(title)
                      : title}
                  </GroupTitle>
                  <GroupContent>
                    {props.data
                      .filter((item) => item.group === title)
                      .map((item) => (
                        <div key={item.value}>
                          <Checkbox
                            value={item.value}
                            checked={checkedValue?.includes(item.value)}
                            onChange={onCheck}
                            disabled={props.disabledItemValues?.includes(
                              item.value
                            )}
                          >
                            {item.label}
                          </Checkbox>
                        </div>
                      ))}
                  </GroupContent>
                </GroupContainer>
              ))}
            </div>
          );
        }}
        placement={props.placement}
        onClean={onClean}
      />
    </>
  );
};

export default Filter;

const Footer = styled.div`
  border-top: 1px solid var(--rs-border-primary);
  padding: 11px;
  display: flex;
  justify-content: space-between;
`;

const PlaceholderContainer = styled.div`
  color: var(--rs-text-heading);
`;

const GroupContainer = styled.div`
  border-right: 1px solid var(--rs-border-primary);
`;
const GroupTitle = styled.div`
  font-weight: bold;
  padding: 10px 20px;
  font-size: 14px;
  border-bottom: 1px solid var(--rs-border-primary);
`;
const GroupContent = styled.div`
  padding: 10px 20px 10px 5px;
`;
