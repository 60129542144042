import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PlacementForm, { PlacementFormProps } from "./PlacementForm";
import { initFormValue } from "./CreatePlacement";
import { CreateFooter, PageHeader } from "components/layout";
import { Button, Loader } from "rsuite";
import { AdNetworkPriorityGroupControlProps } from "./AdNetworkPriorityGroupControl";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { AdNetworkUnitService, PlacementService } from "../../../utils/api";
import {
  MobileOSType,
  PlacementAdNetworkCreativeType,
  PlacementPriorityType,
  PublisherState,
} from "../../../utils/types";
import {
  getComparatorsString,
  getOperatorsString,
} from "../../../front-utils/filter/dynamicFilter";

const EditPlacement = () => {
  const { id = "" } = useParams<{ id: string }>();
  const state = useLocation().state || {};
  const { publisher: publisherByLocation } = state as PublisherState;
  const [publisher, setPublisher] = useState<number | string>("");
  const [formValue, setFormValue] =
    useState<PlacementFormProps["formValue"]>(initFormValue);
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const navigate = useNavigate();
  const [adNetworkUnits, setAdNetworkUnits] = useState<
    AdNetworkPriorityGroupControlProps["adNetworkUnits"]
  >([]);
  const formRef = useRef<HTMLFormElement>(null);
  const [enableAdNetworkUnits, setEnableAdNetworkUnits] = useState<
    AdNetworkPriorityGroupControlProps["adNetworkUnits"]
  >([]);
  const [usingAdNetworkUnits, setUsingAdNetworkUnits] = useState<
    AdNetworkPriorityGroupControlProps["adNetworkUnits"]
  >([]);

  const fetchData = useCallback(async () => {
    const { data } = await new PlacementService().getDetail(id);

    setFormValue({
      id: data.id,
      placement_group: data.placement_group.id,
      enabled: data.enabled,
      code: data.code,
      name: data.name,
      screen:
        data.platform === "PC" || data.platform === "IPTV"
          ? data.platform
          : "MOBILE",
      platform: data.application,
      mobile_os:
        data.platform === "PC" || data.platform === "IPTV"
          ? null
          : data.platform,
      width: data.width.toString(),
      height: data.height.toString(),
      ad_network: data.is_using_ad_network ? ["true"] : [],
      priority_groups:
        data.ad_network_priority_groups?.length > 0
          ? data.ad_network_priority_groups.map(
              (pg: {
                id: number;
                creative_type: PlacementAdNetworkCreativeType;
                priority_type: PlacementPriorityType;
                ad_network_priorities: {
                  id: number;
                  priority?: number;
                  limit_impression?: boolean;
                  impressions?: number;
                  ad_network_unit: { id: number };
                  ratio?: number;
                }[];
              }) => ({
                id: pg.id,
                creative_type: pg.creative_type,
                priority_type: pg.priority_type,
                ad_network_unit_ids: pg.ad_network_priorities.map(
                  (p: { ad_network_unit: { id: number } }) =>
                    p.ad_network_unit.id
                ),
                options: pg.ad_network_priorities
                  .map((p) => ({
                    id: p.id,
                    ad_network_unit_id: p.ad_network_unit.id,
                    priority: p.priority?.toString() || null,
                    limit_impression: p.limit_impression,
                    impressions: p.impressions ? p.impressions.toString() : "",
                    ratio: p.ratio?.toString() || "",
                  }))
                  .sort((a, b) => {
                    return (
                      adNetworkUnits.findIndex(
                        (u) => u.id === a.ad_network_unit_id
                      ) -
                      adNetworkUnits.findIndex(
                        (u) => u.id === b.ad_network_unit_id
                      )
                    );
                  }),
              })
            )
          : [
              {
                priority_type: "RATIO",
                ad_network_unit_ids: [],
                options: [],
              },
            ],
    });

    setPublisher(data.publisher.id);

    if (data.ad_network_priority_groups?.length > 0) {
      // priority_group length 1 이라고 보고 짜둠. 나중에 추가 가능하면 변경해야 함.
      setUsingAdNetworkUnits(
        data.ad_network_priority_groups[0].ad_network_priorities.map(
          (p: {
            ad_network_unit: {
              id: number;
              name: string;
              ad_network: {
                id: number;
                enabled: boolean;
                code: string;
                type: string;
                name: string;
              };
            };
          }) => ({
            id: p.ad_network_unit.id,
            name: p.ad_network_unit.name,
            ad_network_name: p.ad_network_unit.ad_network.name,
            ad_network_code: p.ad_network_unit.ad_network.code,
            ad_network_type: p.ad_network_unit.ad_network.type,
            enabled: p.ad_network_unit.ad_network.enabled,
          })
        )
      );
    }
  }, [adNetworkUnits, id]);

  const fetchEnableAdNetworkUnits = useCallback(async () => {
    if (!publisher) return;
    const { data } = await new AdNetworkUnitService().getIdNameMeta({
      filter: getOperatorsString(
        "and",
        [
          `adNetwork.enabled : true`,
          getComparatorsString(":", "adNetwork.publisher.id", publisher),
          getComparatorsString(
            ":",
            "platform",
            formValue.screen === "MOBILE"
              ? (formValue.mobile_os as MobileOSType)
              : formValue.screen
          ),
          getComparatorsString(":", "application", formValue.platform),
          formValue.width !== ""
            ? getComparatorsString("<:", "width", formValue.width)
            : "",
          formValue.height !== ""
            ? getComparatorsString("<:", "height", formValue.height)
            : "",
        ].filter((v) => v !== "")
      ),
      sort: ["adNetwork.name,desc", "name,asc"],
    });
    const units = data.map(
      (d: {
        id: number;
        name: string;
        ad_network_code: string;
        ad_network_name: string;
        ad_network_type: string;
        type: string;
      }) => ({
        id: d.id,
        name: d.name,
        ad_network_code: d.ad_network_code,
        ad_network_type: d.ad_network_type,
        ad_network_name: d.ad_network_name,
        enabled: true,
        type: d.type,
      })
    );

    setEnableAdNetworkUnits(units);
    setAdNetworkUnits(units);
  }, [
    formValue.height,
    formValue.mobile_os,
    formValue.platform,
    formValue.screen,
    formValue.width,
    publisher,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchEnableAdNetworkUnits();
  }, [fetchEnableAdNetworkUnits]);

  useEffect(() => {
    if (usingAdNetworkUnits.filter((used) => !used.enabled).length > 0) {
      //비활성된 네트워크 포함하는 게재위치
      setAdNetworkUnits(
        enableAdNetworkUnits.concat(
          usingAdNetworkUnits.filter((used) => !used.enabled)
        )
      );
    }
  }, [usingAdNetworkUnits, enableAdNetworkUnits]);

  const handleSubmit = useCallback(async () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { hasError } = await formRef?.current?.checkAsync();
    formRef?.current?.checkForField("priority_groups");

    if (hasError) return;

    try {
      setLoading(true);
      if (!formValue) return;
      const {
        id,
        enabled,
        code,
        name,
        screen,
        platform,
        mobile_os,
        width,
        height,
        ad_network,
        priority_groups,
      } = formValue;

      await new PlacementService().edit(id as number, {
        enabled: enabled as boolean,
        code,
        name,
        platform: screen === "PC" ? screen : (mobile_os as MobileOSType),
        application: platform,
        width,
        height,
        is_using_ad_network: ad_network.includes("true"),
        ad_network_priority_groups: ad_network.includes("true")
          ? priority_groups.map((pg) => ({
              id: pg.id || undefined,
              creative_type: pg.creative_type,
              priority_type: pg.priority_type,
              ad_network_priorities: pg.options.map((op) => ({
                id: op.id || undefined,
                priority: op.priority ? parseInt(op.priority) : undefined,
                impressions: op.impressions
                  ? parseInt(op.impressions)
                  : undefined,
                limit_impression:
                  pg.priority_type === "RATIO"
                    ? undefined
                    : op.limit_impression,
                ad_network_unit_id: op.ad_network_unit_id,
                ratio:
                  pg.priority_type === "RATIO" ? parseInt(op.ratio) : undefined,
              })),
            }))
          : undefined,
      });

      navigate(`/inventory/placements?selected-tab=placement`, {
        state: { publisher: publisherByLocation },
      });
    } catch (e) {
      setErrorModalOpen(true);
    } finally {
      setLoading(false);
    }
  }, [formValue, navigate, publisherByLocation, setErrorModalOpen]);

  return (
    <>
      <PageHeader title={"게재위치 조회"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}
      <PlacementForm
        editMode={true}
        formValue={formValue}
        onFormChange={setFormValue}
        renderFooter={() => (
          <CreateFooter>
            <Link
              to={"/inventory/placements?selected-tab=placement"}
              state={{ publisher: publisherByLocation }}
            >
              <Button>취소</Button>
            </Link>
            <Button appearance={"primary"} onClick={handleSubmit}>
              저장
            </Button>
          </CreateFooter>
        )}
        adNetworkUnits={adNetworkUnits}
        publisher={publisher}
        formRef={formRef}
      />
    </>
  );
};

export default EditPlacement;
