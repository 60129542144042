import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CreateItemContainer,
  CreateItemTitle,
  CreateItemValue,
} from "../../../components/layout";
import { CreateItemTitleText } from "../../../components/text";
import {
  Checkbox,
  Divider,
  Radio,
  RadioGroup,
  Schema,
  Stack,
  Toggle,
  Tag,
  TagGroup,
  Loader,
} from "rsuite";
import {
  CheckPicker,
  Form,
  InfoTooltip,
  InputCount,
  InputNumber,
  SelectPicker,
  TextAreaCount,
} from "../../../components";
import {
  AdNetworkCodeType,
  AdNetworkType,
  BidType,
  CurrencyType,
  SalesType,
} from "../../../utils/types";
import styled from "styled-components";
import {
  CustomMinusCircleFilled,
  CustomPlusCircleFilled,
} from "../../../components/icon";
import {
  AdNetworkService,
  CompanyService,
  PlacementGroupService,
} from "../../../utils/api";
import { AuthContext } from "../../../utils/context/AuthContext";

const AdNetworkForm = (props: AdNetworkFormProps) => {
  const {
    editMode,
    formValue,
    onSubmit,
    adNetworkName,
    placementGroups: autoReviewPlacementGroups,
  } = props;
  const [publisherName, setPublisherName] = useState("");
  const [adNetworkCodes, setAdNetworkCodes] = useState<
    { label: string; value: AdNetworkCodeType }[]
  >([]);
  const [placementGroups, setPlacementGroups] = useState<
    { label: string; value: number }[] | undefined
  >(undefined);

  const model = useMemo(
    () =>
      Schema.Model({
        code: Schema.Types.StringType().isRequired("네트워크를 선택하세요."),
      }),
    []
  );
  const handleFormChange = useCallback(
    (formValue: AdNetworkFormProps["formValue"]) => {
      props.onChange(formValue);
    },
    [props]
  );
  const { myInfo } = useContext(AuthContext);
  const reviewPlacementGroups = placementGroups?.filter(
    (pg) => !autoReviewPlacementGroups?.find((p) => p.id === pg.value)
  );

  const fetchPublisherName = useCallback(async () => {
    if (!props.publisher) return;
    const { data } = await new CompanyService().getDetail(props.publisher);
    setPublisherName(data.name);
  }, [props.publisher]);

  const fetchAdNetworks = useCallback(async () => {
    if (myInfo?.role !== "MASTER") return;
    const { data } = await new AdNetworkService().getCodes();
    setAdNetworkCodes(
      data.map((network: { value: AdNetworkCodeType; name: string }) => ({
        value: network.value,
        label: network.name,
      }))
    );
  }, [myInfo?.role]);

  const fetchPlacementGroups = useCallback(async () => {
    if (!props.publisher) return;
    const { data } = await new PlacementGroupService().getIdNameMeta({
      filter: `${props.publisher ? `publisher.id : ${props.publisher}` : ""}`,
      sort: "name,asc",
    });

    setPlacementGroups(
      data.map((pg: { id: number; name: string }) => ({
        label: pg.name,
        value: pg.id,
      }))
    );
  }, [props.publisher]);

  useEffect(() => {
    fetchPublisherName();
  }, [fetchPublisherName]);

  useEffect(() => {
    fetchAdNetworks();
  }, [fetchAdNetworks]);

  useEffect(() => {
    fetchPlacementGroups();
  }, [fetchPlacementGroups]);

  const checkAllPlacementGroup = useCallback(
    (value: any, checked: boolean) => {
      if (formValue)
        props.onChange({
          ...formValue,
          placement_group_ids: checked
            ? (placementGroups || []).map((pg) => pg.value)
            : [],
        });
    },
    [formValue, placementGroups, props]
  );

  if (!formValue) return null;
  return (
    <>
      <Form
        layout="horizontal"
        model={model}
        formValue={props.formValue}
        onChange={handleFormChange}
        onSubmit={onSubmit}
      >
        {editMode && (
          <Form.Group controlId="enabled">
            <Form.ControlLabel
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <CreateItemTitleText>상태</CreateItemTitleText>
            </Form.ControlLabel>
            <CreateItemValue>
              <Stack spacing={10}>
                <span
                  style={{
                    width: 40,
                    display: "inline-block",
                    color: formValue.enabled
                      ? "var(--rs-text-active)"
                      : undefined,
                    float: "left",
                  }}
                >
                  {formValue.enabled ? "활성" : "비활성"}
                </span>
                <Form.Control
                  name="enabled"
                  accepter={Toggle}
                  checked={formValue.enabled}
                />
              </Stack>
            </CreateItemValue>
          </Form.Group>
        )}

        {myInfo?.role === "MASTER" && (
          <CreateItemContainer style={{ padding: 0, marginBottom: 24 }}>
            <CreateItemTitle style={{ marginBottom: 4 }}>
              <CreateItemTitleText>매체사</CreateItemTitleText>
            </CreateItemTitle>
            <CreateItemValue>{publisherName}</CreateItemValue>
          </CreateItemContainer>
        )}

        <Form.Group controlId="code">
          <Form.ControlLabel>
            <CreateItemTitleText required>네트워크</CreateItemTitleText>
          </Form.ControlLabel>
          {myInfo?.role === "MASTER" ? (
            <Form.Control
              name="code"
              accepter={SelectPicker}
              style={{ width: 300 }}
              placeholder={"선택"}
              searchable={false}
              data={adNetworkCodes}
              plaintext={editMode}
            />
          ) : (
            <span>{adNetworkName}</span>
          )}
        </Form.Group>

        <Form.Group controlId="type">
          <Form.ControlLabel>
            <CreateItemTitleText required>연동 방식</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="type"
            accepter={RadioGroup}
            inline
            plaintext={editMode}
          >
            <Radio value={"API"}>API</Radio>
            <Radio value={"SDK"}>SDK</Radio>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="placement_group_ids">
          <Form.ControlLabel
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <CreateItemTitleText required>미검수 설정</CreateItemTitleText>
            <InfoTooltip
              inner={
                <>
                  <div>
                    {`선택된 게재위치그룹의 광고는 ‘자동 승인’되어 바로 노출 가능합니다.`}
                  </div>
                  <div>
                    {`별도로 선택하지 않은 게재위치그룹에서는 검수 단계를 거쳐 ‘승인’되어야 광고 노출이 가능합니다.`}
                  </div>
                </>
              }
              iconStyle={{ marginLeft: 10 }}
            />
          </Form.ControlLabel>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "calc(100% - 150px)",
            }}
          >
            <Form.Control
              name="placement_group_ids"
              accepter={CheckPicker}
              placeholder={
                placementGroups === undefined || placementGroups.length > 0
                  ? "선택"
                  : "게재위치그룹이 없습니다"
              }
              style={{ minWidth: 300 }}
              data={placementGroups || []}
              renderMenu={(menu: ReactNode) => {
                return (
                  <div>
                    <div
                      className="rs-check-item"
                      style={{
                        borderBottom: `1px solid var(--rs-border-primary)`,
                      }}
                    >
                      <Checkbox
                        indeterminate={
                          formValue.placement_group_ids.length > 0 &&
                          formValue.placement_group_ids.length <
                            (placementGroups?.length || 0)
                        }
                        checked={
                          formValue.placement_group_ids.length > 0 &&
                          formValue.placement_group_ids.length ===
                            placementGroups?.length
                        }
                        onChange={checkAllPlacementGroup}
                      >
                        전체
                      </Checkbox>
                    </div>
                    {menu}
                  </div>
                );
              }}
            />

            {editMode && (
              <>
                <div style={{ marginTop: 10 }}>
                  <CreateItemTitleText style={{ fontSize: "12px" }}>
                    검수 게재위치그룹 현황
                    <InfoTooltip
                      inner={
                        "미검수 설정에 등록하지 않은 게재위치그룹(매체)은 검수로 자동 등록됩니다."
                      }
                    />
                  </CreateItemTitleText>
                </div>
                <div style={{ marginTop: 5 }}>
                  {reviewPlacementGroups === undefined ? (
                    <Loader />
                  ) : reviewPlacementGroups.length > 0 ? (
                    <TagGroup>
                      {reviewPlacementGroups?.map((pg) => (
                        <Tag key={pg.value}>{pg.label}</Tag>
                      ))}
                    </TagGroup>
                  ) : (
                    "검수중인 그룹 없음"
                  )}
                </div>
              </>
            )}
          </div>
        </Form.Group>
        <Divider />
        <Form.Group controlId="contract_info">
          <Form.ControlLabel>
            <CreateItemTitleText>계약 정보</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control name="contract_info" accepter={ContractInfoControl} />
        </Form.Group>
        <Divider />
        <Form.Group controlId="memo">
          <Form.ControlLabel>
            <CreateItemTitleText>메모</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="memo"
            accepter={TextAreaCount}
            maxLength={1000}
            style={{ width: 800 }}
          />
        </Form.Group>

        <Divider />
        {props.renderFooter()}
      </Form>
    </>
  );
};

export default AdNetworkForm;

const ContractInfoControl = (props: ContractInfoControlProps) => {
  const { onChange, value } = props;
  const [contractInfos, setContractInfos] =
    useState<ContractInfoControlProps["value"]>(value);
  const [needToUpdatePrice, setNeedToUpdatePrice] = useState(false);

  const handleChangeByIndex = useCallback(
    (rowIndex: number, value: ContractInfo) => {
      const nextGroups: ContractInfoControlProps["value"] = contractInfos.map(
        (g, i) => (i === rowIndex ? value : g)
      );
      setContractInfos(nextGroups);
    },
    [contractInfos]
  );
  const handleChangeValue = useCallback(
    (index: number, key: keyof ContractInfo, value: any) => {
      let next = { ...contractInfos[index], [key]: value };
      if (key === "currency" && contractInfos[index].currency !== value) {
        next = { ...next, price: "" };
      }

      handleChangeByIndex(index, next);
    },
    [contractInfos, handleChangeByIndex]
  );

  const handleAddContractInfo = useCallback(() => {
    setContractInfos([
      ...contractInfos,
      {
        name: "",
        bid_type: "CPM",
        sales_type: "FIXED",
        currency: "KRW",
        price: "",
      },
    ]);
  }, [contractInfos]);

  const handleRemoveContractInfo = useCallback(
    (index: number) => {
      const infos = [...contractInfos];
      infos.splice(index, 1);
      setContractInfos(infos);
    },
    [contractInfos]
  );

  useEffect(
    () => {
      onChange(contractInfos);
    },
    // onChange 추가시 무한루프
    // eslint-disable-next-line
    [contractInfos]
  );

  return contractInfos.map((rowValue, index) => (
    <Stack spacing={8} key={index} style={{ marginBottom: 5 }}>
      <InputCount
        maxLength={30}
        value={rowValue.name}
        onChange={(value: ContractInfo["name"]) =>
          handleChangeValue(index, "name", value)
        }
      />
      <SelectPicker
        data={[
          { label: "CPM", value: "CPM" },
          { label: "CPC", value: "CPC" },
          { label: "CPI", value: "CPI" },
          { label: "CPA", value: "CPA" },
        ]}
        placeholder={"판매 방식"}
        style={{ width: 150 }}
        value={rowValue.bid_type}
        onChange={(value: ContractInfo["bid_type"]) =>
          handleChangeValue(index, "bid_type", value)
        }
        cleanable={false}
      />
      <SelectPicker
        data={[
          { label: "고정가", value: "FIXED" },
          { label: "변동가", value: "FLOATING" },
        ]}
        placeholder={"판매 유형"}
        style={{ width: 150 }}
        value={rowValue.sales_type}
        onChange={(value: ContractInfo["sales_type"]) =>
          handleChangeValue(index, "sales_type", value)
        }
        cleanable={false}
      />
      <div>
        <StyledSelectPicker
          style={{
            display: "inline-block",
            width: 80,
            verticalAlign: "middle",
          }}
          placeholder={"통화"}
          data={[
            { label: "₩", value: "KRW" },
            { label: "$", value: "USD" },
          ]}
          searchable={false}
          cleanable={false}
          value={rowValue.currency}
          onChange={async (value: ContractInfo["currency"]) => {
            handleChangeValue(index, "currency", value);
            setNeedToUpdatePrice(true);
          }}
        />
        <StyledInputNumber
          style={{ width: 200, verticalAlign: "middle" }}
          value={rowValue.price}
          onChange={(value: ContractInfo["price"]) =>
            handleChangeValue(index, "price", value)
          }
          acceptFloat={rowValue.currency === "USD"}
          updateToPropValue={needToUpdatePrice}
          setUpdateToPropValue={setNeedToUpdatePrice}
        />
      </div>
      {index === 0 ? (
        <CustomPlusCircleFilled
          style={{ cursor: "pointer" }}
          onClick={handleAddContractInfo}
        />
      ) : (
        <CustomMinusCircleFilled
          style={{ cursor: "pointer" }}
          onClick={() => handleRemoveContractInfo(index)}
        />
      )}
    </Stack>
  ));
};

interface ContractInfo {
  name: string;
  bid_type: BidType;
  sales_type: SalesType;
  currency: CurrencyType;
  price: string;
}

export type AdNetworkFormProps = {
  formValue?: {
    code: string | null;
    type: AdNetworkType;
    placement_group_ids: number[];
    contract_info: ContractInfo[];
    memo: string;
    enabled?: boolean;
  };
  onChange: (formValue: AdNetworkFormProps["formValue"]) => void;
  renderFooter: () => ReactElement;
  publisher?: number | string;
  editMode?: boolean;
  onSubmit?: (validate: boolean) => Promise<void>;
  adNetworkName?: string;
  placementGroups?: { id: number; name: string }[];
};

type ContractInfoControlProps = {
  value: ContractInfo[];
  onChange: (value: ContractInfo[]) => void;
};

export const StyledSelectPicker: typeof SelectPicker = styled(SelectPicker)`
  .rs-btn.rs-btn-default.rs-picker-toggle {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
export const StyledInputNumber = styled(InputNumber)`
  &.rs-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
