import React, { useCallback } from "react";
import { FormControlProps, Stack } from "rsuite";
import { InputCount } from "../input";
import { Form } from "./index";

export const EmailControl = (props: EmailControlProps) => {
  const { onChange, value = "@", mode } = props;
  const splitByAt = value.split("@");

  const handleChange = useCallback(
    (order: number, changedValue: string) => {
      if (changedValue.includes("@")) return;
      const newValue =
        order === 0
          ? `${changedValue}@${value.split("@")[1]}`
          : `${value.split("@")[0]}@${changedValue}`;

      onChange(newValue);
    },
    [onChange, value]
  );

  return mode === "VIEW" ? (
    <span>{value === "@" ? "" : value}</span>
  ) : (
    <Stack spacing={8}>
      <InputCount
        id={`email_0`}
        maxLength={50}
        value={splitByAt[0]}
        onChange={(v) => handleChange(0, v)}
      />
      @
      <InputCount
        id={`email_1`}
        maxLength={30}
        value={splitByAt[1]}
        onChange={(v) => handleChange(1, v)}
      />
      <Form.HelpText>영문(대소), 숫자, 언더바(_), ., - 사용 가능</Form.HelpText>
    </Stack>
  );
};

export const PhoneControl = (props: PhoneControlProps) => {
  const { onChange, value = "--", mode } = props;
  const splitByHyphen = value.split("-");

  const handleChange = useCallback(
    (order: number, changedValue: string) => {
      const checkNumber = /^[0-9]*$/;
      if (!checkNumber.test(changedValue)) return;
      const newValue =
        order === 0
          ? `${changedValue}-${value.split("-")[1]}-${value.split("-")[2]}`
          : order === 1
          ? `${value.split("-")[0]}-${changedValue}-${value.split("-")[2]}`
          : `${value.split("-")[0]}-${value.split("-")[1]}-${changedValue}`;

      onChange(newValue);
    },
    [onChange, value]
  );

  return mode === "VIEW" ? (
    <span>{value === "--" ? "" : value}</span>
  ) : (
    <>
      <Stack spacing={8}>
        <InputCount
          id={`phone_0`}
          style={{ width: 100 }}
          maxLength={4}
          value={splitByHyphen[0]}
          onChange={(v) => handleChange(0, v)}
        />
        -
        <InputCount
          id={`phone_1`}
          style={{ width: 100 }}
          maxLength={4}
          value={splitByHyphen[1]}
          onChange={(v) => handleChange(1, v)}
        />
        -
        <InputCount
          id={`phone_2`}
          style={{ width: 100 }}
          maxLength={4}
          value={splitByHyphen[2]}
          onChange={(v) => handleChange(2, v)}
        />
      </Stack>
    </>
  );
};

interface EmailControlProps extends FormControlProps {
  value: string;
  onChange: (value: string) => void;
  mode: "CREATE" | "VIEW" | "EDIT";
}

interface PhoneControlProps extends FormControlProps {
  value: string;
  onChange: (value: string) => void;
  mode: "CREATE" | "VIEW" | "EDIT";
}
