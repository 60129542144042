import React, { createContext, useState, useEffect, useCallback } from "react";
import { AccountRoleType, CompanyType } from "../types";
import { AuthService } from "../api";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext<{
  myInfo: MyInfo | null | undefined;
  setMyInfoHandler: (myInfo: MyInfo | null | undefined) => void;
}>({
  myInfo: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
  setMyInfoHandler: (_: MyInfo | null | undefined) => {},
});

const AuthContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [myInfo, setMyInfo] = useState<MyInfo | null | undefined>(undefined);
  const setMyInfoHandler = (myInfo: MyInfo | null | undefined) =>
    setMyInfo(myInfo);
  const navigate = useNavigate();

  const getMe = useCallback(async () => {
    try {
      const service = new AuthService();
      const { data } = await service.getMe();
      setMyInfoHandler(data);
    } catch (e: any) {
      if (e?.response?.data?.error_code === "BLOCKED_IP") {
        navigate("/*");
      }
      setMyInfoHandler(null);
    }
  }, [navigate]);

  useEffect(() => {
    getMe();

    window.onfocus = () => {
      // 현재 세션 정보와 로그인 되어있던 정보를 브라우저 창 focus 시 확인해서 같은 아이디가 아니면 login 화면으로 이동시킨다
      new AuthService()
        .getMe()
        .then(({ data }) => {
          if (!data || data.id !== myInfo?.id) {
            setMyInfoHandler(null);
            navigate("/login");
          }
        })
        .catch((e: any) => {
          setMyInfoHandler(null);
          if (e?.response?.data?.error_code === "BLOCKED_IP") {
            navigate("/*");
          } else {
            navigate("/login");
          }
        });
    };
  }, [getMe, myInfo?.id, navigate]);

  return (
    <AuthContext.Provider value={{ myInfo, setMyInfoHandler }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

type MyInfo = {
  id: number;
  role: AccountRoleType;
  login_id: string;
  name: string;
  email: string;
  company_id: number;
  company_name: string;
  company_type: CompanyType;
  default_review_placement_group_id?: number;
  default_ad_network_id?: number;
};
