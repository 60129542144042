import React, { useCallback, useContext, useEffect, useState } from "react";
import { CreateFooter, PageHeader } from "../../../components/layout";
import CompanyForm, { CompanyFormProps } from "./CompanyForm";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Loader, Modal } from "rsuite";
import { CompanyService } from "../../../utils/api";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { AuthContext } from "../../../utils/context/AuthContext";

const EditCompany = () => {
  const { id = "" } = useParams<{ id: string }>();
  const [formValue, setFormValue] =
    useState<CompanyFormProps["formValue"]>(undefined);
  const [originFileName, setOriginFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const navigate = useNavigate();
  const { myInfo } = useContext(AuthContext);
  const [isCheckedDuplicated, setIsCheckedDuplicated] = useState(true);
  const [isDuplicated, setIsDuplicated] = useState<boolean>();
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const fetchData = useCallback(async () => {
    const { data } = await new CompanyService().getDetail(id);

    setFormValue({
      id: data.id,
      type: "PUBLISHER",
      enabled: data.enabled,
      name: data.name,
      business_registration_number: data.business_registration_number,
      business_registration_certificate: [],
      business_registration_certificate_file_name: "",
      address: data.address || "",
      business_type: data.business_type || "",
      business_item: data.business_item || "",
      site_address: data.site_address || "",
      settlement_manager_name: data.settlement_manager_name || "",
      settlement_manager_email: data.settlement_manager_email || "@",
      settlement_manager_phone: data.settlement_manager_phone || "--",
      memo: data.memo || "",
      created_at: data.created_at,
      updated_at: data.updated_at,
    });
    setOriginFileName(data.business_registration_certificate_file_name);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = useCallback(
    async (validate: boolean) => {
      if (!validate || isDuplicated || !isCheckedDuplicated) return;

      try {
        setLoading(true);
        if (!formValue) return;
        const {
          id,
          type,
          enabled,
          name,
          business_registration_number,
          address,
          business_type,
          business_item,
          site_address,
          settlement_manager_name,
          settlement_manager_email,
          settlement_manager_phone,
          memo,
          business_registration_certificate,
        } = formValue;

        await new CompanyService().edit(id as number, {
          id: id as number,
          type,
          enabled: enabled as boolean,
          name,
          business_registration_number,
          address,
          business_type,
          business_item,
          site_address,
          settlement_manager_name,
          settlement_manager_email:
            settlement_manager_email === "@" ? "" : settlement_manager_email,
          settlement_manager_phone:
            settlement_manager_phone === "--" ? "" : settlement_manager_phone,
          memo,
        });

        if (business_registration_certificate[0]) {
          const formData = new FormData();
          formData.append(
            "file",
            business_registration_certificate[0].blobFile as File
          );
          await new CompanyService().uploadRegistrationCertificate(
            id as number,
            formData
          );
        }
        if (myInfo?.role === "MASTER") navigate("/account/company");
        else if (myInfo?.role === "ADMIN")
          navigate(`/account/company/view/${id}`);
      } catch (e) {
        setErrorModalOpen(true);
      } finally {
        setLoading(false);
      }
    },
    [
      formValue,
      isCheckedDuplicated,
      isDuplicated,
      myInfo?.role,
      navigate,
      setErrorModalOpen,
    ]
  );

  return (
    <>
      <PageHeader title={"회사 수정"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}
      <CompanyForm
        formValue={formValue}
        onChange={setFormValue}
        originFileName={originFileName}
        renderFooter={() => (
          <CreateFooter>
            {myInfo?.role === "ADMIN" ? (
              <Button onClick={() => setOpenCancelModal(true)}>취소</Button>
            ) : (
              <Link to={`/account/company`}>
                <Button>취소</Button>
              </Link>
            )}

            <Button appearance={"primary"} type="submit">
              저장
            </Button>
          </CreateFooter>
        )}
        mode={"EDIT"}
        onSubmit={handleSubmit}
        isCheckedDuplicated={isCheckedDuplicated}
        setIsCheckedDuplicated={setIsCheckedDuplicated}
        isDuplicated={isDuplicated}
        setIsDuplicated={setIsDuplicated}
      />
      <Modal
        size={"xs"}
        open={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        role="alertdialog"
      >
        <Modal.Header>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          회사 수정을 취소 하시겠습니까? 수정한 내용은 저장되지 않습니다.
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpenCancelModal(false)}
            style={{ marginRight: 5 }}
          >
            취소
          </Button>
          <Link to={`/account/company/view/${id}`}>
            <Button appearance="primary">확인</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditCompany;
