import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Form, InputCount, SelectPicker, Table, WarnTooltip } from "components";
import { CreateItemTitleText, PrimaryText } from "components/text";
import {
  Checkbox,
  CheckboxGroup,
  Divider,
  Radio,
  RadioGroup,
  Schema,
  Stack,
  Toggle,
} from "rsuite";
import {
  CreateItemContainer,
  CreateItemTitle,
  CreateItemValue,
  FlexDiv,
} from "components/layout";
import AdNetworkPriorityGroupControl, {
  AdNetworkPriorityGroupControlProps,
} from "./AdNetworkPriorityGroupControl";
import { ValueType } from "rsuite/Checkbox";
import { useLocation } from "react-router-dom";
import { CreatePlacementLocationState } from "./CreatePlacement";
import { DateCell } from "components/table/Cells";
import {
  MobileOSType,
  PlacementAdNetworkCreativeType,
  PlacementPriorityType,
  PlatformType,
  ScreenType,
  SortType,
} from "utils/types";
import {
  CompanyService,
  PlacementGroupService,
  PlacementService,
} from "../../../utils/api";
import { AuthContext } from "../../../utils/context/AuthContext";
import { underToUpper } from "../../../utils";

const PlacementForm = (props: PlacementFormProps) => {
  const location = useLocation();
  const { placementGroup } =
    (location.state as CreatePlacementLocationState) || {};
  const {
    onFormChange,
    formValue,
    editMode,
    adNetworkUnits,
    onSubmit,
    formRef,
  } = props;
  const [publisherName, setPublisherName] = useState("");
  const [formError, setFormError] = useState({});
  const [initPriorityGroup, setInitPriorityGroup] = useState(false);
  const { myInfo } = useContext(AuthContext);
  const [placementGroups, setPlacementGroups] = useState<
    { label: string; value: number }[]
  >([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [histories, setHistories] = useState<
    {
      id: number;
      account: {
        id: number;
        login_id: string;
        name: string;
      };
      created_at: string;
    }[]
  >([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  }>({
    sortColumn: "created_at",
    sortType: "desc",
  });

  const fetchPublisherName = useCallback(async () => {
    if (!props.publisher) return;
    const { data } = await new CompanyService().getDetail(props.publisher);
    setPublisherName(data.name);
  }, [props.publisher]);

  const fetchPlacementGroups = useCallback(async () => {
    if (!props.publisher) return;
    const { data } = await new PlacementGroupService().getIdNameMeta({
      filter: `publisher.id : ${props.publisher}`,
    });

    setPlacementGroups(
      data.map((d: { id: number; name: string }) => ({
        label: d.name,
        value: d.id,
      }))
    );
  }, [props.publisher]);

  const fetchHistories = useCallback(async () => {
    if (!formValue.id) return;
    const { data } = await new PlacementService().getHistory(formValue.id, {
      page,
      size: 20,
      sort: underToUpper(sort.sortColumn) + "," + sort.sortType,
    });

    setHistories(data.content);
    setTotal(data.total_elements);
  }, [formValue.id, page, sort.sortColumn, sort.sortType]);

  const model = useMemo(() => {
    return Schema.Model({
      placement_group:
        Schema.Types.NumberType().isRequired("게재위치 그룹을 선택하세요."),
      code: Schema.Types.StringType()
        .isRequired("게재위치 코드를 입력하세요")
        .rangeLength(3, 80, "게재위치 코드는 3~80자로 입력하세요.")
        .pattern(
          /^[A-Z0-9_]*$/,
          "영문 대문자, 숫자, 언더바(_) 사용 가능합니다."
        )
        // @ts-ignore
        .addRule((value) => {
          return editMode || checkExistCode(value);
        }, "동일한 코드를 사용할 수 없습니다."),
      name: Schema.Types.StringType()
        .isRequired("게재위치명을 입력하세요.")
        .rangeLength(2, 60, "게재위치명은 2~60자로 입력하세요.")
        .pattern(
          /^[ㄱ-ㅎ가-힣ㅏ-ㅣa-zA-Z0-9_\s]*$/,
          "영문(대소), 한글, 숫자, 언더바(_), 띄어쓰기 사용 가능합니다."
        ),
      width: Schema.Types.StringType().isRequired("사이즈를 입력하세요."),
      height: Schema.Types.StringType().isRequired("사이즈를 입력하세요."),
      priority_groups: Schema.Types.ArrayType().of(
        Schema.Types.ObjectType().shape({
          ad_network_unit_ids: Schema.Types.ArrayType()
            .addRule((value) => {
              return formValue.ad_network.length === 0 || value.length > 0;
            }, "네트워크 유닛을 1개 이상 선택하세요.")
            .addRule((value) => {
              return (
                formValue.ad_network.length === 0 ||
                !adNetworkUnits
                  .filter((u) => u.enabled === false)
                  .find((u) => value.includes(u.id))
              );
            }, "비활성된 네트워크가 포함되어 있습니다."),
          options: Schema.Types.ArrayType().of(
            Schema.Types.ObjectType().shape({
              priority: Schema.Types.StringType().addRule(
                (value, data, fieldName) => {
                  const index: string | number =
                    parseInt(fieldName?.[1]?.replace(/[[\]]/g, "") || "") || 0;
                  return (
                    formValue.ad_network.length === 0 ||
                    formValue.priority_groups[index].priority_type ===
                      "RATIO" ||
                    formValue.priority_groups[index].priority_type === "ECPM" ||
                    formValue.priority_groups[index].priority_type ===
                      "REVENUE" ||
                    !formValue.priority_groups[
                      index
                    ].ad_network_unit_ids.includes(data.ad_network_unit_id) ||
                    (value !== "" && value !== null)
                  );
                },
                "우선순위를 선택하세요.",
                true
              ),
              ratio: Schema.Types.StringType()
                .addRule(
                  (value, data, fieldName) => {
                    const index: string | number =
                      parseInt(fieldName?.[1]?.replace(/[[\]]/g, "") || "") ||
                      0;
                    return (
                      formValue.ad_network.length === 0 ||
                      formValue.priority_groups[index].priority_type !==
                        "RATIO" ||
                      !formValue.priority_groups[
                        index
                      ].ad_network_unit_ids.includes(data.ad_network_unit_id) ||
                      (value !== "" && value !== null)
                    );
                  },
                  "요청 비중을 입력하세요.",
                  true
                )
                .addRule((value, data, fieldName) => {
                  const index: string | number =
                    parseInt(fieldName?.[1]?.replace(/[[\]]/g, "") || "") || 0;
                  return (
                    formValue.priority_groups[index].options.reduce(
                      (acc, op) => acc + parseInt(op.ratio || "0"),
                      0
                    ) <= 100
                  );
                }, "요청 비중의 합은 100을 초과할 수 없습니다."),
            })
          ),
        })
      ),
    });
  }, [formValue, adNetworkUnits, editMode]);

  const handleFormChange = useCallback(
    async (formValue: PlacementFormProps["formValue"]) => {
      const originFormValue = props.formValue;
      const regexpForSize = /^[\d|\s]*$/;
      if (
        !regexpForSize.test(formValue.width) ||
        !regexpForSize.test(formValue.height)
      )
        return;

      let tempFormValue = {
        ...formValue,
      };
      if (formValue.screen === "PC" || formValue.screen === "IPTV")
        tempFormValue = { ...tempFormValue, mobile_os: null };
      if (formValue.screen === "MOBILE" && formValue.mobile_os === null)
        tempFormValue = { ...tempFormValue, mobile_os: "IOS" };
      if (formValue.screen === "IPTV")
        tempFormValue = { ...tempFormValue, platform: "APP" };
      if (
        originFormValue.ad_network.length === 0 &&
        formValue.ad_network.length > 0
      ) {
        // 네트워크 미제공->제공 변경 시 선택값 초기화
        tempFormValue = {
          ...tempFormValue,
          priority_groups: originFormValue.priority_groups.map(() => ({
            creative_type: "ALL",
            priority_type: "RATIO",
            ad_network_unit_ids: [],
            options: [],
          })),
        };
      }

      await onFormChange(tempFormValue);
      formRef?.current?.checkForField("priority_groups");
    },
    [formRef, onFormChange, props.formValue]
  );

  const handleClickPriorityGroupInit = useCallback(() => {
    setInitPriorityGroup(true);
  }, []);

  useEffect(() => {
    fetchPublisherName();
  }, [fetchPublisherName]);

  useEffect(() => {
    fetchPlacementGroups();
  }, [fetchPlacementGroups]);

  useEffect(() => {
    fetchHistories();
  }, [fetchHistories]);

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  useEffect(() => {
    if (!editMode) {
      // 생성 시 옵션 선택 값에 따라 네트워크 유닛 바뀜, 초기화
      handleClickPriorityGroupInit();
    }
  }, [adNetworkUnits, editMode, handleClickPriorityGroupInit]);

  return (
    <>
      <Form
        layout="horizontal"
        model={model}
        formValue={formValue}
        onChange={handleFormChange}
        onCheck={setFormError}
        ref={formRef}
        onSubmit={onSubmit}
      >
        {editMode && (
          <Form.Group controlId="enabled">
            <Form.ControlLabel
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <CreateItemTitleText>상태</CreateItemTitleText>
            </Form.ControlLabel>
            <CreateItemValue>
              <Stack spacing={10}>
                <span
                  style={{
                    width: 40,
                    display: "inline-block",
                    color: formValue.enabled
                      ? "var(--rs-text-active)"
                      : undefined,
                    float: "left",
                  }}
                >
                  {formValue.enabled ? "활성" : "비활성"}
                </span>
                <Form.Control
                  name="enabled"
                  accepter={Toggle}
                  checked={formValue.enabled}
                />
              </Stack>
            </CreateItemValue>
          </Form.Group>
        )}

        {myInfo?.role === "MASTER" && (
          <CreateItemContainer style={{ padding: 0, marginBottom: 24 }}>
            <CreateItemTitle style={{ marginBottom: 4 }}>
              <CreateItemTitleText>매체사</CreateItemTitleText>
            </CreateItemTitle>
            <CreateItemValue>{publisherName}</CreateItemValue>
          </CreateItemContainer>
        )}

        <Form.Group controlId={"placement_group"}>
          <Form.ControlLabel>
            <CreateItemTitleText required>게재위치 그룹</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="placement_group"
            accepter={SelectPicker}
            data={placementGroups}
            placeholder={"게재위치 그룹 선택"}
            style={{ width: 300 }}
            searchable={false}
            readOnly={placementGroup !== undefined}
            plaintext={editMode}
          />
        </Form.Group>
        <Form.Group controlId="code">
          <Form.ControlLabel>
            <CreateItemTitleText required>게재위치 코드</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="code"
            accepter={InputCount}
            maxLength={80}
            style={{ width: 500, display: "inline-block" }}
            checkAsync
            plaintext={editMode}
          />
          {!editMode && (
            <Form.HelpText>
              영문 대문자, 숫자, 언더바(_) 사용 가능. 입력 후 변경 불가
            </Form.HelpText>
          )}
        </Form.Group>
        <Form.Group controlId="name">
          <Form.ControlLabel>
            <CreateItemTitleText required>게재위치명</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="name"
            accepter={InputCount}
            maxLength={60}
            style={{ width: 500, display: "inline-block" }}
          />
          <Form.HelpText>
            영문(대소), 한글, 숫자, 언더바(_), 띄어쓰기 사용 가능
          </Form.HelpText>
        </Form.Group>
        <Divider />
        <Form.Group controlId="screen">
          <Form.ControlLabel>
            <CreateItemTitleText required>스크린</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="screen"
            accepter={RadioGroup}
            inline
            plaintext={editMode}
          >
            <Radio value={"PC"}>PC</Radio>
            <Radio value={"MOBILE"}>Mobile</Radio>
            <Radio value={"IPTV"}>IPTV</Radio>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="platform">
          <Form.ControlLabel>
            <CreateItemTitleText required>플랫폼</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="platform"
            accepter={RadioGroup}
            inline
            plaintext={editMode}
          >
            <Radio value={"WEB"} disabled={formValue.screen === "IPTV"}>
              Web
            </Radio>
            <Radio value={"APP"}>Application</Radio>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="mobile_os">
          <Form.ControlLabel>
            <CreateItemTitleText required>모바일OS</CreateItemTitleText>
          </Form.ControlLabel>
          {!editMode || formValue.screen === "MOBILE" ? (
            <Form.Control
              name="mobile_os"
              accepter={RadioGroup}
              inline
              disabled={formValue.screen !== "MOBILE"}
              plaintext={editMode}
            >
              <Radio value={"IOS"}>iOS</Radio>
              <Radio value={"ANDROID"}>Android</Radio>
            </Form.Control>
          ) : (
            "-"
          )}
        </Form.Group>
        <Form.Group controlId="size">
          <Form.ControlLabel>
            <CreateItemTitleText required>사이즈</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="width"
            accepter={InputCount}
            maxLength={4}
            plaintext={editMode}
          />
          <span style={{ margin: "0 8px" }}>x</span>
          <Form.Control
            name="height"
            accepter={InputCount}
            maxLength={4}
            plaintext={editMode}
          />
          {!editMode && <Form.HelpText>숫자입력</Form.HelpText>}{" "}
        </Form.Group>
        <Divider />
        <Form.Group controlId="ad_network">
          <Form.ControlLabel>
            <CreateItemTitleText>네트워크 광고</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="ad_network"
            accepter={CheckboxGroup}
            inline
            style={{ display: "flex", alignItems: "center" }}
          >
            <Checkbox value={"true"}>제공</Checkbox>
            {editMode &&
              formValue.ad_network.length > 0 &&
              formValue.priority_groups[0]?.ad_network_unit_ids.filter(
                (uid) =>
                  adNetworkUnits.find((u) => u.id === uid)?.enabled === true
              ).length === 0 && (
                <WarnTooltip inner={"활성화된 네트워크가 없습니다."} />
              )}
          </Form.Control>
        </Form.Group>
        {formValue.ad_network.length > 0 && (
          <>
            <Divider />
            <FlexDiv style={{ alignItems: "center" }}>
              <CreateItemTitle>
                <CreateItemTitleText required>세부 설정</CreateItemTitleText>
              </CreateItemTitle>
              <CreateItemValue>
                <FlexDiv
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <CreateItemTitleText>Mediation 설정</CreateItemTitleText>
                  <PrimaryText
                    style={{ marginRight: 12, cursor: "pointer" }}
                    onClick={handleClickPriorityGroupInit}
                  >
                    초기화
                  </PrimaryText>
                </FlexDiv>
                <Divider />
                <Form.Control
                  name="priority_groups"
                  accepter={AdNetworkPriorityGroupControl}
                  adNetworkUnits={props.adNetworkUnits}
                  formError={formError}
                  needToInit={initPriorityGroup}
                  setNeedToInit={setInitPriorityGroup}
                />
              </CreateItemValue>
            </FlexDiv>
          </>
        )}
        {editMode && (
          <>
            <Divider />
            <CreateItemContainer style={{ padding: 0 }}>
              <CreateItemTitle>
                <CreateItemTitleText>히스토리</CreateItemTitleText>
              </CreateItemTitle>
              <CreateItemValue>
                <Table
                  data={histories}
                  columns={historyColumns}
                  pagination={total > 20}
                  displayLength={20}
                  paginationLayout={["pager"]}
                  activePage={page}
                  onChangePage={setPage}
                  total={total}
                  sortColumn={sort.sortColumn}
                  sortType={sort.sortType}
                  onSortColumn={handleSortChange}
                  height={280}
                  autoHeight={histories.length < 5}
                />
              </CreateItemValue>
            </CreateItemContainer>
          </>
        )}
        <Divider />
        {props.renderFooter()}
      </Form>
    </>
  );
};

export default PlacementForm;

export type PlacementFormProps = {
  formValue: {
    id?: number;
    enabled?: boolean;
    placement_group: number | null;
    code: string;
    name: string;
    screen: ScreenType;
    platform: PlatformType;
    mobile_os: MobileOSType | null;
    width: string;
    height: string;
    ad_network: ValueType[];
    priority_groups: PriorityGroup[];
  };
  onFormChange: (formValue: PlacementFormProps["formValue"]) => void;
  renderFooter: () => ReactElement;
  publisher: number | string;
  adNetworkUnits: AdNetworkPriorityGroupControlProps["adNetworkUnits"];
  editMode?: boolean;
  onSubmit?: (validate: boolean) => Promise<void>;
  formRef: React.RefObject<HTMLFormElement>;
};

export type PriorityGroup = {
  id?: number;
  priority_type: PlacementPriorityType;
  creative_type: PlacementAdNetworkCreativeType;
  ad_network_unit_ids: number[];
  options: {
    id?: number;
    ad_network_unit_id: number;
    priority: string | null;
    limit_impression: boolean;
    impressions: string;
    ratio: string;
  }[];
};

const historyColumns = [
  {
    title: "업데이트일",
    key: "created_at",
    sortable: true,
    flexGrow: 1,
    Cell: DateCell,
  },
  {
    title: "사용자",
    key: "account.name",
    flexGrow: 1,
  },
];

const checkExistCode = async (value: string) => {
  const { data } = await new PlacementService().existCode({
    code: value,
  });

  return !data.result;
};
