import {
  ComparatorsTypes,
  OperatorTypes,
  ValueType,
} from "../types/dynamicFilterTypes";

export const getComparatorsString = (
  comparator: ComparatorsTypes,
  field: string,
  value?: ValueType[] | ValueType
) => {
  const isUndefined = (value: ValueType[] | ValueType | undefined) => {
    return value === undefined;
  };
  const isAllString = (value: ValueType[]) => {
    return value.every((v) => typeof v === "string");
  };
  const isString = (value: ValueType[] | ValueType) => {
    return typeof value === "string";
  };
  const isArray = (value: ValueType[] | ValueType | undefined) => {
    return Array.isArray(value);
  };
  let result = "";
  if (isUndefined(value)) {
    // * field: status, comparator: is null, value: undefined
    // status is null
    result = `${field} ${comparator}`;
  } else if (!isArray(value)) {
    if (isString(value!)) {
      // * field: status, comparator: ~, value: admin
      // status ~ 'admin'
      result = `${field} ${comparator} '${(value as string).replace(
        /\\/g,
        "\\\\"
      )}'`;
    } else {
      // value can be number|boolean
      // * field: status, comparator: ~, value: 1 / boolean
      // status ~ 1
      result = `${field} ${comparator} ${value}`;
    }
  } else if (isArray(value) && comparator === "in") {
    if (isAllString(value as ValueType[])) {
      // * field: status, comparator: in, value: ['initialized', 'active']
      // status in ('initialized', 'active')
      result = `${field} ${comparator} ('${(value as []).join("','")}')`;
    } else {
      // value can be (number|boolean)[]
      // * field: status, comparator: in, value: [1,2]
      // status in (1,2)
      result = `${field} ${comparator} (${(value as []).join(",")})`;
    }
  }
  return result;
};
export const getOperatorsString = (
  operator: OperatorTypes,
  expressions: string[] | string
) => {
  const isArray = (value: ValueType[] | ValueType | undefined) => {
    return Array.isArray(value);
  };
  let result = "";
  if (operator === "not") {
    // * operator: not, expressions: "adName ~ 'member'"
    // not (adName  ~ 'member')
    result = `${operator} (${expressions})`;
  } else if (isArray(expressions)) {
    // * operator: and, expressions: ['admin','member']
    // admin and member
    result = (expressions as []).join(` ${operator} `);
  }
  return result;
};
