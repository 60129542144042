import Axios from "axios";
import {
  AdNetworkDownloadParams,
  AdNetworkParams,
  AdNetworkUnitDownloadParams,
  ErrorParams,
  ListParams,
  OperationParams,
  SettlementDownloadParams,
} from "./params";
import queryString from "query-string";
import { emptyStringToNull } from "./index";
import {
  AccountRoleType,
  AdNetworkCodeType,
  AdNetworkType,
  AdReviewStatusType,
  BidType,
  CompanyType,
  CreativeType,
  CurrencyType,
  OriginPlatformType,
  PlacementPriorityType,
  PlatformType,
  SalesType,
  SkipOptionType,
} from "./types";
import { ValueType } from "rsuite/Checkbox";

const API_URL: string =
  process.env.REACT_APP_HOST_ENV === "prod"
    ? "https://api.ssp.uplus.co.kr"
    : process.env.REACT_APP_HOST_ENV === "dev" ||
      process.env.REACT_APP_HOST_ENV === "local"
    ? "https://api-dev.ssp.uplus.co.kr"
    : "https://api-pg.ssp.uplus.co.kr";

const config = {
  baseURL: API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

Axios.defaults.withCredentials = true;
Axios.defaults.xsrfHeaderName = "X-CSRFToken";
Axios.defaults.xsrfCookieName = "csrftoken";

const axios = Axios.create(config);

class AuthService {
  getMe = async () => {
    return await axios.get(`api/v1/auth/me`);
  };
  login = async (params: { login_id: string; password: string }) => {
    return await axios.post("/api/v1/auth/login", {
      ...params,
    });
  };
  logout = async () => {
    return await axios.post("/api/v1/auth/logout");
  };
}

class CompanyService {
  get = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/companies?${query}`);
  };

  getDetail = async (id: number | string) => {
    return await axios.get(`/api/v1/companies/${id}`);
  };

  getIdNameMeta = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return await axios.get(`/api/v1/companies/name?${query}`);
  };

  create = async (body: {
    type: "PUBLISHER";
    name: string;
    business_registration_number: string;
    address?: string;
    business_type?: string;
    business_item?: string;
    site_address?: string;
    settlement_manager_name?: string;
    settlement_manager_email?: string;
    settlement_manager_phone?: string;
    memo?: string;
  }) => {
    return await axios.post(`/api/v1/companies`, emptyStringToNull(body));
  };

  edit = async (
    id: number,
    body: {
      id: number;
      type: CompanyType;
      enabled: boolean;
      name: string;
      business_registration_number: string;
      address?: string;
      business_type?: string;
      business_item?: string;
      site_address?: string;
      settlement_manager_name?: string;
      settlement_manager_email?: string;
      settlement_manager_phone?: string;
      memo?: string;
    }
  ) => {
    return await axios.put(`/api/v1/companies/${id}`, emptyStringToNull(body));
  };

  uploadRegistrationCertificate = async (id: number, body: FormData) =>
    await axios.put(`/api/v1/companies/${id}/file/certificate`, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

  downloadRegistrationCertificate = async (id: number) =>
    await axios.get(`/api/v1/companies/${id}/file/certificate/download`, {
      responseType: "blob",
    });

  existRegistrationNumber = async (number: string) => {
    return await axios.get(
      `/api/v1/companies/business-registration-number/${number}/exists`
    );
  };
}

class AccountService {
  get = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/accounts?${query}`);
  };

  getDetail = async (id: number | string) => {
    return await axios.get(`/api/v1/accounts/${id}`);
  };

  create = async (body: {
    login_id: string;
    password: string;
    name: string;
    email: string;
    phone: string;
    role: AccountRoleType;
    placement_group_ids?: number[];
    company_id?: number;
    company_type: CompanyType;
  }) => {
    return await axios.post(`/api/v1/accounts`, emptyStringToNull(body));
  };

  edit = async (
    id: number,
    body: {
      id: number;
      enabled: boolean;
      password?: string;
      login_id: string;
      name?: string;
      email: string;
      phone: string;
      role: AccountRoleType;
      placement_group_ids?: number[];
      company_id?: number;
      company_type: CompanyType;
    }
  ) => {
    return await axios.put(`/api/v1/accounts/${id}`, emptyStringToNull(body));
  };

  existLoginId = async (loginId: string) => {
    return await axios.get(`/api/v1/accounts/${loginId}/exists`);
  };

  delete = async (ids: ValueType[]) => {
    const query = queryString.stringify(
      { ids },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );

    return await axios.delete(`/api/v1/accounts?${query}`);
  };

  editEnabled = async (body: {
    company_type: CompanyType;
    enabled: boolean;
    ids: ValueType[];
  }) => {
    return await axios.patch(
      `/api/v1/accounts/enabled`,
      emptyStringToNull(body)
    );
  };

  getIdNameMeta = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return await axios.get(`/api/v1/accounts/name?${query}`);
  };

  editDefaultReviewPlacementGroupId = async (placementGroupId: number) => {
    return await axios.patch(
      `/api/v1/accounts/default-review-placement-group-id/${placementGroupId}`
    );
  };
  editDefaultAdNetworkId = async (adNetworkId: number) => {
    return await axios.patch(
      `/api/v1/accounts/default-ad-network-id/${adNetworkId}`
    );
  };
}

class PlacementGroupService {
  get = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/placement-groups?${query}`);
  };

  getDetail = async (id: number | string) => {
    return await axios.get(`/api/v1/placement-groups/${id}`);
  };

  getIdNameMeta = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return await axios.get(`/api/v1/placement-groups/name?${query}`);
  };

  create = async (body: {
    publisher_id: number;
    name: string;
    representative_address: string;
    pc_address?: string;
    mobile_address?: string;
    android_address?: string;
    ios_address?: string;
    account_ids?: number[];
    memo?: string;
  }) => {
    return await axios.post(
      `/api/v1/placement-groups`,
      emptyStringToNull(body)
    );
  };

  deleteGroupList = async (ids: ValueType[]) => {
    const query = queryString.stringify(
      { ids },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );

    return await axios.delete(`/api/v1/placement-groups?${query}`);
  };

  existGroupName = async (params: {
    "publisher-id": number;
    name: string;
    "placement-group-id"?: number;
  }) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/placement-groups/name/exists?${query}`);
  };

  edit = async (
    id: number | string,
    body: {
      id: number;
      publisher_id: number;
      name: string;
      representative_address: string;
      pc_address?: string;
      mobile_address?: string;
      android_address?: string;
      ios_address?: string;
      account_ids?: number[];
      memo?: string;
    }
  ) => {
    return await axios.put(
      `/api/v1/placement-groups/${id}`,
      emptyStringToNull(body)
    );
  };
}

class PlacementService {
  get = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/placements?${query}`);
  };

  getDetail = async (id: number | string) => {
    return await axios.get(`/api/v1/placements/${id}`);
  };

  editEnabled = async (body: { enabled: boolean; ids: ValueType[] }) => {
    return await axios.patch(
      `/api/v1/placements/enabled`,
      emptyStringToNull(body)
    );
  };

  deleteList = async (ids: ValueType[]) => {
    const query = queryString.stringify(
      { ids },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );

    return await axios.delete(`/api/v1/placements?${query}`);
  };

  existCode = async (params: { code: string }) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/placements/code/exists?${query}`);
  };

  create = async (body: {
    publisher_id: number;
    placement_group_id: number;
    code: string;
    name: string;
    platform: OriginPlatformType;
    application: PlatformType;
    width: string;
    height: string;
    is_using_ad_network: boolean;
    ad_network_priority_groups?: {
      priority_type: PlacementPriorityType;
      ad_network_priorities: {
        priority?: number;
        impressions?: number;
        limit_impression?: boolean;
        ad_network_unit_id: number;
        ratio?: number;
      }[];
    }[];
  }) => {
    return await axios.post(`/api/v1/placements`, emptyStringToNull(body));
  };

  edit = async (
    id: number | string,
    body: {
      code: string;
      name: string;
      platform: OriginPlatformType;
      application: PlatformType;
      width: string;
      height: string;
      is_using_ad_network: boolean;
      enabled: boolean;
      ad_network_priority_groups?: {
        id?: number;
        priority_type: PlacementPriorityType;
        ad_network_priorities: {
          id?: number;
          priority?: number;
          impressions?: number;
          limit_impression?: boolean;
          ad_network_unit_id: number;
          ratio?: number;
        }[];
      }[];
    }
  ) => {
    return await axios.put(`/api/v1/placements/${id}`, emptyStringToNull(body));
  };

  getHistory = async (id: number | string, params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/placements/${id}/histories?${query}`);
  };

  download = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return await axios.get(`/api/v1/placements/download?${query}`, {
      responseType: "blob",
    });
  };
}

class AdNetworkUnitService {
  get = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/ad-network-units?${query}`);
  };

  getDetail = async (id: number | string) => {
    return await axios.get(`/api/v1/ad-network-units/${id}`);
  };

  getIdNameMeta = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return await axios.get(`/api/v1/ad-network-units/name?${query}`);
  };

  deleteList = async (ids: ValueType[]) => {
    const query = queryString.stringify(
      { ids },
      {
        skipEmptyString: true,
        skipNull: true,
      }
    );

    return await axios.delete(`/api/v1/ad-network-units?${query}`);
  };

  create = async (body: {
    publisher_id: number;
    ad_network_id: number;
    name: string;
    platform: OriginPlatformType;
    application: PlatformType;
    width: number;
    height: number;
    type: CreativeType;
    skip_option: SkipOptionType;
    spec: {
      pub_key?: string;
      media_id?: string;
      section_id?: string;
      app_id?: string;
      unit_id?: string;
      mc?: string;
      site_id?: string;
      ad_id?: string;
      store_url?: string;
      bundle?: string;
      mcode?: string;
      pkgname?: string;
      media_key?: string;
      adunit_id?: string;
      pack_name?: string;
      tag?: string;
      bid_floor?: string;
      app_name?: string;
      ifa_type?: string;
    };
  }) => {
    return await axios.post(
      `/api/v1/ad-network-units`,
      emptyStringToNull(body)
    );
  };

  editName = async (id: string | number, name: string) => {
    return axios.patch(`/api/v1/ad-network-units/${id}/name`, { name });
  };

  existName = async (params: {
    name: string;
    "ad-network-id": number;
    "ad-network-unit-id"?: number;
  }) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/ad-network-units/name/exists?${query}`);
  };

  download = async (params: AdNetworkUnitDownloadParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return await axios.get(`/api/v1/ad-network-units/download?${query}`, {
      responseType: "blob",
    });
  };
}

class AdNetworkService {
  get = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/ad-networks?${query}`);
  };

  getCodes = async () => {
    return await axios.get(`/api/v1/ad-networks/codes`);
  };

  getNameCode = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/ad-networks/name-code?${query}`);
  };

  editEnabled = async (body: { enabled: boolean; ids: ValueType[] }) => {
    return await axios.patch(
      `/api/v1/ad-networks/enabled`,
      emptyStringToNull(body)
    );
  };

  create = async (body: {
    publisher_id: number;
    code: AdNetworkCodeType;
    type: AdNetworkType;
    placement_group_ids?: number[];
    memo?: string;
    contract_info?: {
      name?: string;
      bid_type?: BidType;
      sales_type?: SalesType;
      currency?: CurrencyType;
      price?: number;
    }[];
  }) => {
    return await axios.post(`/api/v1/ad-networks`, emptyStringToNull(body));
  };

  getDetail = async (id: number | string) => {
    return await axios.get(`/api/v1/ad-networks/${id}`);
  };

  edit = async (
    id: number | string,
    body: {
      id: number | string;
      enabled: boolean;
      code: AdNetworkCodeType;
      type: AdNetworkType;
      placement_group_ids?: number[];
      memo?: string;
      contract_info?: {
        name?: string;
        bid_type?: BidType;
        sales_type?: SalesType;
        currency?: CurrencyType;
        price?: number;
      }[];
    }
  ) => {
    return await axios.put(
      `/api/v1/ad-networks/${id}`,
      emptyStringToNull(body)
    );
  };
}

class ReportService {
  getOperation = async (params: OperationParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/reports/operation?${query}`);
  };

  downloadOperation = async (params: OperationParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return await axios.get(`/api/v1/reports/operation/download?${query}`, {
      responseType: "blob",
    });
  };

  getAdNetwork = async (params: AdNetworkParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/reports/ad-network?${query}`);
  };

  downloadAdNetwork = async (params: AdNetworkDownloadParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return await axios.get(`/api/v1/reports/ad-network/download?${query}`, {
      responseType: "blob",
    });
  };

  getError = async (params: ErrorParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/reports/error?${query}`);
  };

  downloadError = async (params: ErrorParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return await axios.get(`/api/v1/reports/error/download?${query}`, {
      responseType: "blob",
    });
  };
}

class ReviewService {
  get = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/review-items?${query}`);
  };

  editStatus = async (id: number, body: { status: AdReviewStatusType }) => {
    return await axios.patch(
      `/api/v1/review-items/${id}/status`,
      emptyStringToNull(body)
    );
  };
}

class SettlementService {
  get = async (params: ListParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });

    return await axios.get(`/api/v1/settlements?${query}`);
  };

  download = async (params: SettlementDownloadParams) => {
    const query = queryString.stringify(params, {
      skipEmptyString: true,
      skipNull: true,
    });
    return await axios.get(`/api/v1/settlements/download?${query}`, {
      responseType: "blob",
    });
  };
}

export {
  AuthService,
  CompanyService,
  AccountService,
  PlacementGroupService,
  PlacementService,
  AdNetworkUnitService,
  AdNetworkService,
  ReportService,
  ReviewService,
  SettlementService,
};
