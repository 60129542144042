import styled from "styled-components";
import moment from "moment";

const fontSize = {
  contents: "12px",
  headlineL: "18px",
  headlineM: "14px",
};

const fontWeight = {
  contents: "normal",
  headline: "bold",
};

type CreateItemTitleSpanProps = {
  required?: boolean;
};
const CreateItemTitleText = styled.span<CreateItemTitleSpanProps>`
  font-size: ${fontSize.headlineM};
  font-weight: ${fontWeight.headline};
  position: relative;

  ${(props: any) =>
    props.required
      ? `&:before {
    position: absolute;
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 5px;
    background-color: var(--rs-text-error);
    right: -5px;
    bottom: 12px;
  }`
      : ""};
`;

const InfoMessage = styled.span`
  color: var(--rs-text-secondary);
`;

const PrimaryText = styled.span`
  color: var(--rs-text-active);
`;

const ErrorMessage = styled.span`
  color: var(--rs-form-errormessage-text);
  font-size: 12px;
`;

const LinkText = styled.span`
  color: var(--rs-text-link);
  cursor: pointer;
`;

const formatDate = (date?: string | Date) =>
  date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "-";

export {
  CreateItemTitleText,
  InfoMessage,
  PrimaryText,
  ErrorMessage,
  LinkText,
  formatDate,
};
