import React from "react";
import { SelectPicker as RsuiteSelectPicker, SelectPickerProps } from "rsuite";
import styled from "styled-components";

const StyledSelectPicker: typeof RsuiteSelectPicker = styled(
  RsuiteSelectPicker
)`
  &.error.rs-picker-default .rs-picker-toggle {
    border-color: var(--rs-form-errormessage-text);
  }

  &.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  &.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: var(--rs-text-heading);
  }
`;

const SelectPicker = (props: SelectPickerProps<any>) => {
  return <StyledSelectPicker searchable={false} {...props} />;
};

export default SelectPicker;
