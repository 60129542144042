import styled from "styled-components";
import { Form as RsuiteForm } from "rsuite";

export const Form = styled(RsuiteForm)`
  padding: 25px 30px;

  .rs-form-group {
    display: flex;
    align-items: center;
  }

  &.rs-form-horizontal .rs-form-group .rs-form-control-label {
    width: 150px;
    text-align: left;
  }

  &.rs-form-horizontal
    .rs-form-group
    .rs-form-control-wrapper
    + .rs-form-help-text:not(.rs-form-help-text-tooltip) {
    display: inline-block;
    margin-left: 10px;
  }

  .rs-form-help-text {
    font-size: 12px;
  }
`;
