import React, { useCallback, useContext, useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { PageHeader } from "components/layout";
import { SelectPicker, Tab, Tabs } from "../../../components";
import AdNetworkTable from "./AdNetworkTable";
import AdNetworkUnitTable from "./AdNetworkUnitTable";
import { AuthContext } from "../../../utils/context/AuthContext";
import { CompanyService } from "../../../utils/api";
import { PublisherState } from "../../../utils/types";

const AdNetworks = () => {
  const query: {
    "selected-tab"?: string;
  } = queryString.parse(useLocation().search);
  const activeKeyFromQuery = query["selected-tab"];
  const [activeKey, setActiveKey] = useState(
    activeKeyFromQuery || "ad-network"
  );
  const state = useLocation().state || {};
  const { publisher: publisherByLocation } = state as PublisherState;
  const [publishers, setPublishers] = useState<{ label: string; value: any }[]>(
    []
  );
  const { myInfo } = useContext(AuthContext);
  const [publisher, setPublisher] = useState<any>(
    myInfo?.company_type === "PUBLISHER"
      ? myInfo?.company_id
      : publisherByLocation || null
  );
  const navigate = useNavigate();

  const fetchPublishers = useCallback(async () => {
    const { data } = await new CompanyService().getIdNameMeta({
      filter: "type : 'PUBLISHER'",
    });

    setPublishers(
      data.map((d: { id: number; name: string }) => ({
        label: d.name,
        value: d.id,
      }))
    );
  }, []);

  useEffect(() => {
    fetchPublishers();
  }, [fetchPublishers]);

  useEffect(() => {
    if (myInfo?.company_type === "PUBLISHER") {
      setPublisher(myInfo?.company_id);
    }
  }, [myInfo]);
  useEffect(() => {
    if (activeKeyFromQuery) setActiveKey(activeKeyFromQuery);
  }, [activeKeyFromQuery]);

  useEffect(() => {
    navigate(`/inventory/ad-networks?selected-tab=${activeKey}`);
  }, [activeKey, navigate]);

  return (
    <>
      <PageHeader title={"네트워크"}>
        {myInfo?.role === "MASTER" && (
          <SelectPicker
            placeholder={"매체사 선택"}
            data={publishers}
            style={{ width: 200, marginLeft: 10 }}
            value={publisher}
            onChange={setPublisher}
          />
        )}
      </PageHeader>
      <Tabs
        activeKey={activeKey}
        onSelect={setActiveKey}
        style={{ marginTop: 5 }}
      >
        <Tab eventKey={"ad-network"}>네트워크</Tab>
        <Tab eventKey={"ad-network-unit"}>네트워크 유닛</Tab>
      </Tabs>
      {activeKey === "ad-network" && <AdNetworkTable publisher={publisher} />}
      {activeKey === "ad-network-unit" && (
        <AdNetworkUnitTable publisher={publisher} />
      )}
    </>
  );
};

export default AdNetworks;
