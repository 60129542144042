import React, { useCallback, useContext, useEffect, useState } from "react";
import { CreateFooter, PageHeader } from "../../../components/layout";
import UserForm, { UserFormProps } from "./UserForm";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { CompanyType } from "../../../utils/types";
import { Button, Loader } from "rsuite";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { AccountService } from "../../../utils/api";
import { AuthContext } from "../../../utils/context/AuthContext";

const CreateUser = () => {
  const { myInfo } = useContext(AuthContext);
  const location = useLocation();
  const { companyType } = (location.state as LocationState) || {};
  const [formValue, setFormValue] = useState<UserFormProps["formValue"]>({
    ...initFormValue,
    company_id: myInfo?.role === "ADMIN" ? myInfo?.company_id || null : null,
  } as UserFormProps["formValue"]);
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (companyType === "PUBLISHER")
      setFormValue((prev) => (prev ? { ...prev, role: "ADMIN" } : undefined));
  }, [companyType]);

  const handleSubmit = useCallback(
    async (validate: boolean) => {
      if (!validate) return;

      try {
        setLoading(true);
        if (!formValue) return;
        const {
          login_id,
          password,
          name,
          email,
          phone,
          role,
          placement_group_ids,
          company_id,
        } = formValue;

        await new AccountService().create({
          login_id,
          password,
          name,
          email: email === "@" ? "" : email,
          phone: phone === "--" ? "" : phone,
          role,
          placement_group_ids,
          company_id: company_id || undefined,
          company_type: companyType,
        });

        navigate(`/account/user?selected-tab=${companyType}`);
      } catch (e) {
        setErrorModalOpen(true);
      } finally {
        setLoading(false);
      }
    },
    [companyType, formValue, navigate, setErrorModalOpen]
  );

  if (!companyType) return <Navigate to="/account/user" replace />;
  return (
    <>
      <PageHeader title={"사용자 생성"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}
      <UserForm
        companyType={companyType}
        formValue={formValue}
        onChange={setFormValue}
        renderFooter={() => (
          <CreateFooter>
            <Link to={`/account/user?selected-tab=${companyType}`}>
              <Button>취소</Button>
            </Link>
            <Button appearance={"primary"} type="submit">
              생성
            </Button>
          </CreateFooter>
        )}
        mode={"CREATE"}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default CreateUser;

type LocationState = {
  companyType: CompanyType;
};

export const initFormValue: UserFormProps["formValue"] = {
  company_id: null,
  login_id: "",
  password: "",
  password_check: "",
  name: "",
  email: "@",
  phone: "--",
  role: "MASTER",
  placement_group_ids: [],
};
