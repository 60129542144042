import React, { useCallback, useContext, useState } from "react";
import { CreateFooter, PageHeader } from "../../../components/layout";
import AdNetworkForm, { AdNetworkFormProps } from "./AdNetworkForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Loader } from "rsuite";
import { AdNetworkService } from "../../../utils/api";
import { AdNetworkCodeType, PublisherState } from "../../../utils/types";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";

export const initFormValue: AdNetworkFormProps["formValue"] = {
  code: null,
  type: "API",
  placement_group_ids: [],
  contract_info: [
    {
      name: "",
      bid_type: "CPM",
      sales_type: "FIXED",
      currency: "KRW",
      price: "",
    },
  ],
  memo: "",
};

const CreateAdNetwork = () => {
  const location = useLocation();
  const { publisher } = location.state as PublisherState;
  const [formValue, setFormValue] =
    useState<AdNetworkFormProps["formValue"]>(initFormValue);
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (validate: boolean) => {
      if (!validate) return;

      try {
        setLoading(true);
        if (!formValue || !publisher) return;
        const { code, type, placement_group_ids, memo, contract_info } =
          formValue;

        await new AdNetworkService().create({
          publisher_id: publisher as number,
          code: code as AdNetworkCodeType,
          type,
          placement_group_ids,
          memo,
          contract_info: contract_info.map((ci) => ({
            ...ci,
            price: parseFloat(ci.price),
          })),
        });

        navigate(`/inventory/ad-networks?selected-tab=ad-network`, {
          state: { publisher },
        });
      } catch (e) {
        setErrorModalOpen(true);
      } finally {
        setLoading(false);
      }
    },
    [formValue, navigate, publisher, setErrorModalOpen]
  );

  return (
    <>
      <PageHeader title={"네트워크 등록"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}

      <AdNetworkForm
        formValue={formValue}
        onChange={setFormValue}
        publisher={publisher}
        onSubmit={handleSubmit}
        renderFooter={() => (
          <CreateFooter>
            <Link
              to={"/inventory/ad-networks?selected-tab=ad-network"}
              state={{ publisher }}
            >
              <Button>취소</Button>
            </Link>
            <Button appearance={"primary"} type="submit">
              등록
            </Button>
          </CreateFooter>
        )}
      />
    </>
  );
};

export default CreateAdNetwork;
