import React, { useCallback, useEffect, useState } from "react";
import {
  DateRangePicker as RsuiteDateRangePicker,
  DateRangePickerProps as RsuiteDateRangePickerProps,
  InputGroup,
} from "rsuite";
import styled from "styled-components";
import moment from "moment";
import { RangeType } from "rsuite/DatePicker";
import { DateRange } from "rsuite/cjs/DateRangePicker/types";

const { allowedMaxDays, beforeToday } = RsuiteDateRangePicker;

const DateRangePicker = (props: DateRangePickerProps) => {
  const { disabled, onChange, setDisabledDate, ...restProps } = props;
  const [value, setValue] = useState<DateRange | null>(props.value || null);

  useEffect(() => {
    if (props.value !== undefined) setValue(props.value);
  }, [props.value]);

  const valueTimeZero: [Date, Date] | undefined =
    value?.[0] && value?.[1]
      ? [
          new Date(value[0].setHours(0, 0, 0, 0)),
          new Date(value[1].setHours(0, 0, 0, 0)),
        ]
      : undefined;

  const disabledDateCheck = () => {
    switch (setDisabledDate) {
      case "beforeToday":
        return beforeToday ? beforeToday() : undefined;
      default:
        return allowedMaxDays ? allowedMaxDays(180) : undefined;
    }
  };

  const selected = ranges.find((range) => {
    if (
      valueTimeZero?.[0] &&
      range.value[0].getTime() === valueTimeZero[0].getTime() &&
      valueTimeZero?.[1] &&
      range.value[1].getTime() === valueTimeZero[1].getTime()
    ) {
      return true;
    }
    return false;
  });

  const handleChange = useCallback(
    (value: any, event: React.SyntheticEvent) => {
      if (onChange) {
        onChange(value, event);
      } else {
        setValue(value);
      }
    },
    [onChange]
  );

  return (
    <div style={{ display: "inline-block" }}>
      <InputGroup disabled={disabled}>
        {selected && (
          <StyledAddon disabled={disabled}>{selected.label}</StyledAddon>
        )}

        <StyledDateRangePicker
          size={"md"}
          cleanable={false}
          locale={{
            sunday: "일",
            monday: "월",
            tuesday: "화",
            wednesday: "수",
            thursday: "목",
            friday: "금",
            saturday: "토",
            ok: "적용",
            today: "Today",
          }}
          ranges={ranges}
          value={valueTimeZero}
          onChange={handleChange}
          disabledDate={disabledDateCheck()}
          {...restProps}
        />
      </InputGroup>
    </div>
  );
};

export default DateRangePicker;

interface DateRangePickerProps extends RsuiteDateRangePickerProps {
  setDisabledDate?: string;
}

const ranges: RangeType<any>[] = [
  {
    label: "어제",
    value: [
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(1, "days")
        .toDate(),
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(1, "days")
        .toDate(),
    ],
  },
  {
    label: "오늘",
    value: [
      moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
      moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    ],
  },
  {
    label: "최근 7일",
    value: [
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(6, "days")
        .toDate(),
      moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    ],
  },
  {
    label: "최근 30일",
    value: [
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(29, "days")
        .toDate(),
      moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    ],
  },
  {
    label: "최근 90일",
    value: [
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(89, "days")
        .toDate(),
      moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    ],
  },
  {
    label: "이번주",
    value: [
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .startOf("week")
        .toDate(),
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .endOf("week")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
    ],
  },
  {
    label: "지난주",
    value: [
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(1, "weeks")
        .startOf("week")
        .toDate(),
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(1, "weeks")
        .endOf("week")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
    ],
  },
  {
    label: "이번달",
    value: [
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .startOf("month")
        .toDate(),
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .endOf("month")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
    ],
  },
  {
    label: "지난달",
    value: [
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(1, "months")
        .startOf("month")
        .toDate(),
      moment()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .subtract(1, "months")
        .endOf("month")
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .toDate(),
    ],
  },
];

const StyledAddon = styled(InputGroup.Addon)`
  &.rs-input-group-addon {
    min-width: 60px;
    background-color: #fff;
    color: var(--rs-text-heading);
    border-right: solid 1px var(--rs-border-primary) !important;
    padding: 3px 9px;
    justify-content: center;

    &.rs-input-group-addon-disabled {
      background-color: var(--rs-input-disabled-bg);
      color: var(--rs-text-disabled);
    }
  }
`;

const StyledDateRangePicker = styled(RsuiteDateRangePicker)`
  &.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  &.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: var(--rs-text-heading);
  }

  &.rs-picker-default .rs-picker-toggle {
    border: none;
  }
  .rs-picker-toggle-active,
  .rs-picker.rs-picker-focused {
    box-shadow: none;
  }
`;
