import React, { useCallback, useContext, useState } from "react";
import { Nav, Sidenav } from "rsuite";
import {
  AreaChartOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Icon } from "@rsuite/icons";
import { MdPictureInPicture } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthContext } from "../../utils/context/AuthContext";

const StyledSideNav = styled(Sidenav)`
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle,
  &.rs-sidenav-collapse-in .rs-dropdown-item:not(.rs-dropdown-item-submenu) {
    font-size: 14px;
  }

  &.rs-sidenav-subtle.rs-sidenav-collapse-in
    .rs-dropdown.rs-dropdown-selected-within
    .rs-dropdown-toggle,
  &.rs-sidenav-subtle .rs-dropdown-item:not(.rs-dropdown-item-active),
  &.rs-sidenav-subtle .rs-dropdown-toggle,
  &.rs-sidenav-subtle .rs-sidenav-item,
  &.rs-sidenav-subtle .rs-sidenav-toggle-button {
    color: var(--rs-text-heading);
  }

  &.rs-sidenav-subtle .rs-dropdown-item.rs-dropdown-item-active,
  &.rs-sidenav-subtle .rs-sidenav-item.rs-sidenav-item-active {
    font-weight: bold;
  }
`;

const LNB = (props: { expanded: boolean }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeKey, setActiveKey] = useState<string | undefined>(
    "/inventory/placements"
  );
  const { myInfo } = useContext(AuthContext);

  const handleSelect = useCallback(
    (eventKey?: string) => {
      if (!eventKey) return;
      setActiveKey(eventKey);
      if (eventKey !== location.pathname) navigate(eventKey);
    },
    [location.pathname, navigate, setActiveKey]
  );
  return (
    <StyledSideNav
      defaultOpenKeys={["inventory", "report", "manage", "account"]}
      style={{
        height: "100%",
        borderRight: `1px solid var(--rs-border-primary)`,
      }}
      appearance="subtle"
      expanded={props.expanded}
    >
      <Sidenav.Body>
        <Nav activeKey={activeKey} onSelect={handleSelect}>
          {myInfo?.role !== "VIEWER" && (
            <Nav.Menu
              eventKey="inventory"
              icon={<Icon as={MdPictureInPicture} />}
              title="인벤토리"
              placement={"rightStart"}
            >
              <Nav.Item
                eventKey="/inventory/placements"
                active={location.pathname.includes("/inventory/placement")}
              >
                게재위치
              </Nav.Item>
              <Nav.Item
                eventKey="/inventory/ad-networks"
                active={location.pathname.includes("/inventory/ad-network")}
              >
                네트워크
              </Nav.Item>
            </Nav.Menu>
          )}
          {myInfo?.role !== "VIEWER" && (
            <Nav.Menu
              eventKey="manage"
              icon={<Icon as={SettingOutlined} style={{ animation: "none" }} />}
              title="운영 관리"
              placement={"rightStart"}
            >
              <Nav.Item
                eventKey="/manage/review"
                active={location.pathname.includes("/manage/review")}
              >
                광고 검수
              </Nav.Item>
              <Nav.Item
                eventKey="/manage/settlement"
                active={location.pathname.includes("/manage/settlement")}
              >
                정산 내역
              </Nav.Item>
            </Nav.Menu>
          )}

          <Nav.Menu
            eventKey="report"
            icon={<Icon as={AreaChartOutlined} style={{ animation: "none" }} />}
            title="보고서"
            placement={"rightStart"}
          >
            <Nav.Item
              eventKey="/report/operation"
              active={location.pathname.includes("/report/operation")}
            >
              운영 보고서
            </Nav.Item>
            {myInfo?.role !== "VIEWER" && (
              <>
                <Nav.Item
                  eventKey="/report/network"
                  active={location.pathname.includes("/report/network")}
                >
                  네트워크 보고서
                </Nav.Item>
                <Nav.Item
                  eventKey="/report/error"
                  active={location.pathname.includes("/report/error")}
                >
                  오류 보고서
                </Nav.Item>
              </>
            )}
          </Nav.Menu>
          <Nav.Menu
            eventKey="account"
            icon={<Icon as={TeamOutlined} style={{ animation: "none" }} />}
            title="계정"
            placement={"rightStart"}
          >
            <Nav.Item
              eventKey={
                myInfo?.role === "MASTER"
                  ? "/account/company"
                  : `/account/company/view/${myInfo?.company_id}`
              }
              active={location.pathname.includes("/account/company")}
            >
              회사
            </Nav.Item>
            <Nav.Item
              eventKey={
                myInfo?.role === "MASTER" || myInfo?.role === "ADMIN"
                  ? "/account/user"
                  : `/account/user/view/${myInfo?.id}`
              }
              active={location.pathname.includes("/account/user")}
            >
              사용자
            </Nav.Item>
          </Nav.Menu>
        </Nav>
      </Sidenav.Body>
    </StyledSideNav>
  );
};

export default LNB;
