import React from "react";
import styled from "styled-components";
import { Stack, StackProps } from "rsuite";

const Container = styled.div`
  padding: 12px 25px;
  border-bottom: solid 1px var(--rs-border-primary);
  display: flex;
  align-items: center;
  height: 61px;
`;
const Title = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: var(--rs-gray-800);
`;

const FlexDiv = styled.div`
  display: flex;
`;

const TableActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 25px;
`;

const PageHeader = ({
  title,
  style,
  children,
}: {
  title: string;
  style?: any;
  children?: any;
}) => {
  return (
    <Container style={style}>
      <Title>{title}</Title>
      {children}
    </Container>
  );
};

const CreateItemContainer = styled.div`
  padding: 25px 30px;
  display: flex;
  align-items: center;
`;

const CreateItemTitle = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  flex: 0 0 150px;
  max-width: 150px;
  margin-right: 12px;
  min-height: 20px;
  line-height: 1.42857143;
  padding: 8px 0;
`;

const CreateItemValue = styled.div`
  flex-basis: 0;
  flex-grow: 1;
`;

const CreateFooter = (props: StackProps) => (
  <Stack
    alignItems={"center"}
    justifyContent={"center"}
    spacing={8}
    {...props}
  />
);

export {
  PageHeader,
  FlexDiv,
  TableActionsContainer,
  CreateItemContainer,
  CreateItemTitle,
  CreateItemValue,
  CreateFooter,
};
