import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TableActionsContainer } from "components/layout";
import { Button, CellProps, Modal, Table as RsuiteTable } from "rsuite";
import { Search, Table } from "components";
import { DateCell, LinkCell, TextCell } from "components/table/Cells";
import { ValueType } from "rsuite/Checkbox";
import { SortType } from "utils/types";
import { Link, useNavigate } from "react-router-dom";
import { LinkText } from "components/text";
import { TableColumns } from "../../../components/table/Table";
import { PlacementGroupService } from "../../../utils/api";
import { underToUpper } from "../../../utils";
import {
  getComparatorsString,
  getOperatorsString,
} from "../../../front-utils/filter/dynamicFilter";
import _ from "lodash";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { AuthContext } from "../../../utils/context/AuthContext";

const { Cell } = RsuiteTable;

const PlacementGroupTable = (props: { publisher: any }) => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<ValueType[]>([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  }>({
    sortColumn: "created_at",
    sortType: "desc",
  });
  const [searchKey, setSearchKey] = useState("name");
  const [searchValue, setSearchValue] = useState("");
  const [appliedSearchValue, setAppliedSearchValue] =
    useState<string>(searchValue);
  const [openRemoveDisableModal, setOpenRemoveDisableModal] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const { myInfo } = useContext(AuthContext);
  const [tableLoading, setTableLoading] = useState(false);
  const { publisher } = props;
  const navigate = useNavigate();

  const columns: () => TableColumns[] = useMemo(
    () => () => {
      const columns: TableColumns[] = [
        {
          title: "게재위치 그룹",
          key: "name",
          sortable: true,
          width: 200,
          flexGrow: 1,
          Cell: (props: CellProps) => (
            <LinkCell
              to={`/inventory/placement-group/edit/${props.rowData.id}`}
              state={{ publisher }}
              {...props}
            />
          ),
        },
        {
          title: "대표 주소",
          key: "representative_address",
          width: 200,
          flexGrow: 1,
        },
        {
          title: "담당자",
          key: "account_placement_groups.account",
          width: 100,
          flexGrow: 1,
          Cell: (props: CellProps) => {
            const accounts = props.rowData.accounts || [];
            return (
              <TextCell
                {...props}
                text={
                  accounts.length === 0
                    ? "지정 담당자 없음"
                    : `${accounts[0].name}${
                        accounts.length > 1 ? `외 ${accounts.length - 1}명` : ""
                      }`
                }
              />
            );
          },
        },
        {
          title: "게재위치",
          key: "placement_count",
          sortable: true,
          width: 100,
          flexGrow: 1,
          align: "center",
          Cell: (props: CellProps) => {
            return (
              <Cell {...props}>
                {props.rowData.placement_count ? (
                  <LinkText
                    onClick={() => {
                      navigate(
                        `/inventory/placements?selected-tab=placement&search-key=placementGroup.name&search-value=${props.rowData?.name}`
                      );
                    }}
                  >
                    {props.rowData.placement_count}
                  </LinkText>
                ) : (
                  "-"
                )}
              </Cell>
            );
          },
        },
        {
          title: "생성일",
          key: "created_at",
          sortable: true,
          width: 150,
          flexGrow: 1,
          Cell: DateCell,
        },
        {
          title: "업데이트일",
          key: "updated_at",
          sortable: true,
          width: 150,
          flexGrow: 1,
          Cell: DateCell,
        },
        {
          title: "마지막 사용자",
          key: "last_updater.name",
          sortable: true,
          width: 150,
          flexGrow: 1,
          Cell: (props: CellProps) => (
            <TextCell
              {...props}
              text={`${props.rowData?.last_updater?.name}(${props.rowData?.last_updater?.login_id})`}
            />
          ),
        },
      ];

      if (myInfo?.role === "MASTER") {
        columns.unshift({
          title: "매체사",
          key: "publisher.name",
          sortable: true,
          width: 150,
        } as TableColumns);
      }
      return columns;
    },
    [myInfo?.role, navigate, publisher]
  );
  const fetchData = useCallback(async () => {
    try {
      setTableLoading(true);
      const { data } = await new PlacementGroupService().get({
        page,
        size,
        sort: underToUpper(sort.sortColumn) + "," + sort.sortType,
        filter: getOperatorsString(
          "and",
          [
            publisher
              ? getComparatorsString(":", "publisher.id", publisher)
              : "",
            appliedSearchValue !== "" &&
            (searchKey === "name" ||
              searchKey === "accountPlacementGroups.account.name")
              ? getComparatorsString("~", searchKey, `%${appliedSearchValue}%`)
              : "",
            appliedSearchValue !== "" && searchKey === "lastUpdater"
              ? `(${getOperatorsString("or", [
                  getComparatorsString(
                    "~",
                    "lastUpdater.name",
                    `%${appliedSearchValue}%`
                  ),
                  getComparatorsString(
                    "~",
                    "lastUpdater.loginId",
                    `%${appliedSearchValue}%`
                  ),
                ])})`
              : "",
          ].filter((v) => v !== "")
        ),
      });
      setData(data.content);
      setTotal(data.total_elements);
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }, [
    appliedSearchValue,
    page,
    publisher,
    searchKey,
    size,
    sort.sortColumn,
    sort.sortType,
  ]);

  useEffect(() => {
    fetchData();
    setCheckedKeys([]);
  }, [fetchData]);

  useEffect(() => {
    setPage(1);
  }, [size, appliedSearchValue, publisher]);

  const handleCheck = useCallback(
    (value: ValueType | undefined, checked: boolean) => {
      if (!value) return;
      const nextCheckedKeys = checked
        ? [...checkedKeys, value]
        : checkedKeys.filter((item) => item !== value);

      setCheckedKeys(nextCheckedKeys);
    },
    [checkedKeys]
  );

  const handleCheckAll = useCallback(
    (_, checked: boolean) => {
      const allCheckedKeys = checked ? data.map((item) => item.id) : [];
      setCheckedKeys(allCheckedKeys);
    },
    [data]
  );

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  const handleSearchKeyChange = useCallback(
    (v: "name" | "accountPlacementGroups.account.name" | "lastUpdater") => {
      setSearchKey(v);
      setSearchValue("");
    },
    []
  );

  const delayedSetSearchValue = useMemo<any>(
    () => _.debounce((v: string) => setAppliedSearchValue(v), 500),
    []
  );

  const handleSearchValueChange = useCallback((v: string) => {
    setSearchValue(v);
  }, []);

  useEffect(() => {
    delayedSetSearchValue(searchValue);
  }, [searchValue, delayedSetSearchValue]);

  const handleRemoveCheck = useCallback(async () => {
    if (
      checkedKeys.some(
        (id) => data.find((item) => item.id === id)?.placement_count
      )
    ) {
      setOpenRemoveDisableModal(true);
      return;
    }

    try {
      await new PlacementGroupService().deleteGroupList(checkedKeys);

      fetchData();
      setCheckedKeys([]);
    } catch (e) {
      setErrorModalOpen(true);
    }
  }, [checkedKeys, data, fetchData, setErrorModalOpen]);

  return (
    <>
      <TableActionsContainer style={{ justifyContent: "space-between" }}>
        <div>
          <Button
            disabled={checkedKeys.length === 0}
            onClick={handleRemoveCheck}
          >
            삭제
          </Button>

          <Link
            to={{ pathname: "/inventory/placement-group/new" }}
            state={{ publisher }}
          >
            <Button
              appearance="primary"
              style={{ marginLeft: 8 }}
              disabled={myInfo?.role === "MASTER" && !publisher}
            >
              생성
            </Button>
          </Link>
        </div>
        <Search
          data={searchKeys}
          onSearchKeyChange={handleSearchKeyChange}
          searchKey={searchKey}
          searchValue={searchValue}
          onSearchValueChange={handleSearchValueChange}
        />
      </TableActionsContainer>
      <Table
        data={data}
        columns={columns()}
        checkable={true}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        checkedKeys={checkedKeys}
        onCheck={handleCheck}
        onCheckAll={handleCheckAll}
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
        onSortColumn={handleSortChange}
        loading={tableLoading}
      />
      <Modal
        size={"xs"}
        open={openRemoveDisableModal}
        onClose={() => setOpenRemoveDisableModal(false)}
        role="alertdialog"
      >
        <Modal.Header>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>소속된 게재위치가 있어 삭제할 수 없습니다.</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpenRemoveDisableModal(false)}
            appearance="primary"
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PlacementGroupTable;

const searchKeys = [
  {
    label: "게재위치 그룹",
    value: "name",
  },
  {
    label: "담당자",
    value: "accountPlacementGroups.account.name",
  },
  {
    label: "마지막 사용자",
    value: "lastUpdater",
  },
];
