import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PageHeader, TableActionsContainer } from "../../../components/layout";
import {
  DateRangePicker,
  InputNumber,
  MultiplePicker,
  Search,
  SelectPicker,
  Table,
} from "../../../components";
import {
  Button,
  CellProps,
  Divider,
  Stack,
  Table as RsuiteTable,
} from "rsuite";
import { ItemDataType } from "rsuite/cjs/@types/common";
import { DateRange } from "rsuite/cjs/DateRangePicker/types";
import moment from "moment";
import {
  AdNetworkCodeType,
  CurrencyType,
  PeriodType,
  SortType,
} from "../../../utils/types";
import { Icon } from "@rsuite/icons";
import { RiExchangeDollarLine } from "react-icons/ri";
import { InfoMessage } from "../../../components/text";
import {
  DateCell,
  FloatCell,
  NumberCell,
  PercentCell,
  TextCell,
} from "../../../components/table/Cells";
import { TableColumns } from "../../../components/table/Table";
import { AuthContext } from "../../../utils/context/AuthContext";
import {
  AdNetworkService,
  CompanyService,
  ReportService,
} from "../../../utils/api";
import { underToUpper } from "../../../utils";
import _ from "lodash";
import fileDownload from "js-file-download";
import { TableColumnInfo } from "../operation/OperationReport";

const { Cell, Column, HeaderCell, ColumnGroup } = RsuiteTable;

const NetworkReport = () => {
  const [date, setDate] = useState<DateRange | null>([
    moment().subtract("1", "day").toDate(),
    moment().subtract("1", "day").toDate(),
  ]);
  const [period, setPeriod] = useState<PeriodType>("DAILY");
  const [networkCodes, setNetworkCodes] = useState<AdNetworkCodeType[]>([]);
  const [networks, setNetworks] = useState<ItemDataType[]>([]);
  const [exchangeRate, setExchangeRate] = useState<string>("");
  const [appliedExchangeRate, setAppliedExchangeRate] =
    useState<string>(exchangeRate);
  const [isInitClicked, setIsInitClicked] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  } | null>(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [publishers, setPublishers] = useState<{ label: string; value: any }[]>(
    []
  );
  const { myInfo } = useContext(AuthContext);
  const [publisher, setPublisher] = useState<any>(
    myInfo?.company_type === "PUBLISHER" ? myInfo?.company_id : null
  );
  const [searchKey, setSearchKey] = useState<
    "ad-network-name" | "ad-network-unit-name"
  >("ad-network-name");
  const [searchValue, setSearchValue] = useState<string>("");
  const [appliedSearchValue, setAppliedSearchValue] =
    useState<string>(searchValue);
  const [appliedParams, setAppliedParams] = useState({
    date,
    period,
    networkCodes,
  });

  const fetchPublishers = useCallback(async () => {
    if (myInfo?.role !== "MASTER") return;
    const { data } = await new CompanyService().getIdNameMeta({
      filter: "type : 'PUBLISHER'",
    });

    setPublishers(
      data.map((d: { id: number; name: string }) => ({
        label: d.name,
        value: d.id,
      }))
    );
  }, [myInfo?.role]);

  useEffect(() => {
    fetchPublishers();
  }, [fetchPublishers]);

  useEffect(() => {
    setPage(1);
  }, [size, appliedParams, publisher, appliedSearchValue]);

  const fetchNetworks = useCallback(async () => {
    try {
      const { data } = await new AdNetworkService().getCodes();
      setNetworks(
        data
          .filter(
            (network: { value: AdNetworkCodeType; name: string }) =>
              !hasNoReportNetworks.includes(network.value)
          )
          .map((network: { value: AdNetworkCodeType; name: string }) => ({
            value: network.value,
            label: network.name,
          }))
      );
      setNetworkCodes(
        data
          .filter(
            (network: { value: AdNetworkCodeType; name: string }) =>
              !hasNoReportNetworks.includes(network.value)
          )
          .map((data: { value: AdNetworkCodeType; name: string }) => data.value)
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchReport = useCallback(async () => {
    try {
      setTableLoading(true);

      const { data } = await new ReportService().getAdNetwork({
        page,
        size,
        sort: sort
          ? underToUpper(sort.sortColumn) + "," + sort.sortType
          : ["date,desc", "adNetworkName,asc", "adNetworkUnitName,asc"],
        "period-type": appliedParams.period,
        "start-date": moment(appliedParams.date?.[0]).format("YYYY-MM-DD"),
        "end-date": moment(appliedParams.date?.[1]).format("YYYY-MM-DD"),
        "company-id": publisher,
        "ad-network-codes": appliedParams.networkCodes,
        "ad-network-name":
          searchKey === "ad-network-name" ? appliedSearchValue : "",
        "ad-network-unit-name":
          searchKey === "ad-network-unit-name" ? appliedSearchValue : "",
      });

      const count = Math.pow(10, 2);
      const reportData = data.reports.content.map(
        (d: { currency: CurrencyType; revenue: number }) => ({
          ...d,
          revenue_usd: d.currency === "USD" ? d.revenue : null,
          revenue_krw:
            d.currency === "KRW"
              ? d.revenue
              : appliedExchangeRate
              ? (Math.floor(d.revenue * count) / count) *
                parseInt(appliedExchangeRate)
              : null,
        })
      );
      if (reportData.length > 0)
        reportData.unshift({
          type: "TOTAL",
          ...data.summary,
          revenue_krw: appliedExchangeRate
            ? (Math.floor(data.summary.revenue_usd * count) / count) *
                parseInt(appliedExchangeRate) +
              data.summary.revenue_krw
            : data.summary.revenue_krw,
        });

      setData(reportData);
      setTotal(data.reports.total_elements);
    } finally {
      setTableLoading(false);
    }
  }, [
    page,
    size,
    sort,
    appliedParams.period,
    appliedParams.date,
    appliedParams.networkCodes,
    publisher,
    searchKey,
    appliedSearchValue,
    appliedExchangeRate,
  ]);

  useEffect(() => {
    fetchNetworks();
  }, [fetchNetworks]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  const columns: () => TableColumns[] = useMemo(
    () => () => {
      const columns: TableColumns[] = [
        {
          title: "일자",
          key: "date",
          sortable: true,
          width: 120,
          headerStyle: {
            padding:
              myInfo?.role !== "MASTER" ? "30px 10px 30px 25px" : "30px 10px",
          },
          Cell: (props: CellProps) => {
            return props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : appliedParams.period === "TOTAL" ? (
              <TextCell {...props} text={"총합"} />
            ) : (
              <DateCell
                format={
                  appliedParams.period === "DAILY" ? "YYYY-MM-DD" : "YYYY-MM"
                }
                {...props}
              />
            );
          },
        },
        {
          title: "네트워크",
          key: "ad_network_name",
          sortable: true,
          width: 150,
          headerStyle: { padding: "30px 10px" },
          Cell: (props: CellProps) => {
            return props.rowData.type === "TOTAL" ? (
              <Cell {...props}>합계</Cell>
            ) : (
              <TextCell
                {...props}
                text={`${props.rowData.ad_network_name}(${props.rowData.ad_network_type})`}
              />
            );
          },
        },
        {
          title: "네트워크 유닛",
          key: "ad_network_unit_name",
          sortable: true,
          width: 300,
          headerStyle: { padding: "30px 10px" },
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : (
              <TextCell {...props} />
            ),
        },
        {
          title: "요청수",
          key: "requests",
          sortable: true,
          width: 110,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: NumberCell,
          align: "right",
        },

        {
          title: <>전달수</>,
          key: "fills",
          sortable: true,
          width: 110,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: NumberCell,
          align: "right",
        },
        {
          title: <>노출수</>,
          key: "impressions",
          sortable: true,
          width: 110,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: NumberCell,
          align: "right",
        },
        {
          title: <>Fill rate</>,
          key: "fill_rate",
          sortable: true,
          width: 110,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: PercentCell,
          align: "right",
        },
        {
          title: <>동영상 조회수</>,
          key: "views",
          sortable: true,
          width: 140,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: NumberCell,
          align: "right",
        },
        {
          title: <>클릭수</>,
          key: "clicks",
          sortable: true,
          width: 110,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: NumberCell,
          align: "right",
        },
        {
          title: <>CTR</>,
          key: "ctr",
          sortable: true,
          width: 110,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: PercentCell,
          align: "right",
        },
        {
          title: <>VTR</>,
          key: "vtr",
          sortable: true,
          width: 100,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: PercentCell,
          align: "right",
        },
        {
          title: <>동영상 재생수(시작)</>,
          key: "start_views",
          sortable: true,
          width: 165,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: NumberCell,
          align: "right",
        },
        {
          title: <>동영상 25% 재생수</>,
          key: "first_quartile_views",
          sortable: true,
          width: 165,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: NumberCell,
          align: "right",
        },
        {
          title: <>동영상 50% 재생수</>,
          key: "midpoint_views",
          sortable: true,
          width: 165,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: NumberCell,
          align: "right",
        },
        {
          title: <>동영상 75% 재생수</>,
          key: "third_quartile_views",
          sortable: true,
          width: 165,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: NumberCell,
          align: "right",
        },
        {
          title: <>동영상 100% 재생수</>,
          key: "complete_views",
          sortable: true,
          width: 170,
          flexGrow: 1,
          headerStyle: { padding: "30px 10px" },
          Cell: NumberCell,
          align: "right",
        },
      ];

      if (myInfo?.role === "MASTER") {
        columns.unshift({
          title: "매체사",
          key: "company_name",
          sortable: true,
          width: 150,
          headerStyle: { padding: "30px 10px 30px 25px" },
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : (
              <TextCell {...props} />
            ),
        } as TableColumns);
      }
      return columns;
    },
    [appliedParams.period, myInfo?.role]
  );

  const handleApplyExchangeRate = useCallback(() => {
    if (exchangeRate === "") return;
    setAppliedExchangeRate(exchangeRate);
  }, [exchangeRate]);

  const handleInitExchangeRate = useCallback(() => {
    setExchangeRate("");
    setAppliedExchangeRate("");
    setIsInitClicked(true);
  }, []);

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  const handleClickFetch = useCallback(() => {
    setAppliedParams({
      period,
      date,
      networkCodes,
    });
  }, [date, networkCodes, period]);

  const handleSearchKeyChange = useCallback(
    (v: "ad-network-name" | "ad-network-unit-name") => {
      setSearchKey(v);
      setSearchValue("");
    },
    []
  );

  const delayedSetSearchValue = useMemo<any>(
    () => _.debounce((v: string) => setAppliedSearchValue(v), 500),
    []
  );

  const handleSearchValueChange = useCallback((v: string) => {
    setSearchValue(v);
  }, []);

  useEffect(() => {
    delayedSetSearchValue(searchValue);
  }, [searchValue, delayedSetSearchValue]);

  const handleClickDownload = useCallback(async () => {
    try {
      const { data } = await new ReportService().downloadAdNetwork({
        sort: sort
          ? underToUpper(sort.sortColumn) + "," + sort.sortType
          : ["date,desc", "adNetworkName,asc", "adNetworkUnitName,asc"],
        "period-type": appliedParams.period,
        "start-date": moment(appliedParams.date?.[0]).format("YYYY-MM-DD"),
        "end-date": moment(appliedParams.date?.[1]).format("YYYY-MM-DD"),
        "company-id": publisher,
        "ad-network-codes": appliedParams.networkCodes,
        "ad-network-name":
          searchKey === "ad-network-name" ? appliedSearchValue : "",
        "ad-network-unit-name":
          searchKey === "ad-network-unit-name" ? appliedSearchValue : "",
        "exchange-rate": appliedExchangeRate
          ? parseInt(appliedExchangeRate)
          : undefined,
      });

      fileDownload(
        data,
        `네트워크보고서-${
          myInfo?.role !== "MASTER"
            ? myInfo?.company_name
            : publisher
            ? publishers.find((pub) => pub.value === publisher)?.label
            : "매체사전체"
        }-${moment(appliedParams.date?.[0]).format("YYYYMMDD")}-${moment(
          appliedParams.date?.[1]
        ).format("YYYYMMDD")}.xlsx`
      );
    } catch (e) {
      console.log(e);
    }
  }, [
    appliedExchangeRate,
    appliedParams.date,
    appliedParams.networkCodes,
    appliedParams.period,
    appliedSearchValue,
    myInfo?.company_name,
    myInfo?.role,
    publisher,
    publishers,
    searchKey,
    sort,
  ]);

  return (
    <>
      <PageHeader title={"네트워크 보고서"}>
        {myInfo?.role === "MASTER" && (
          <SelectPicker
            placeholder={"매체사 선택"}
            data={publishers}
            value={publisher}
            onChange={setPublisher}
            style={{ width: 200, marginLeft: 10 }}
          />
        )}
      </PageHeader>
      <Stack
        spacing={8}
        style={{
          padding: "9px 25px",
          borderBottom: "1px solid var(--rs-border-primary)",
        }}
      >
        <DateRangePicker value={date} onChange={setDate} />
        <SelectPicker
          data={periodData}
          value={period}
          onChange={setPeriod}
          cleanable={false}
          renderValue={(value: any, items: ItemDataType) => (
            <span>{`시간 : ${items.label}`}</span>
          )}
        />

        <MultiplePicker
          data={networks}
          label={"네트워크"}
          value={networkCodes}
          onValueChange={setNetworkCodes}
        />

        <Button appearance={"primary"} onClick={handleClickFetch}>
          조회
        </Button>
      </Stack>
      <Stack
        spacing={8}
        style={{
          padding: "9px 25px",
          borderBottom: "1px solid var(--rs-border-primary)",
        }}
      >
        <Icon
          as={RiExchangeDollarLine}
          style={{
            fontSize: "18px",
            color: "var(--rs-gray-600)",
            marginLeft: 4,
            verticalAlign: "middle",
          }}
        />
        <span>1달러 기준 환율</span>
        <InputNumber
          maxLength={4}
          value={exchangeRate}
          onChange={setExchangeRate}
          updateToPropValue={isInitClicked}
          setUpdateToPropValue={setIsInitClicked}
        />
        <span>원</span>
        <Button appearance={"primary"} onClick={handleApplyExchangeRate}>
          적용
        </Button>
        <Button onClick={handleInitExchangeRate}>초기화</Button>
      </Stack>
      <TableActionsContainer>
        <Stack>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "var(--rs-gray-800)",
              height: "50px",
            }}
          >
            조회 결과
          </span>
          <Divider vertical />
          <span>총 {total}개</span>
          <InfoMessage style={{ marginLeft: 20 }}>
            * 데이터는 매일 오전 7시에 업데이트되며, 운영 보고서와 데이터가
            상이할 수 있습니다.
          </InfoMessage>
        </Stack>
        <Stack spacing={8}>
          <Search
            data={searchKeys}
            searchKey={searchKey}
            onSearchKeyChange={handleSearchKeyChange}
            searchValue={searchValue}
            onSearchValueChange={handleSearchValueChange}
          />
          <Button onClick={handleClickDownload}>다운로드</Button>
        </Stack>
      </TableActionsContainer>
      <Table
        data={data}
        columns={columns()}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        sortColumn={sort?.sortColumn}
        sortType={sort?.sortType}
        onSortColumn={handleSortChange}
        rowClassName={(rowData: any) => {
          if (!rowData) return "";
          return rowData.type === "TOTAL" ? "total" : "";
        }}
        headerHeight={80}
        loading={tableLoading}
      >
        <ColumnGroup
          header={
            <>
              광고 수익
              <TableColumnInfo
                inner={
                  <>
                    <div>USD 통화의 소수점 이하 계산으로 인해</div>
                    <div>
                      합계 행의 값과 개별 행에 표시된 값의 합이 다를 수
                      있습니다.
                    </div>
                  </>
                }
              />
            </>
          }
          align={"center"}
        >
          <Column minWidth={110} flexGrow={1} align={"right"}>
            <HeaderCell>USD</HeaderCell>
            <FloatCell dataKey="revenue_usd" />
          </Column>

          <Column minWidth={110} flexGrow={1} align={"right"}>
            <HeaderCell>KRW</HeaderCell>
            <NumberCell dataKey="revenue_krw" />
          </Column>
        </ColumnGroup>
      </Table>
    </>
  );
};

export default NetworkReport;

const periodData = [
  {
    label: "총합",
    value: "TOTAL",
  },
  {
    label: "일별",
    value: "DAILY",
  },
  {
    label: "월별",
    value: "MONTHLY",
  },
];

const searchKeys = [
  {
    label: "네트워크",
    value: "ad-network-name",
  },
  {
    label: "네트워크 유닛",
    value: "ad-network-unit-name",
  },
];

const hasNoReportNetworks = [
  "UPLUSAD",
  "PUBMATIC",
  "REALCLICK",
  "NASMEDIA",
  "PTBWA",
];
