import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TableActionsContainer } from "components/layout";
import { Filter, InfoTooltip, Search, Table, WarnTooltip } from "components";
import {
  Button,
  CellProps,
  Dropdown,
  Modal,
  Stack,
  Table as RsuiteTable,
} from "rsuite";
import { Link, useLocation } from "react-router-dom";
import {
  DateCell,
  EnabledCell,
  LinkCell,
  MapCell,
  TextCell,
} from "components/table/Cells";
import { ValueType } from "rsuite/Checkbox";
import { SortType } from "utils/types";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { AuthContext } from "../../../utils/context/AuthContext";
import { TableColumns } from "../../../components/table/Table";
import _ from "lodash";
import { PlacementService } from "../../../utils/api";
import { getFilterStringFromObject, underToUpper } from "../../../utils";
import {
  getComparatorsString,
  getOperatorsString,
} from "../../../front-utils/filter/dynamicFilter";
import {
  MOBILE_OS_TYPE,
  PLATFORM_TYPE,
  SCREEN_TYPE,
} from "../../../utils/variables";
import queryString from "query-string";
import fileDownload from "js-file-download";
import moment from "moment/moment";

const { Cell } = RsuiteTable;

const PlacementTable = (props: { publisher: any }) => {
  const query: {
    "search-key"?: string;
    "search-value"?: string;
  } = queryString.parse(useLocation().search);
  const searchKeyFromQuery = query["search-key"];
  const searchValueFromQuery = query["search-value"];

  const [filterValue, setFilterValue] = useState<ValueType[]>([]);
  const [filterTempValue, setFilterTempValue] = useState<ValueType[]>([]);
  const [disabledItemValues, setDisabledItemValues] = useState<ValueType[]>([
    "IOS",
    "ANDROID",
  ]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<ValueType[]>([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  }>({
    sortColumn: "created_at",
    sortType: "desc",
  });
  const [searchKey, setSearchKey] = useState(searchKeyFromQuery || "name");
  const [searchValue, setSearchValue] = useState(searchValueFromQuery || "");
  const [appliedSearchValue, setAppliedSearchValue] =
    useState<string>(searchValue);
  const [openRemoveDisableModal, setOpenRemoveDisableModal] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const { myInfo } = useContext(AuthContext);
  const [tableLoading, setTableLoading] = useState(false);
  const { publisher } = props;

  const handleFilterSelect = useCallback((value: ValueType[]) => {
    setFilterValue(value);
  }, []);

  const handleFilterItemCheck = useCallback((value: ValueType[]) => {
    if (!value.includes("MOBILE")) {
      setFilterTempValue(value.filter((s) => s !== "IOS" && s !== "ANDROID"));
      setDisabledItemValues((prev) => prev.concat(["IOS", "ANDROID"]));
    } else {
      setDisabledItemValues((prev) =>
        prev.filter((s) => s !== "IOS" && s !== "ANDROID")
      );
    }
  }, []);

  useEffect(() => {
    if (filterValue.includes("MOBILE"))
      setDisabledItemValues((prev) =>
        prev.filter((s) => s !== "IOS" && s !== "ANDROID")
      );
    else {
      setDisabledItemValues((prev) => prev.concat(["IOS", "ANDROID"]));
    }
  }, [filterValue]);

  const columns = useMemo(
    () => () => {
      const columns: TableColumns[] = [
        {
          title: "상태",
          key: "enabled",
          sortable: true,
          width: 70,
          Cell: EnabledCell,
        },
        {
          title: "게재위치 ID",
          key: "id",
          sortable: true,
          width: 120,
        },
        {
          title: "게재위치 코드",
          key: "code",
          sortable: true,
          width: 200,
          flexGrow: 1,
        },
        {
          title: "게재위치",
          key: "name",
          sortable: true,
          width: 300,
          flexGrow: 1,
          Cell: (props: CellProps) => (
            <LinkCell
              to={`/inventory/placement/edit/${props.rowData.id}`}
              state={{ publisher }}
              {...props}
            />
          ),
        },
        {
          title: "게재위치 그룹",
          key: "placement_group_name",
          sortable: true,
          width: 200,
          flexGrow: 1,
        },
        {
          title: "스크린",
          key: "platform",
          sortable: true,
          width: 80,
          Cell: (props: CellProps) => (
            <MapCell mapper={SCREEN_TYPE} {...props} />
          ),
        },
        {
          title: "플랫폼",
          key: "application",
          sortable: true,
          width: 85,
          Cell: (props: CellProps) => (
            <MapCell mapper={PLATFORM_TYPE} {...props} />
          ),
        },
        {
          title: "모바일OS",
          key: "platform",
          sortable: true,
          width: 95,
          Cell: (props: CellProps) => (
            <MapCell mapper={MOBILE_OS_TYPE} {...props} />
          ),
        },
        {
          title: "네트워크 광고",
          key: "is_using_ad_network",
          sortable: true,
          width: 115,
          Cell: (props: CellProps) => {
            return (
              <Cell {...props}>
                {props.rowData?.is_using_ad_network ? "제공" : "미제공"}
                {props.rowData?.is_using_ad_network &&
                  !props.rowData?.has_active_network && (
                    <WarnTooltip inner={"활성화된 네트워크가 없습니다."} />
                  )}
              </Cell>
            );
          },
        },
        {
          title: "생성일",
          key: "created_at",
          sortable: true,
          width: 150,
          Cell: DateCell,
        },
        {
          title: "업데이트일",
          key: "updated_at",
          sortable: true,
          width: 150,
          Cell: DateCell,
        },
        {
          title: "마지막 사용자",
          key: "last_updater.name",
          sortable: true,
          width: 150,
          Cell: (props: CellProps) => (
            <TextCell
              {...props}
              text={`${props.rowData?.last_updater?.name}(${props.rowData?.last_updater?.login_id})`}
            />
          ),
        },
      ];

      if (myInfo?.role === "MASTER") {
        columns.unshift({
          title: "매체사",
          key: "publisher.name",
          sortable: true,
          width: 150,
        } as TableColumns);
      }
      return columns;
    },
    [myInfo?.role, publisher]
  );

  const getFetchParams = useCallback(() => {
    return {
      page,
      size,
      sort: underToUpper(sort.sortColumn) + "," + sort.sortType,
      filter: getOperatorsString(
        "and",
        [
          publisher ? getComparatorsString(":", "publisher.id", publisher) : "",
          appliedSearchValue !== "" && searchKey === "id"
            ? getComparatorsString(":", searchKey, appliedSearchValue)
            : "",
          appliedSearchValue !== "" &&
          (searchKey === "code" ||
            searchKey === "name" ||
            searchKey === "placementGroup.name")
            ? getComparatorsString("~", searchKey, `%${appliedSearchValue}%`)
            : "",
          appliedSearchValue !== "" && searchKey === "lastUpdater"
            ? `(${getOperatorsString("or", [
                getComparatorsString(
                  "~",
                  "lastUpdater.name",
                  `%${appliedSearchValue}%`
                ),
                getComparatorsString(
                  "~",
                  "lastUpdater.loginId",
                  `%${appliedSearchValue}%`
                ),
              ])})`
            : "",
          ...getFilterStringFromObject(
            filterValue,
            filterData,
            (key, value) => {
              if (key === "platform" && value.includes("MOBILE")) {
                const newValue = [...value.filter((v) => v !== "MOBILE")];

                if (!value.includes("IOS") && !value.includes("ANDROID")) {
                  newValue.push("IOS");
                  newValue.push("ANDROID");
                }
                return newValue;
              } else if (key === "isUsingAdNetwork") {
                return value.map((v: any) => v === "PROVIDE");
              } else return value;
            }
          ),
        ].filter((v) => v !== "")
      ),
    };
  }, [
    appliedSearchValue,
    filterValue,
    page,
    publisher,
    searchKey,
    size,
    sort.sortColumn,
    sort.sortType,
  ]);

  const fetchData = useCallback(async () => {
    try {
      setTableLoading(true);
      const { data } = await new PlacementService().get(getFetchParams());
      setData(data.content);
      setTotal(data.total_elements);
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }, [getFetchParams]);

  useEffect(() => {
    fetchData();
    setCheckedKeys([]);
  }, [fetchData]);

  useEffect(() => {
    setPage(1);
  }, [size, appliedSearchValue, filterValue, publisher]);

  const handleCheck = useCallback(
    (value: ValueType | undefined, checked: boolean) => {
      if (!value) return;
      const nextCheckedKeys = checked
        ? [...checkedKeys, value]
        : checkedKeys.filter((item) => item !== value);

      setCheckedKeys(nextCheckedKeys);
    },
    [checkedKeys]
  );

  const handleCheckAll = useCallback(
    (_, checked: boolean) => {
      const allCheckedKeys = checked ? data.map((item) => item.id) : [];
      setCheckedKeys(allCheckedKeys);
    },
    [data]
  );

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  const handleSearchKeyChange = useCallback(
    (v: "code" | "name" | "placementGroup.name" | "lastUpdater") => {
      setSearchKey(v);
      setSearchValue("");
    },
    []
  );

  const delayedSetSearchValue = useMemo<any>(
    () => _.debounce((v: string) => setAppliedSearchValue(v), 500),
    []
  );

  const handleSearchValueChange = useCallback((v: string) => {
    setSearchValue(v);
  }, []);

  useEffect(() => {
    delayedSetSearchValue(searchValue);
  }, [searchValue, delayedSetSearchValue]);

  const handleEnabledSelect = useCallback(
    async (eventKey: "ACTIVE" | "INACTIVE") => {
      try {
        await new PlacementService().editEnabled({
          ids: checkedKeys,
          enabled: eventKey === "ACTIVE",
        });

        fetchData();
        setCheckedKeys([]);
      } catch (e: any) {
        setErrorModalOpen(true);
      }
    },
    [checkedKeys, fetchData, setErrorModalOpen]
  );

  const handleClickRemove = useCallback(async () => {
    try {
      await new PlacementService().deleteList(checkedKeys);

      fetchData();
      setCheckedKeys([]);
    } catch (e: any) {
      if (e.response.data?.error_code === "IMPOSSIBLE_DELETE_PLACEMENT") {
        setOpenRemoveDisableModal(true);
      } else {
        setErrorModalOpen(true);
      }
    }
  }, [checkedKeys, fetchData, setErrorModalOpen]);

  const handleClickDownload = useCallback(async () => {
    try {
      const { data } = await new PlacementService().download(
        _.omit(getFetchParams(), ["page", "size"])
      );
      fileDownload(data, `게재위치_${moment().format("YYYYMMDD")}.xlsx`);
    } catch (e) {
      console.log(e);
    }
  }, [getFetchParams]);

  return (
    <div>
      <TableActionsContainer style={{ justifyContent: "space-between" }}>
        <Stack spacing={8}>
          <Dropdown
            title={"상태 변경"}
            menuStyle={{ width: "100%" }}
            disabled={checkedKeys.length === 0}
            onSelect={handleEnabledSelect}
          >
            <Dropdown.Item eventKey={"ACTIVE"}>활성</Dropdown.Item>
            <Dropdown.Item eventKey={"INACTIVE"}>비활성</Dropdown.Item>
          </Dropdown>
          <Button
            disabled={checkedKeys.length === 0}
            onClick={handleClickRemove}
          >
            삭제
          </Button>
          <Link
            to={{ pathname: "/inventory/placement/new" }}
            state={{ publisher }}
          >
            <Button
              appearance="primary"
              disabled={myInfo?.role === "MASTER" && !publisher}
            >
              생성
            </Button>
          </Link>
        </Stack>
        <Stack spacing={8}>
          <Filter
            data={filterData}
            value={filterValue}
            checkedValue={filterTempValue}
            setCheckedValue={setFilterTempValue}
            onSelect={handleFilterSelect}
            onItemCheck={handleFilterItemCheck}
            placement={"bottomEnd"}
            disabledItemValues={disabledItemValues}
            renderGroupTitle={(title: string) => {
              if (title === "모바일OS")
                return (
                  <>
                    {title}{" "}
                    <InfoTooltip
                      inner={"스크린 'Mobile' 선택시 활성화됩니다."}
                    />
                  </>
                );
              return title;
            }}
          />
          <Search
            data={searchKeys}
            onSearchKeyChange={handleSearchKeyChange}
            searchKey={searchKey}
            searchValue={searchValue}
            onSearchValueChange={handleSearchValueChange}
          />
          <Button onClick={handleClickDownload}>다운로드</Button>
        </Stack>
      </TableActionsContainer>
      <Table
        data={data}
        columns={columns()}
        checkable={true}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        checkedKeys={checkedKeys}
        onCheck={handleCheck}
        onCheckAll={handleCheckAll}
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
        onSortColumn={handleSortChange}
        loading={tableLoading}
      />
      <Modal
        size={"xs"}
        open={openRemoveDisableModal}
        onClose={() => setOpenRemoveDisableModal(false)}
        role="alertdialog"
      >
        <Modal.Header>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>성과가 있는 게재위치는 삭제할 수 없습니다.</Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpenRemoveDisableModal(false)}
            appearance="primary"
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PlacementTable;

const searchKeys = [
  {
    label: "게재위치 ID",
    value: "id",
  },
  {
    label: "게재위치 코드",
    value: "code",
  },
  {
    label: "게재위치",
    value: "name",
  },
  {
    label: "게재위치 그룹",
    value: "placementGroup.name",
  },

  {
    label: "마지막 사용자",
    value: "lastUpdater",
  },
];

const filterData = [
  { label: "활성", value: "true", group: "상태", field: "enabled" },
  { label: "비활성", value: "false", group: "상태", field: "enabled" },
  { label: "PC", value: "PC", group: "스크린", field: "platform" },
  { label: "Mobile", value: "MOBILE", group: "스크린", field: "platform" },
  { label: "IPTV", value: "IPTV", group: "스크린", field: "platform" },
  { label: "Web", value: "WEB", group: "플랫폼", field: "application" },
  { label: "Application", value: "APP", group: "플랫폼", field: "application" },
  { label: "iOS", value: "IOS", group: "모바일OS", field: "platform" },
  { label: "Android", value: "ANDROID", group: "모바일OS", field: "platform" },
  {
    label: "제공",
    value: "PROVIDE",
    group: "네트워크 광고",
    field: "isUsingAdNetwork",
  },
  {
    label: "미제공",
    value: "NOT_PROVIDE",
    group: "네트워크 광고",
    field: "isUsingAdNetwork",
  },
];
