import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'lgsmart';
    src: url(${require("../assets/LGSmHaTB.ttf")}) format('truetype');
  }

  body {
    font-family: "Nanum Gothic", verdana, Apple Gothic, Malgun Gothic, dotum, serif;
    font-size: 12px;
    color: var(--rs-text-heading);
  }

  .rs-modal-footer {
    text-align: center;
  }

  //DateRangePicker
  .rs-picker-daterange-menu {
    .rs-picker-toolbar {
      padding: 0;
    }

    .rs-picker-toolbar-ranges {
      max-width: 510px;
      padding-top: 12px;
      padding-bottom: 12px;

      .rs-btn {
        width: 115px;
      }
    }

    .rs-picker-toolbar-right {
      float: none;
      text-align: center;
      padding: 12px 0;
      border-top: 1px solid var(--rs-border-primary);
    }
  }

  //DatePicker
  .rs-picker-date-menu {
    .rs-picker-toolbar-ranges {
      display: block;
    }

    .rs-picker-toolbar-right {
      float: none;
      text-align: center;
    }
  }

  //Modal
  .rs-modal-body {
    margin-top: 30px;
    padding-bottom: 30px;
  }

  //FontSizeTo12
  //Button
  .rs-btn, .rs-btn-sm, .rs-dropdown-menu, .rs-input-group-addon {
    font-size: 12px;
  }

  .rs-btn:not(.rs-btn-primary):not(.rs-btn-disabled) {
    color: var(--rs-text-heading);
  }

  //Input
  .rs-input {
    font-size: 12px !important;
    color: var(--rs-text-heading);
    
    &.error {
      border-color: var(--rs-form-errormessage-text);
    }
  }
  

  //Pagination
  .rs-pagination-btn {
    color: var(--rs-text-heading);
  }

  //Notification 
  .rs-notification-description {
    color: var(--rs-text-heading);
  }

  //Button
  .rs-btn-primary {
    font-weight: bold;
  }

  .rs-btn-default {
    background-color: #f2f2f5;
  }
`;

export { GlobalStyle };
