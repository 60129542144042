import {
  AccountRoleType,
  AdNetworkCodeType,
  AdReviewStatusType,
  CompanyType,
  CreativeType,
  MobileOSType,
  OriginPlatformType,
  PlacementAdNetworkCreativeType,
  PlatformType,
  ScreenType,
  SkipOptionType,
} from "./types";

export const SCREEN_TYPE: { [key in OriginPlatformType | ScreenType]: string } =
  {
    PC: "PC",
    ANDROID: "Mobile",
    IOS: "Mobile",
    MOBILE: "Mobile",
    IPTV: "IPTV",
  };

export const PLATFORM_TYPE: { [key in PlatformType]: string } = {
  WEB: "Web",
  APP: "Application",
};

export const MOBILE_OS_TYPE: { [key in MobileOSType]: string } = {
  ANDROID: "Android",
  IOS: "iOS",
};

export const CREATIVE_TYPE: { [key in CreativeType]: string } = {
  IMAGE: "이미지",
  VIDEO: "동영상",
  NATIVE: "네이티브",
};
export const PLACEMENT_AD_NETWORK_CREATIVE_TYPE: {
  [key in PlacementAdNetworkCreativeType]: string;
} = {
  ALL: "전체",
  IMAGE: "이미지",
  VIDEO: "동영상",
};
export const AD_NETWORK_CODE_TYPE: { [key in AdNetworkCodeType]: string } = {
  MEZZO: "메조미디어",
  EXELBID: "엑셀비드",
  DIGITALCAMP: "디지털캠프",
  CAULY: "카울리",
  UPLUSAD: "U+AD",
  PUBMATIC: "Pubmatic",
  REALCLICK: "리얼클릭",
  NASMEDIA: "나스미디어",
  PTBWA: "pTBWA",
};

export const AD_REVIEW_STATUS: { [key in AdReviewStatusType]: string } = {
  AUTO_APPROVED: "자동 승인",
  BLOCKED: "차단",
  WAITING_REVIEW: "검수 대기",
  APPROVED: "승인",
  DECLINED: "반려",
};

export const ACCOUNT_ROLE_TYPE: { [key in AccountRoleType]: string } = {
  MASTER: "마스터",
  ADMIN: "관리자",
  VIEWER: "뷰어",
};

export const COMPANY_TYPE: { [key in CompanyType]: string } = {
  SYSTEM_PROVIDER: "운영사",
  PUBLISHER: "매체사",
};

export const SKIP_OPTION: { [key in SkipOptionType]: string } = {
  UNAVAILABLE: "미사용",
  SKIPPABLE: "스킵 가능",
  NON_SKIPPABLE: "스킵 불가능",
};

export const regexpForEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regexpForPhoneNumber = /[0-9]{3,4}-[0-9]{3,4}-[0-9]{4}/;
