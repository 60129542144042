import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CellProps,
  Checkbox,
  Form,
  Radio,
  RadioGroup,
  Table as RsuiteTable,
} from "rsuite";
import { CreateItemTitleText, ErrorMessage } from "components/text";
import {
  CheckPicker,
  InfoTooltip,
  InputNumber,
  SelectPicker,
  Table,
  WarnTooltip,
} from "components";
import {
  CreateItemContainer,
  CreateItemTitle,
  CreateItemValue,
  FlexDiv,
} from "components/layout";
import { PlacementFormProps, PriorityGroup } from "./PlacementForm";
import {
  CreativeType,
  PlacementAdNetworkCreativeType,
  PlacementMediationType,
} from "../../../utils/types";
import { PLACEMENT_AD_NETWORK_CREATIVE_TYPE } from "../../../utils/variables";
import _ from "lodash";

const { Cell, Column, HeaderCell } = RsuiteTable;

const AdNetworkPriorityGroupControl = (
  props: AdNetworkPriorityGroupControlProps
) => {
  const {
    onChange,
    adNetworkUnits,
    formError,
    value,
    needToInit,
    setNeedToInit,
  } = props;

  const [priorityGroups, setPriorityGroups] =
    useState<AdNetworkPriorityGroupControlProps["value"]>(value);
  const [needToUpdateInputValue, setNeedToUpdateInputValue] = useState(false);
  const [mediationType, setMediationType] = useState<PlacementMediationType[]>(
    priorityGroups.map((pg) =>
      pg.priority_type === "RATIO" ? "RATIO" : "PRIORITY"
    )
  );
  const [adUnitSearchTextList, setAdUnitSearchTextList] = useState([""]);

  const prioritySelectData = useMemo(
    () => (index: number) =>
      Array.from(
        Array(priorityGroups[index].ad_network_unit_ids.length).keys()
      ).map((value) => ({ label: `${value + 1}`, value: `${value + 1}` })),
    [priorityGroups]
  );
  const handleChangeByIndex = useCallback(
    (rowIndex: number, value: PriorityGroup) => {
      const nextGroups: AdNetworkPriorityGroupControlProps["value"] =
        priorityGroups.map((g, i) => (i === rowIndex ? value : g));
      setPriorityGroups(nextGroups);
    },
    [priorityGroups]
  );

  const handleChangeCreativeType = useCallback(
    (index: number, value: PlacementAdNetworkCreativeType) => {
      setMediationType((prev) =>
        prev.map((v, i) => (i === index ? "RATIO" : v))
      );
      handleChangeByIndex(index, {
        creative_type: value,
        priority_type: "RATIO",
        ad_network_unit_ids: [],
        options: [],
      });
    },
    [handleChangeByIndex]
  );

  const handleChangeMediationType = useCallback(
    (index: number, value: PlacementMediationType) => {
      setMediationType((prev) => prev.map((v, i) => (i === index ? value : v)));
      handleChangeByIndex(index, {
        ...priorityGroups[index],
        priority_type: value === "PRIORITY" ? "USER_DEFINED" : "RATIO",
        options: priorityGroups[index].options.map((op) => ({
          ...op,
          priority: "",
          limit_impression: false,
          impressions: "",
          ratio: "",
        })),
      });
    },
    [handleChangeByIndex, priorityGroups]
  );

  const handleChangePriorityType = useCallback(
    (index: number, value: PriorityGroup["priority_type"]) => {
      handleChangeByIndex(index, {
        ...priorityGroups[index],
        priority_type: value,
        options:
          value === "USER_DEFINED"
            ? priorityGroups[index].options
            : priorityGroups[index].options.map((op) => ({
                ...op,
                priority: "",
              })),
      });
    },
    [handleChangeByIndex, priorityGroups]
  );

  const handleChangeAdNetworkUnitIds = useCallback(
    (index: number, value: PriorityGroup["ad_network_unit_ids"]) => {
      handleChangeByIndex(index, {
        ...priorityGroups[index],
        ad_network_unit_ids: value,
        options: value
          .sort(
            (a, b) =>
              adNetworkUnits.findIndex((u) => u.id === a) -
              adNetworkUnits.findIndex((u) => u.id === b)
          )
          .reduce((acc: PriorityGroup["options"], val: number) => {
            const option = priorityGroups[index].options.find(
              (op) => op.ad_network_unit_id === val
            );

            if (option)
              return [
                ...acc,
                {
                  ...option,
                  priority:
                    parseInt(option.priority || "") <= value.length
                      ? option.priority
                      : "",
                },
              ];
            else
              return [
                ...acc,
                {
                  ad_network_unit_id: val,
                  priority: "",
                  limit_impression: false,
                  impressions: "",
                  ratio: "",
                },
              ];
          }, []),
      });
      setNeedToUpdateInputValue(true);
    },
    [adNetworkUnits, handleChangeByIndex, priorityGroups]
  );

  const handleChangeUnitPriority = useCallback(
    (pgIndex: number, optionIndex: number, value: string | null) => {
      handleChangeByIndex(pgIndex, {
        ...priorityGroups[pgIndex],
        options: priorityGroups[pgIndex].options.map((op, i) =>
          i === optionIndex ? { ...op, priority: value } : op
        ),
      });
    },
    [handleChangeByIndex, priorityGroups]
  );

  const handleChangeUnitLimitImpression = useCallback(
    (pgIndex: number, optionIndex: number, checked: boolean) => {
      handleChangeByIndex(pgIndex, {
        ...priorityGroups[pgIndex],
        options: priorityGroups[pgIndex].options.map((op, i) =>
          i === optionIndex ? { ...op, limit_impression: checked } : op
        ),
      });
    },
    [handleChangeByIndex, priorityGroups]
  );

  const handleChangeUnitImpressions = useCallback(
    (pgIndex: number, optionIndex: number, value: string) => {
      handleChangeByIndex(pgIndex, {
        ...priorityGroups[pgIndex],
        options: priorityGroups[pgIndex].options.map((op, i) =>
          i === optionIndex ? { ...op, impressions: value } : op
        ),
      });
    },
    [handleChangeByIndex, priorityGroups]
  );
  const handleChangeUnitRatio = useCallback(
    (pgIndex: number, optionIndex: number, value: string) => {
      handleChangeByIndex(pgIndex, {
        ...priorityGroups[pgIndex],
        options: priorityGroups[pgIndex].options.map((op, i) =>
          i === optionIndex ? { ...op, ratio: value } : op
        ),
      });
    },
    [handleChangeByIndex, priorityGroups]
  );
  const getAdNetworkUnitsForCheckPicker = useCallback(
    (index: number) =>
      adNetworkUnits
        .filter((u) => {
          if (priorityGroups[index].creative_type === "ALL") {
            return true;
          }
          return u.type === priorityGroups[index].creative_type;
        })
        .map((u) => ({
          label: `${u.ad_network_name} (${u.ad_network_type}): ${u.name}`,
          value: u.id,
        })),
    [adNetworkUnits, priorityGroups]
  );
  const disabledAdNetworkUnits = useMemo(
    () => (index: number) =>
      adNetworkUnits
        .filter(
          (u) =>
            u.enabled == false &&
            !priorityGroups[index].ad_network_unit_ids.includes(u.id)
        )
        .map((u) => u.id),
    [adNetworkUnits, priorityGroups]
  );

  const handleCheckAdNetworkUnitIdsAll = useCallback(
    (index: number, checked: boolean) => {
      const units = getSearchFilteredList(
        getAdNetworkUnitsForCheckPicker(index),
        adUnitSearchTextList[index]
      ).map((item) => item.value);
      const disabled = disabledAdNetworkUnits(index);
      const newChecked = checked
        ? _.union(priorityGroups[index].ad_network_unit_ids, units).filter(
            (id) => !disabled.includes(id)
          )
        : _.pullAll(priorityGroups[index].ad_network_unit_ids, units);

      handleChangeAdNetworkUnitIds(index, newChecked);
    },
    [
      adUnitSearchTextList,
      disabledAdNetworkUnits,
      getAdNetworkUnitsForCheckPicker,
      handleChangeAdNetworkUnitIds,
      priorityGroups,
    ]
  );

  const columns = useMemo(
    // eslint-disable-next-line react/display-name
    () => (index: number) =>
      [
        {
          title: "네트워크",
          key: "ad_network_name",
          width: 200,
          flexGrow: 1,
          Cell: (props: CellProps) => {
            return (
              <Cell {...props}>
                {`${props.rowData["ad_network_name"]} (${props.rowData["ad_network_type"]})`}
                {props.rowData.enabled === false && (
                  <WarnTooltip
                    inner={"비활성된 네트워크입니다"}
                    trigger={"hover"}
                  />
                )}
              </Cell>
            );
          },
        },
        {
          title: "네트워크 유닛",
          key: "name",
          width: 200,
          flexGrow: 1,
        },
        {
          title: "우선순위",
          key: "priority",
          width: 100,
          Cell: (cellProps: CellProps) => {
            const priorityType = priorityGroups[index].priority_type;
            const rowIndex = cellProps.rowIndex || 0;
            const { rowData } = cellProps;

            return (
              <Cell
                {...cellProps}
                style={
                  priorityType !== "USER_DEFINED"
                    ? undefined
                    : { padding: "8px 10px" }
                }
              >
                {priorityType !== "USER_DEFINED" ? (
                  "자동"
                ) : (
                  <>
                    <SelectPicker
                      style={{
                        width: "100%",
                        position: "relative",
                      }}
                      className={
                        formError.priority_groups?.array[index].object.options
                          .array[rowIndex]?.object.priority.hasError
                          ? "error"
                          : undefined
                      }
                      data={prioritySelectData(index)}
                      size={"sm"}
                      placeholder={"선택"}
                      value={priorityGroups[index].options[rowIndex]?.priority}
                      onChange={(value: string | null) =>
                        handleChangeUnitPriority(index, rowIndex, value)
                      }
                      disabledItemValues={priorityGroups[index].options
                        .filter(
                          (op) => op.priority !== null && op.priority !== ""
                        )
                        .map((op) => op.priority as string)}
                      disabled={rowData.enabled === false}
                    />
                  </>
                )}
              </Cell>
            );
          },
        },
      ].filter((column) =>
        mediationType[index] === "RATIO"
          ? column.key === "ad_network_name" || column.key === "name"
          : true
      ),
    [
      priorityGroups,
      formError.priority_groups?.array,
      prioritySelectData,
      handleChangeUnitPriority,
      mediationType,
    ]
  );

  const tableData = useMemo(
    () => (index: number) => {
      return adNetworkUnits.filter((u) =>
        priorityGroups[index].ad_network_unit_ids.includes(u.id)
      );
    },
    [priorityGroups, adNetworkUnits]
  );

  useEffect(() => {
    // 초기화 버튼
    if (needToInit) {
      setPriorityGroups((priorityGroups) => {
        return priorityGroups.map(() => ({
          creative_type: "ALL",
          priority_type: "RATIO",
          ad_network_unit_ids: [],
          options: [],
        }));
      });
      setNeedToInit(false);
      setNeedToUpdateInputValue(true);
      setMediationType(priorityGroups.map(() => "RATIO"));
    }
  }, [adNetworkUnits, needToInit, priorityGroups, setNeedToInit]);

  useEffect(() => {
    onChange(priorityGroups);
    // onChange 추가시 무한루프
    // eslint-disable-next-line
  }, [priorityGroups]);

  useEffect(() => {
    setPriorityGroups(value);
  }, [value]);

  return priorityGroups.map((rowValue, index) => {
    const optionErrors: {
      hasError: boolean;
      object: {
        priority: { hasError: boolean; errorMessage: string };
        ratio: { hasError: boolean; errorMessage: string };
      };
    }[] = formError.priority_groups?.array[index].object.options.array;

    return (
      <div key={index}>
        <CreateItemContainer style={{ padding: 0, marginBottom: 24 }}>
          <CreateItemTitle>
            <CreateItemTitleText style={{ fontSize: "12px" }}>
              소재 타입 선택
            </CreateItemTitleText>
          </CreateItemTitle>
          <CreateItemValue>
            <SelectPicker
              data={Object.keys(PLACEMENT_AD_NETWORK_CREATIVE_TYPE).map(
                (key) => ({
                  label:
                    PLACEMENT_AD_NETWORK_CREATIVE_TYPE[
                      key as PlacementAdNetworkCreativeType
                    ],
                  value: key,
                })
              )}
              cleanable={false}
              value={rowValue.creative_type}
              onChange={(value) => handleChangeCreativeType(index, value)}
            />
          </CreateItemValue>
        </CreateItemContainer>
        <CreateItemContainer style={{ padding: 0, marginBottom: 24 }}>
          <CreateItemTitle>
            <CreateItemTitleText style={{ fontSize: "12px" }}>
              네트워크 유닛 선택
            </CreateItemTitleText>
          </CreateItemTitle>
          <CreateItemValue style={{ position: "relative" }}>
            <CheckPicker
              data={getAdNetworkUnitsForCheckPicker(index)}
              value={rowValue.ad_network_unit_ids}
              style={{ width: 500 }}
              onChange={(value: PriorityGroup["ad_network_unit_ids"]) =>
                handleChangeAdNetworkUnitIds(index, value)
              }
              disabledItemValues={disabledAdNetworkUnits(index)}
              searchable={true}
              renderMenu={(menu: ReactNode) => {
                return (
                  <div>
                    <div
                      className="rs-check-item"
                      style={{
                        borderTop: `1px solid var(--rs-border-primary)`,
                        borderBottom: `1px solid var(--rs-border-primary)`,
                      }}
                    >
                      <Checkbox
                        indeterminate={
                          rowValue.ad_network_unit_ids.length > 0 &&
                          rowValue.ad_network_unit_ids.length !==
                            getSearchFilteredList(
                              getAdNetworkUnitsForCheckPicker(index),
                              adUnitSearchTextList[index]
                            ).length
                        }
                        checked={
                          rowValue.ad_network_unit_ids.length > 0 &&
                          rowValue.ad_network_unit_ids.length ===
                            getSearchFilteredList(
                              getAdNetworkUnitsForCheckPicker(index),
                              adUnitSearchTextList[index]
                            ).length
                        }
                        onChange={(_, checked) => {
                          handleCheckAdNetworkUnitIdsAll(index, checked);
                        }}
                      >
                        전체
                      </Checkbox>
                    </div>
                    {menu}
                  </div>
                );
              }}
              onSearch={(searchText) => {
                setAdUnitSearchTextList((prev) =>
                  prev.map((s, i) => (i === index ? searchText : s))
                );
              }}
              onClose={() => {
                setAdUnitSearchTextList((prev) =>
                  prev.map((s, i) => (i === index ? "" : s))
                );
              }}
            />
            {formError.priority_groups?.array[index].object.ad_network_unit_ids
              .hasError && (
              <Form.ErrorMessage show placement={"bottomStart"}>
                {
                  formError.priority_groups?.array[index].object
                    .ad_network_unit_ids.errorMessage
                }
              </Form.ErrorMessage>
            )}
          </CreateItemValue>
        </CreateItemContainer>

        <CreateItemContainer style={{ padding: 0, marginBottom: 24 }}>
          <CreateItemTitle>
            <CreateItemTitleText style={{ fontSize: "12px" }}>
              Mediation 타입
            </CreateItemTitleText>
          </CreateItemTitle>
          <CreateItemValue>
            <RadioGroup
              inline
              value={mediationType[index]}
              onChange={(value) =>
                handleChangeMediationType(
                  index,
                  value as PlacementMediationType
                )
              }
            >
              <Radio value={"PRIORITY"}>우선순위</Radio>
              <Radio value={"RATIO"}>비중</Radio>
            </RadioGroup>
          </CreateItemValue>
        </CreateItemContainer>

        {mediationType[index] === "PRIORITY" && (
          <CreateItemContainer style={{ padding: 0, marginBottom: 24 }}>
            <CreateItemTitle>
              <CreateItemTitleText style={{ fontSize: "12px" }}>
                우선순위 기준 선택
              </CreateItemTitleText>
            </CreateItemTitle>
            <CreateItemValue>
              <SelectPicker
                data={[
                  { label: "사용자 지정", value: "USER_DEFINED" },
                  { label: "eCPM", value: "ECPM" },
                  { label: "Revenue", value: "REVENUE" },
                ]}
                cleanable={false}
                value={rowValue.priority_type}
                onChange={(value: PriorityGroup["priority_type"]) =>
                  handleChangePriorityType(index, value)
                }
              />
            </CreateItemValue>
          </CreateItemContainer>
        )}

        <CreateItemContainer style={{ padding: 0 }}>
          <CreateItemTitle>
            <CreateItemTitleText style={{ fontSize: "12px" }}>
              {mediationType[index] === "PRIORITY" ? "우선순위" : "비중"} 설정
            </CreateItemTitleText>
          </CreateItemTitle>
          <CreateItemValue>
            <Table
              data={tableData(index)}
              columns={columns(index)}
              rowClassName={(rowData) => {
                return rowData?.enabled === false ? "error" : "";
              }}
            >
              {mediationType[index] === "PRIORITY" && (
                <Column width={300} flexGrow={1}>
                  <HeaderCell>
                    <>
                      일 최대 노출수
                      <InfoTooltip
                        inner={"입력값을 일부 초과할 수 있습니다."}
                      />
                    </>
                  </HeaderCell>
                  <LimitCell
                    options={priorityGroups[index].options}
                    onLimitImpressionChange={(rowIndex, checked) =>
                      handleChangeUnitLimitImpression(index, rowIndex, checked)
                    }
                    onImpressionsChange={(rowIndex, value) =>
                      handleChangeUnitImpressions(index, rowIndex, value)
                    }
                    needToUpdate={needToUpdateInputValue}
                    setNeedToUpdate={setNeedToUpdateInputValue}
                  />
                </Column>
              )}
              {mediationType[index] === "RATIO" && (
                <Column width={200}>
                  <HeaderCell>일 요청 비중</HeaderCell>
                  <RatioCell
                    options={priorityGroups[index].options}
                    onRatioChange={(rowIndex, value) =>
                      handleChangeUnitRatio(index, rowIndex, value)
                    }
                    errors={optionErrors}
                    needToUpdate={needToUpdateInputValue}
                    setNeedToUpdate={setNeedToUpdateInputValue}
                  />
                </Column>
              )}
            </Table>
            {mediationType[index] === "RATIO" && (
              <div
                style={{
                  display: "flex",
                  border: "1px solid var(--rs-border-primary)",
                  borderTop: "none",
                }}
              >
                <div
                  style={{
                    padding: "13px 10px 13px 25px",
                    flexGrow: 1,
                    width: 200,
                  }}
                >
                  일 요청 비중 합계
                </div>
                <div
                  style={{ padding: "13px 10px", flexGrow: 1, width: 200 }}
                ></div>
                <div style={{ padding: "9px 10px", width: 200 }}>
                  <InputNumber
                    size={"sm"}
                    style={{
                      textAlign: "left",
                      color: "var(--rs-text-heading)",
                    }}
                    value={priorityGroups[index].options
                      .reduce((acc, val) => {
                        return acc + parseInt(val.ratio || "0");
                      }, 0)
                      .toString()}
                    disabled
                    updateToPropValue
                  />
                </div>
              </div>
            )}
            {optionErrors?.some((a) => a.hasError) && (
              <div style={{ marginTop: 10 }}>
                <ErrorMessage>
                  {optionErrors?.find((a) => a.hasError)?.object.priority
                    .errorMessage ||
                    optionErrors?.find((a) => a.hasError)?.object.ratio
                      .errorMessage}
                </ErrorMessage>
              </div>
            )}
          </CreateItemValue>
        </CreateItemContainer>
      </div>
    );
  });
};

export default AdNetworkPriorityGroupControl;

const getSearchFilteredList = (
  originList: { label: string; value: number }[],
  searchText: string
) =>
  originList.filter((item) =>
    item.label.toLowerCase().includes(searchText.toLowerCase())
  );

export type AdNetworkPriorityGroupControlProps = {
  value: PlacementFormProps["formValue"]["priority_groups"];
  onChange: (value: PlacementFormProps["formValue"]["priority_groups"]) => void;
  adNetworkUnits: {
    id: number;
    enabled?: boolean;
    ad_network_code: string;
    ad_network_type: "SDK" | "API";
    ad_network_name: string;
    name: string;
    type: CreativeType;
  }[];
  formError: any;
  needToInit: boolean;
  setNeedToInit: (v: boolean) => void;
};

const LimitCell = ({
  rowIndex = 0,
  rowData,
  options,
  onLimitImpressionChange,
  onImpressionsChange,
  needToUpdate,
  setNeedToUpdate,
  ...props
}: LimitCellProps) => {
  const option = options.find((o) => o.ad_network_unit_id === rowData.id);
  return (
    <Cell style={{ padding: "5px 10px" }} {...props}>
      <FlexDiv style={{ alignItems: "center" }}>
        <Checkbox
          inline
          checked={option?.limit_impression}
          onChange={(_, checked: boolean) =>
            onLimitImpressionChange(rowIndex, checked)
          }
          style={{ marginLeft: 0 }}
          disabled={rowData.enabled === false}
        >
          설정
        </Checkbox>
        <InputNumber
          size={"sm"}
          style={{
            display: "inline-flex",
            width: "100%",
            marginLeft: 10,
          }}
          value={option?.impressions}
          disabled={!option?.limit_impression || rowData.enabled === false}
          onChange={(value: string) => onImpressionsChange(rowIndex, value)}
          updateToPropValue={needToUpdate}
          setUpdateToPropValue={setNeedToUpdate}
        />
      </FlexDiv>
    </Cell>
  );
};

const RatioCell = ({
  rowIndex = 0,
  rowData,
  options,
  onRatioChange,
  errors,
  needToUpdate,
  setNeedToUpdate,
  ...props
}: RatioCellProps) => {
  const option = options.find((o) => o.ad_network_unit_id === rowData.id);
  return (
    <Cell style={{ padding: "9px 10px" }} {...props}>
      <FlexDiv style={{ alignItems: "center" }}>
        <InputNumber
          size={"sm"}
          style={{
            textAlign: "left",
          }}
          value={option?.ratio}
          min={0}
          max={100}
          onChange={(value) => onRatioChange(rowIndex, value as string)}
          className={
            errors?.[rowIndex]?.object.ratio.hasError ? "error" : undefined
          }
          updateToPropValue={needToUpdate}
          setUpdateToPropValue={setNeedToUpdate}
        />
      </FlexDiv>
    </Cell>
  );
};

interface LimitCellProps extends CellProps {
  options: PriorityGroup["options"];
  onLimitImpressionChange: (rowIndex: number, checked: boolean) => void;
  onImpressionsChange: (rowIndex: number, value: string) => void;
  needToUpdate: boolean;
  setNeedToUpdate: (v: boolean) => void;
}

interface RatioCellProps extends CellProps {
  options: PriorityGroup["options"];
  onRatioChange: (rowIndex: number, value: string) => void;
  errors?: any[];
  needToUpdate: boolean;
  setNeedToUpdate: (v: boolean) => void;
}
