import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Form,
  InputCount,
  SelectPicker,
  TextAreaCount,
  WarnTooltip,
} from "components";
import { CreateItemTitleText } from "components/text";
import { Divider, Schema, TagPicker } from "rsuite";
import {
  CreateItemContainer,
  CreateItemTitle,
  CreateItemValue,
} from "../../../components/layout";
import { AuthContext } from "../../../utils/context/AuthContext";
import {
  AccountService,
  CompanyService,
  PlacementGroupService,
} from "../../../utils/api";
import { Link } from "react-router-dom";
import { ItemDataType } from "rsuite/cjs/@types/common";

const PlacementGroupForm = (props: PlacementGroupFormProps) => {
  const { onSubmit, formRef, mode, placementCount, originName } = props;
  const { myInfo } = useContext(AuthContext);
  const [publisherName, setPublisherName] = useState("");
  const [viewers, setViewers] = useState<{ label: string; value: number }[]>(
    []
  );
  const fetchPublisherName = useCallback(async () => {
    if (!props.publisher) return;
    const { data } = await new CompanyService().getDetail(props.publisher);
    setPublisherName(data.name);
  }, [props.publisher]);
  const [enabledViewers, setEnabledViewers] = useState<
    { id: number; login_id: string; name: string }[]
  >([]);
  const [disabledViewers, setDisabledViewers] = useState<
    { id: number; login_id: string; name: string }[]
  >([]);

  const fetchViewers = useCallback(async () => {
    if (!props.publisher) return;
    const { data: enable } = await new AccountService().getIdNameMeta({
      filter: `company.id : ${props.publisher} and enabled : true  and role : 'VIEWER'`,
    });
    const { data: disable } = await new AccountService().getIdNameMeta({
      filter: `company.id : ${props.publisher} and enabled : false and role : 'VIEWER'`,
    });

    setEnabledViewers(enable);
    setDisabledViewers(disable);
  }, [props.publisher]);

  useEffect(() => {
    if (props.formValue.account_ids)
      setViewers(
        enabledViewers
          .concat(
            disabledViewers.filter((d) =>
              props.formValue.account_ids.includes(d.id)
            )
          )
          .map((d) => ({
            label: `${d.name}(${d.login_id})`,
            value: d.id,
          }))
      );
  }, [enabledViewers, disabledViewers, props.formValue.account_ids]);

  const model = useMemo(
    () =>
      Schema.Model({
        name: Schema.Types.StringType()
          .isRequired("게재위치 그룹명을 입력하세요.")
          .rangeLength(2, 60, "게재위치 그룹명은  2~60자로 입력하세요.")
          .pattern(
            /^[ㄱ-ㅎ|가-힣ㅏ-ㅣa-zA-Z0-9_\s]*$/,
            "영문(대소), 한글, 숫자, 언더바(_), 띄어쓰기 사용 가능합니다."
          )
          // @ts-ignore
          .addRule((value) => {
            return checkExistGroupName(
              props.publisher,
              value,
              props.formValue.id
            );
          }, "동일한 그룹명을 사용할 수 없습니다."),
        representative_address:
          Schema.Types.StringType().isRequired("대표 주소를 선택하세요."),
        account_ids: Schema.Types.ArrayType()
          .of(Schema.Types.NumberType())
          .addRule(
            (value) => {
              return value?.every(
                (v) => !disabledViewers.map((d) => d.id).includes(v)
              );
            },
            "비활성된 사용자가 포함되어 있습니다.",
            true
          ),
      }),
    [props.publisher, props.formValue.id, disabledViewers]
  );

  useEffect(() => {
    fetchPublisherName();
  }, [fetchPublisherName]);

  useEffect(() => {
    fetchViewers();
  }, [fetchViewers]);

  return (
    <>
      {myInfo?.role === "MASTER" && (
        <CreateItemContainer style={{ paddingBottom: 0, marginBottom: -1 }}>
          <CreateItemTitle>
            <CreateItemTitleText>매체사</CreateItemTitleText>
          </CreateItemTitle>
          <CreateItemValue>{publisherName}</CreateItemValue>
        </CreateItemContainer>
      )}
      <Form
        layout="horizontal"
        model={model}
        formValue={props.formValue}
        onChange={props.onChange}
        onSubmit={onSubmit}
        ref={formRef}
      >
        <Form.Group controlId="name">
          <Form.ControlLabel>
            <CreateItemTitleText required>게재위치 그룹명</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="name"
            accepter={InputCount}
            maxLength={60}
            style={{ width: 500, display: "inline-block" }}
            checkAsync
          />
          <Form.HelpText>
            영문(대소), 한글, 숫자, 언더바(_), 띄어쓰기 사용 가능
          </Form.HelpText>
        </Form.Group>
        <Form.Group controlId={"representative_address"}>
          <Form.ControlLabel>
            <CreateItemTitleText required>대표 주소</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="representative_address"
            placeholder={"대표 주소 선택"}
            accepter={SelectPicker}
            data={[
              { label: "사이트 주소(PC)", value: "PC" },
              { label: "사이트 주소(Mobile)", value: "MOBILE" },
              { label: "App Store 주소(Android)", value: "ANDROID" },
              { label: "App Store 주소(iOS)", value: "IOS" },
            ]}
            searchable={false}
            style={{ width: 300 }}
          />
        </Form.Group>
        <Form.Group controlId="pc_address">
          <Form.ControlLabel>
            <CreateItemTitleText>사이트 주소(PC)</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="pc_address"
            accepter={InputCount}
            maxLength={512}
            style={{ width: 500 }}
          />
        </Form.Group>
        <Form.Group controlId="mobile_address">
          <Form.ControlLabel>
            <CreateItemTitleText>사이트 주소(Mobile)</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="mobile_address"
            accepter={InputCount}
            maxLength={512}
            style={{ width: 500 }}
          />
        </Form.Group>
        <Form.Group controlId="android_address">
          <Form.ControlLabel>
            <CreateItemTitleText>App Store 주소(Android)</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="android_address"
            accepter={InputCount}
            maxLength={512}
            style={{ width: 500 }}
          />
        </Form.Group>
        <Form.Group controlId="ios_address">
          <Form.ControlLabel>
            <CreateItemTitleText>App Store 주소(iOS)</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="ios_address"
            accepter={InputCount}
            maxLength={512}
            style={{ width: 500 }}
          />
        </Form.Group>
        <Divider />
        <Form.Group controlId="account_ids">
          <Form.ControlLabel>
            <CreateItemTitleText>담당자</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="account_ids"
            accepter={TagPicker}
            data={viewers}
            style={{ width: 500 }}
            placeholder={"사용자 입력"}
            renderMenuItem={(label: ReactNode, item: ItemDataType) => {
              return (
                <>
                  {label}
                  {disabledViewers
                    .map((d) => d.id)
                    .includes(item.value as number) && (
                    <WarnTooltip
                      inner={"비활성된 사용자입니다."}
                      trigger={"hover"}
                    />
                  )}
                </>
              );
            }}
          />
        </Form.Group>
        {mode === "EDIT" && (
          <CreateItemContainer style={{ padding: 0 }}>
            <CreateItemTitle>
              <CreateItemTitleText>게재위치 수</CreateItemTitleText>
            </CreateItemTitle>
            <CreateItemValue>
              <Link
                to={`/inventory/placements?selected-tab=placement&search-key=placementGroup.name&search-value=${originName}`}
                target={"_blank"}
              >
                {placementCount}
              </Link>
            </CreateItemValue>
          </CreateItemContainer>
        )}
        <Divider />
        <Form.Group controlId="memo">
          <Form.ControlLabel>
            <CreateItemTitleText>메모</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="memo"
            accepter={TextAreaCount}
            maxLength={1000}
            style={{ width: 500 }}
          />
        </Form.Group>

        <Divider />
        {props.renderFooter()}
      </Form>
    </>
  );
};

export default PlacementGroupForm;

export type PlacementGroupFormProps = {
  formValue: {
    id?: number;
    name: string;
    representative_address: null | "PC" | "MOBILE" | "ANDROID" | "IOS";
    pc_address: string;
    mobile_address: string;
    android_address: string;
    ios_address: string;
    account_ids: number[];
    memo: string;
    publisher_id?: number;
  };
  onChange: (formValue: PlacementGroupFormProps["formValue"]) => void;
  renderFooter: () => ReactElement;
  publisher?: number;
  onSubmit?: (validate: boolean) => Promise<void>;
  formRef: React.RefObject<HTMLFormElement>;
  placementCount?: number;
  mode: "EDIT" | "CREATE";
  originName?: string;
};

const checkExistGroupName = async (
  publisher: number | undefined,
  value: string,
  id?: number
) => {
  if (!publisher) return;
  const { data } = await new PlacementGroupService().existGroupName({
    "publisher-id": publisher,
    name: value,
    "placement-group-id": id,
  });

  return !data.result;
};
