import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PageHeader, TableActionsContainer } from "../../../components/layout";
import {
  DateRangePicker,
  MultiplePicker,
  Search,
  SelectPicker,
  Table,
} from "../../../components";
import {
  Button,
  CellProps,
  Divider,
  Stack,
  Table as RsuiteTable,
} from "rsuite";
import { ItemDataType } from "rsuite/cjs/@types/common";
import { DateRange } from "rsuite/cjs/DateRangePicker/types";
import moment from "moment";
import { AdNetworkCodeType, PeriodType, SortType } from "../../../utils/types";
import { InfoMessage } from "../../../components/text";
import {
  DateCell,
  NumberCell,
  TextCell,
} from "../../../components/table/Cells";
import { TableColumns } from "../../../components/table/Table";
import {
  AdNetworkService,
  CompanyService,
  ReportService,
} from "../../../utils/api";
import { AuthContext } from "../../../utils/context/AuthContext";
import { underToUpper } from "../../../utils";
import _ from "lodash";
import fileDownload from "js-file-download";
import { TableColumnInfo } from "../operation/OperationReport";

const { Cell } = RsuiteTable;

const ErrorReport = () => {
  const [date, setDate] = useState<DateRange | null>([
    moment().toDate(),
    moment().toDate(),
  ]);
  const [period, setPeriod] = useState<PeriodType>("DAILY");
  const [networkCodes, setNetworkCodes] = useState<AdNetworkCodeType[]>([]);
  const [networks, setNetworks] = useState<ItemDataType[]>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  } | null>(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [publishers, setPublishers] = useState<{ label: string; value: any }[]>(
    []
  );
  const { myInfo } = useContext(AuthContext);
  const [publisher, setPublisher] = useState<any>(
    myInfo?.company_type === "PUBLISHER" ? myInfo?.company_id : null
  );
  const [searchKey, setSearchKey] = useState<
    "ad-network-name" | "ad-network-unit-name"
  >("ad-network-unit-name");
  const [searchValue, setSearchValue] = useState<string>("");
  const [appliedSearchValue, setAppliedSearchValue] =
    useState<string>(searchValue);
  const [appliedParams, setAppliedParams] = useState({
    date,
    period,
    networkCodes,
  });

  const fetchPublishers = useCallback(async () => {
    if (myInfo?.role !== "MASTER") return;
    const { data } = await new CompanyService().getIdNameMeta({
      filter: "type : 'PUBLISHER'",
    });

    setPublishers(
      data.map((d: { id: number; name: string }) => ({
        label: d.name,
        value: d.id,
      }))
    );
  }, [myInfo?.role]);

  useEffect(() => {
    fetchPublishers();
  }, [fetchPublishers]);

  const fetchNetworks = useCallback(async () => {
    try {
      const { data } = await new AdNetworkService().getCodes();
      setNetworks(
        data.map((network: { value: AdNetworkCodeType; name: string }) => ({
          value: network.value,
          label: network.name,
        }))
      );
      setNetworkCodes(
        data.map(
          (data: { value: AdNetworkCodeType; name: string }) => data.value
        )
      );
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchReport = useCallback(async () => {
    try {
      setTableLoading(true);

      const { data } = await new ReportService().getError({
        page,
        size,
        sort: sort
          ? underToUpper(sort.sortColumn) + "," + sort.sortType
          : ["date,desc", "adNetworkName,asc", "adNetworkUnitName,asc"],
        "period-type": appliedParams.period,
        "start-date": moment(appliedParams.date?.[0]).format("YYYY-MM-DD"),
        "end-date": moment(appliedParams.date?.[1]).format("YYYY-MM-DD"),
        "company-id": publisher,
        "ad-network-codes": appliedParams.networkCodes,
        "ad-network-name":
          searchKey === "ad-network-name" ? appliedSearchValue : "",
        "ad-network-unit-name":
          searchKey === "ad-network-unit-name" ? appliedSearchValue : "",
      });

      const reportData = data.reports.content;
      if (reportData.length > 0) {
        reportData.unshift({
          type: "TOTAL",
          ...data.summary,
        });
      }
      setData(reportData);
      setTotal(data.reports.total_elements);
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }, [
    page,
    size,
    sort,
    appliedParams.period,
    appliedParams.date,
    appliedParams.networkCodes,
    publisher,
    searchKey,
    appliedSearchValue,
  ]);

  useEffect(() => {
    fetchNetworks();
  }, [fetchNetworks]);

  useEffect(() => {
    fetchReport();
  }, [fetchReport]);

  useEffect(() => {
    setPage(1);
  }, [size, appliedParams, publisher, appliedSearchValue]);

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  const columns: () => TableColumns[] = useMemo(
    () => () => {
      const columns: TableColumns[] = [
        {
          title: "일자",
          key: "date",
          sortable: true,
          width: 120,
          Cell: (props: CellProps) => {
            return props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : appliedParams.period === "TOTAL" ? (
              <TextCell {...props} text={"총합"} />
            ) : (
              <DateCell
                format={
                  appliedParams.period === "DAILY" ? "YYYY-MM-DD" : "YYYY-MM"
                }
                {...props}
              />
            );
          },
        },
        {
          title: "네트워크",
          key: "ad_network_name",
          sortable: true,
          width: 150,
          Cell: (props: CellProps) => {
            return props.rowData.type === "TOTAL" ? (
              <Cell {...props}>합계</Cell>
            ) : (
              <TextCell
                {...props}
                text={`${props.rowData.ad_network_name}(${props.rowData.ad_network_type})`}
              />
            );
          },
        },
        {
          title: "네트워크 유닛",
          key: "ad_network_unit_name",
          sortable: true,
          width: 300,
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : (
              <TextCell {...props} />
            ),
        },
        {
          title: (
            <>
              네트워크 요청수
              <TableColumnInfo
                inner={"광고서버가 네트워크에 광고를 요청한 횟수"}
              />
            </>
          ),
          key: "requests",
          sortable: true,
          width: 145,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },

        {
          title: (
            <>
              전달수
              <TableColumnInfo
                inner={"광고서버가 네트워크로부터 정상 광고를 전달 받은 횟수"}
              />
            </>
          ),
          key: "fills",
          sortable: true,
          width: 130,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              노출수
              <TableColumnInfo
                inner={"광고서버가 게재위치로부터 전달 받은 노출수"}
              />
            </>
          ),
          key: "impressions",
          sortable: true,
          width: 130,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              클릭수
              <TableColumnInfo
                inner={"광고 서버를 통해 게재위치에서 광고가 클릭된 횟수"}
              />
            </>
          ),
          key: "clicks",
          sortable: true,
          width: 130,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              NO-AD수
              <TableColumnInfo inner={"네트워크가 NO-AD를 응답한 횟수"} />
            </>
          ),
          key: "no_ads",
          sortable: true,
          width: 130,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              오류수
              <TableColumnInfo inner={"네트워크가 오류를 응답한 횟수"} />
            </>
          ),
          key: "errors",
          sortable: true,
          width: 130,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
        {
          title: (
            <>
              Time-out수
              <TableColumnInfo
                inner={
                  "광고 요청 후 네트워크의 응답시간이 300m/s을 초과한 횟수"
                }
              />
            </>
          ),
          key: "timeouts",
          sortable: true,
          width: 140,
          flexGrow: 1,
          Cell: NumberCell,
          align: "right",
        },
      ];

      if (myInfo?.role === "MASTER") {
        columns.unshift({
          title: "매체사",
          key: "company_name",
          sortable: true,
          width: 150,
          Cell: (props: CellProps) =>
            props.rowData.type === "TOTAL" ? (
              <Cell {...props} />
            ) : (
              <TextCell {...props} />
            ),
        } as TableColumns);
      }
      return columns;
    },
    [appliedParams.period, myInfo?.role]
  );

  const handleClickFetch = useCallback(() => {
    setAppliedParams({
      period,
      date,
      networkCodes,
    });
  }, [date, networkCodes, period]);

  const handleSearchKeyChange = useCallback(
    (v: "ad-network-name" | "ad-network-unit-name") => {
      setSearchKey(v);
      setSearchValue("");
    },
    []
  );

  const delayedSetSearchValue = useMemo<any>(
    () => _.debounce((v: string) => setAppliedSearchValue(v), 500),
    []
  );

  const handleSearchValueChange = useCallback((v: string) => {
    setSearchValue(v);
  }, []);

  useEffect(() => {
    delayedSetSearchValue(searchValue);
  }, [searchValue, delayedSetSearchValue]);

  const handleClickDownload = useCallback(async () => {
    try {
      const { data } = await new ReportService().downloadError({
        sort: sort
          ? underToUpper(sort.sortColumn) + "," + sort.sortType
          : ["date,desc", "adNetworkName,asc", "adNetworkUnitName,asc"],
        "period-type": appliedParams.period,
        "start-date": moment(appliedParams.date?.[0]).format("YYYY-MM-DD"),
        "end-date": moment(appliedParams.date?.[1]).format("YYYY-MM-DD"),
        "company-id": publisher,
        "ad-network-codes": appliedParams.networkCodes,
        "ad-network-name":
          searchKey === "ad-network-name" ? appliedSearchValue : "",
        "ad-network-unit-name":
          searchKey === "ad-network-unit-name" ? appliedSearchValue : "",
      });

      fileDownload(
        data,
        `오류보고서-${
          myInfo?.role !== "MASTER"
            ? myInfo?.company_name
            : publisher
            ? publishers.find((pub) => pub.value === publisher)?.label
            : "매체사전체"
        }-${moment(appliedParams.date?.[0]).format("YYYYMMDD")}-${moment(
          appliedParams.date?.[1]
        ).format("YYYYMMDD")}.xlsx`
      );
    } catch (e) {
      console.log(e);
    }
  }, [
    appliedParams.date,
    appliedParams.networkCodes,
    appliedParams.period,
    appliedSearchValue,
    myInfo?.company_name,
    myInfo?.role,
    publisher,
    publishers,
    searchKey,
    sort,
  ]);

  return (
    <>
      <PageHeader title={"오류 보고서"}>
        {myInfo?.role === "MASTER" && (
          <SelectPicker
            placeholder={"매체사 선택"}
            data={publishers}
            value={publisher}
            onChange={setPublisher}
            style={{ width: 200, marginLeft: 10 }}
          />
        )}
      </PageHeader>
      <Stack
        spacing={8}
        style={{
          padding: "9px 25px",
          borderBottom: "1px solid var(--rs-border-primary)",
        }}
      >
        <DateRangePicker value={date} onChange={setDate} />
        <SelectPicker
          data={periodData}
          value={period}
          onChange={setPeriod}
          cleanable={false}
          renderValue={(value: any, items: ItemDataType) => (
            <span>{`시간 : ${items.label}`}</span>
          )}
        />

        <MultiplePicker
          data={networks}
          label={"네트워크"}
          value={networkCodes}
          onValueChange={setNetworkCodes}
        />

        <Button appearance={"primary"} onClick={handleClickFetch}>
          조회
        </Button>
      </Stack>
      <TableActionsContainer>
        <Stack>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "var(--rs-gray-800)",
              height: "50px",
            }}
          >
            조회 결과
          </span>
          <Divider vertical />
          <span>총 {total}개</span>
          <InfoMessage style={{ marginLeft: 20 }}>
            * 데이터는 매 10분마다 업데이트 됩니다.
          </InfoMessage>
        </Stack>
        <Stack spacing={8}>
          <Search
            data={searchKeys}
            searchKey={searchKey}
            onSearchKeyChange={handleSearchKeyChange}
            searchValue={searchValue}
            onSearchValueChange={handleSearchValueChange}
          />
          <Button onClick={handleClickDownload}>다운로드</Button>
        </Stack>
      </TableActionsContainer>
      <Table
        data={data}
        columns={columns()}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        sortColumn={sort?.sortColumn}
        sortType={sort?.sortType}
        onSortColumn={handleSortChange}
        rowClassName={(rowData: any) => {
          if (!rowData) return "";
          return rowData.type === "TOTAL" ? "total" : "";
        }}
        loading={tableLoading}
      />
    </>
  );
};

export default ErrorReport;

const periodData = [
  {
    label: "총합",
    value: "TOTAL",
  },
  {
    label: "일별",
    value: "DAILY",
  },
  {
    label: "월별",
    value: "MONTHLY",
  },
];

const searchKeys = [
  {
    label: "네트워크",
    value: "ad-network-name",
  },
  {
    label: "네트워크 유닛",
    value: "ad-network-unit-name",
  },
];
