import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AdNetworkCodeType,
  AdNetworkType,
  CreativeType,
  MobileOSType,
  PlatformType,
  ScreenType,
  SkipOptionType,
} from "../../../utils/types";
import { Divider, List, Radio, RadioGroup, Schema } from "rsuite";
import {
  CreateItemContainer,
  CreateItemTitle,
  CreateItemValue,
} from "../../../components/layout";
import { CreateItemTitleText } from "../../../components/text";
import { AD_NETWORK_CODE_TYPE, SKIP_OPTION } from "../../../utils/variables";
import { Form, InfoTooltip, InputCount } from "../../../components";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../utils/context/AuthContext";
import { ValueType } from "rsuite/Radio";
import { AdNetworkUnitService } from "../../../utils/api";

const AdNetworkUnitForm = (props: AdNetworkUnitFormProps) => {
  const {
    editMode,
    formValue,
    onChange,
    adNetwork,
    placements,
    onSubmit,
    formRef,
  } = props;
  const { myInfo } = useContext(AuthContext);
  const [useSize, setUseSize] = useState<ValueType>("NOT_USE");

  const model = useMemo(
    () =>
      Schema.Model({
        name: Schema.Types.StringType()
          .isRequired("네트워크 유닛명을 입력하세요.")
          .rangeLength(2, 60, "네트워크 유닛명은  2~60자로 입력하세요.")
          .pattern(
            /^[ㄱ-ㅎ|가-힣ㅏ-ㅣa-zA-Z0-9_]*$/,
            "영문(대소), 한글, 숫자, 언더바(_) 사용 가능합니다."
          )
          .addRule(
            // @ts-ignore
            (value) => {
              return checkExistName(value, adNetwork?.id, formValue.id);
            },
            "동일한 네트워크 유닛명을 사용할 수 없습니다."
          ),
        width: Schema.Types.StringType().addRule(
          (value, data) => {
            return (
              value !== "" || (data.type === "VIDEO" && useSize === "NOT_USE")
            );
          },
          "소재 사이즈를 입력하세요.",
          true
        ),
        height: Schema.Types.StringType().addRule(
          (value, data) => {
            return (
              value !== "" || (data.type === "VIDEO" && useSize === "NOT_USE")
            );
          },
          "소재 사이즈를 입력하세요.",
          true
        ),
        pub_key:
          adNetwork?.code === "MEZZO"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        media_id:
          adNetwork?.code === "MEZZO"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        section_id:
          adNetwork?.code === "MEZZO"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        app_id:
          adNetwork?.code === "EXELBID"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        unit_id:
          adNetwork?.code === "EXELBID" ||
          adNetwork?.code === "CAULY" ||
          adNetwork?.code === "UPLUSAD"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        mc:
          adNetwork?.code === "DIGITALCAMP"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        site_id:
          adNetwork?.code === "PUBMATIC"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        ad_id:
          adNetwork?.code === "PUBMATIC"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        store_url:
          adNetwork?.code === "PUBMATIC"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        bundle:
          adNetwork?.code === "PUBMATIC" || adNetwork?.code === "PTBWA"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        mcode:
          adNetwork?.code === "REALCLICK"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        pkgname:
          adNetwork?.code === "REALCLICK"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        media_key:
          adNetwork?.code === "NASMEDIA"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        adunit_id:
          adNetwork?.code === "NASMEDIA"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        pack_name:
          adNetwork?.code === "NASMEDIA"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        tag:
          adNetwork?.code === "PTBWA"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        bid_floor:
          adNetwork?.code === "PTBWA"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        app_name:
          adNetwork?.code === "PTBWA"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
        ifa_type:
          adNetwork?.code === "PTBWA"
            ? Schema.Types.StringType().isRequired("식별값을 입력하세요.")
            : Schema.Types.StringType(),
      }),
    [adNetwork?.code, adNetwork?.id, formValue.id, useSize]
  );

  const handleFormChange = useCallback(
    (formValue: any) => {
      const originFormValue = props.formValue;
      const regexpForSize = /^[\d|\s]*$/;

      if (
        !regexpForSize.test(formValue.width) ||
        !regexpForSize.test(formValue.height)
      )
        return;
      let tempFormValue = { ...formValue };
      if (formValue.screen === "PC" || formValue.screen === "IPTV")
        tempFormValue = { ...tempFormValue, mobile_os: null };
      if (formValue.screen === "MOBILE" && tempFormValue.mobile_os === null)
        tempFormValue = { ...tempFormValue, mobile_os: "IOS" };
      if (formValue.screen === "IPTV")
        tempFormValue = { ...tempFormValue, platform: "APP" };
      if (formValue.type !== originFormValue.type) {
        if (formValue.type === "IMAGE") {
          setUseSize("NOT_USE");
          tempFormValue = { ...tempFormValue, skip_option: "UNAVAILABLE" };
        }
        tempFormValue = {
          ...tempFormValue,
          width: "",
          height: "",
          skip_option: "SKIPPABLE",
        };
      }

      onChange(tempFormValue);
    },
    [onChange, props.formValue]
  );

  useEffect(() => {
    if (
      formValue.type === "VIDEO" &&
      formValue.width !== "" &&
      useSize === "NOT_USE"
    ) {
      setUseSize("USE");
    }
  }, [formValue, useSize]);

  return (
    <>
      <Form
        layout="horizontal"
        model={model}
        formValue={formValue}
        onChange={handleFormChange}
        onSubmit={onSubmit}
        ref={formRef}
      >
        {myInfo?.role === "MASTER" && (
          <CreateItemContainer style={{ padding: 0, marginBottom: 24 }}>
            <CreateItemTitle style={{ marginBottom: 4 }}>
              <CreateItemTitleText>매체사</CreateItemTitleText>
            </CreateItemTitle>
            <CreateItemValue>{adNetwork?.publisher.name}</CreateItemValue>
          </CreateItemContainer>
        )}
        <CreateItemContainer style={{ padding: 0, marginBottom: 24 }}>
          <CreateItemTitle style={{ marginBottom: 4 }}>
            <CreateItemTitleText>네트워크</CreateItemTitleText>
          </CreateItemTitle>
          <CreateItemValue>{`${
            AD_NETWORK_CODE_TYPE[adNetwork?.code as AdNetworkCodeType]
          }(${adNetwork?.type})`}</CreateItemValue>
        </CreateItemContainer>
        <Form.Group controlId="name">
          <Form.ControlLabel>
            <CreateItemTitleText required>네트워크 유닛명</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="name"
            accepter={InputCount}
            maxLength={60}
            style={{ width: 500, display: "inline-block" }}
            checkAsync
          />
          <Form.HelpText>
            영문(대소), 한글, 숫자, 언더바(_) 사용 가능
          </Form.HelpText>
        </Form.Group>
        <Form.Group controlId="screen">
          <Form.ControlLabel>
            <CreateItemTitleText required>스크린</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="screen"
            accepter={RadioGroup}
            inline
            plaintext={editMode}
          >
            <Radio value={"PC"}>PC</Radio>
            <Radio value={"MOBILE"}>Mobile</Radio>
            <Radio value={"IPTV"}>IPTV</Radio>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="platform">
          <Form.ControlLabel>
            <CreateItemTitleText required>플랫폼</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="platform"
            accepter={RadioGroup}
            inline
            plaintext={editMode}
          >
            <Radio value={"WEB"} disabled={formValue.screen === "IPTV"}>
              Web
            </Radio>
            <Radio value={"APP"}>Application</Radio>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="mobile_os">
          <Form.ControlLabel>
            <CreateItemTitleText required>모바일OS</CreateItemTitleText>
          </Form.ControlLabel>
          {!editMode || formValue.screen === "MOBILE" ? (
            <Form.Control
              name="mobile_os"
              accepter={RadioGroup}
              inline
              disabled={formValue.screen !== "MOBILE"}
              plaintext={editMode}
            >
              <Radio value={"IOS"}>iOS</Radio>
              <Radio value={"ANDROID"}>Android</Radio>
            </Form.Control>
          ) : (
            "-"
          )}
        </Form.Group>
        <Form.Group controlId="type">
          <Form.ControlLabel style={{ alignSelf: "flex-start" }}>
            <CreateItemTitleText required>소재 타입</CreateItemTitleText>
          </Form.ControlLabel>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Form.Control
              name="type"
              accepter={RadioGroup}
              inline
              plaintext={editMode}
            >
              <Radio value={"IMAGE"}>이미지</Radio>
              <Radio value={"VIDEO"}>
                동영상
                {editMode && ` (${SKIP_OPTION[formValue.skip_option]})`}
              </Radio>
            </Form.Control>
            {!editMode && formValue.type === "VIDEO" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 36,
                }}
              >
                <CreateItemTitleText
                  style={{ fontSize: "12px", marginRight: 20 }}
                >
                  스킵(Skip) 설정
                </CreateItemTitleText>
                <div style={{ display: "inline-block" }}>
                  <Form.Control name="skip_option" accepter={RadioGroup} inline>
                    <Radio value={"SKIPPABLE"}>
                      {SKIP_OPTION["SKIPPABLE"]}
                      <InfoTooltip
                        trigger={"hover"}
                        inner={
                          "네트워크에서 전달해주는 광고가 스킵 가능한 광고일 경우, 사용자에게 스킵 버튼이 노출됩니다."
                        }
                      />
                    </Radio>
                    <Radio value={"NON_SKIPPABLE"}>
                      {SKIP_OPTION["NON_SKIPPABLE"]}
                      <InfoTooltip
                        trigger={"hover"}
                        inner={
                          "네트워크에서 전달해주는 광고가 스킵 가능한 광고여도 사용자에게 스킵 버튼이 노출되지 않습니다."
                        }
                      />
                    </Radio>
                  </Form.Control>
                </div>
              </div>
            )}
          </div>
        </Form.Group>
        <Form.Group controlId="size">
          <Form.ControlLabel>
            <CreateItemTitleText required>소재 사이즈</CreateItemTitleText>
          </Form.ControlLabel>
          {!editMode && formValue.type === "VIDEO" && (
            <RadioGroup
              inline
              style={{ marginRight: 20 }}
              value={useSize}
              onChange={(value) => {
                setUseSize(value);
                handleFormChange({ ...formValue, width: "", height: "" });
              }}
            >
              <Radio value={"NOT_USE"}>미지정</Radio>
              <Radio value={"USE"}>지정</Radio>
            </RadioGroup>
          )}
          {editMode &&
            formValue.type === "VIDEO" &&
            useSize === "NOT_USE" &&
            "미지정"}
          {(formValue.type === "IMAGE" || useSize === "USE") && (
            <>
              <Form.Control
                name="width"
                accepter={InputCount}
                maxLength={4}
                plaintext={editMode}
              />
              <span style={{ margin: "0 8px" }}>x</span>
              <Form.Control
                name="height"
                accepter={InputCount}
                maxLength={4}
                plaintext={editMode}
              />
              {!editMode && <Form.HelpText>숫자 입력</Form.HelpText>}
            </>
          )}
        </Form.Group>
        <Divider />
        {adNetwork?.code === "MEZZO" && (
          <>
            <Form.Group controlId="pub_key">
              <Form.ControlLabel>
                <CreateItemTitleText required>publisher id</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="pub_key"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="media_id">
              <Form.ControlLabel>
                <CreateItemTitleText required>media id</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="media_id"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="section_id">
              <Form.ControlLabel>
                <CreateItemTitleText required>section_id</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="section_id"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
          </>
        )}
        {adNetwork?.code === "EXELBID" && (
          <>
            <Form.Group controlId="app_id">
              <Form.ControlLabel>
                <CreateItemTitleText required>bundle</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="app_id"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="unit_id">
              <Form.ControlLabel>
                <CreateItemTitleText required>unit id</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="unit_id"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
          </>
        )}
        {adNetwork?.code === "DIGITALCAMP" && (
          <>
            <Form.Group controlId="mc">
              <Form.ControlLabel>
                <CreateItemTitleText required>mc</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="mc"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
          </>
        )}
        {adNetwork?.code === "CAULY" && (
          <>
            <Form.Group controlId="unit_id">
              <Form.ControlLabel>
                <CreateItemTitleText required>unit id</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="unit_id"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
          </>
        )}
        {adNetwork?.code === "UPLUSAD" && (
          <>
            <Form.Group controlId="unit_id">
              <Form.ControlLabel>
                <CreateItemTitleText required>unit id</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="unit_id"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
          </>
        )}
        {adNetwork?.code === "PUBMATIC" && (
          <>
            <Form.Group controlId="site_id">
              <Form.ControlLabel>
                <CreateItemTitleText required>site id</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="site_id"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="ad_id">
              <Form.ControlLabel>
                <CreateItemTitleText required>ad id</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="ad_id"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="store_url">
              <Form.ControlLabel>
                <CreateItemTitleText required>storeurl</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="store_url"
                accepter={InputCount}
                maxLength={128}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="bundle">
              <Form.ControlLabel>
                <CreateItemTitleText required>bundle</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="bundle"
                accepter={InputCount}
                maxLength={128}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
          </>
        )}
        {adNetwork?.code === "REALCLICK" && (
          <>
            <Form.Group controlId="mcode">
              <Form.ControlLabel>
                <CreateItemTitleText required>mcode</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="mcode"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="pkgname">
              <Form.ControlLabel>
                <CreateItemTitleText required>pkgname</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="pkgname"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
          </>
        )}
        {adNetwork?.code === "NASMEDIA" && (
          <>
            <Form.Group controlId="media_key">
              <Form.ControlLabel>
                <CreateItemTitleText required>media key</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="media_key"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="adunit_id">
              <Form.ControlLabel>
                <CreateItemTitleText required>adunit id</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="adunit_id"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="pack_name">
              <Form.ControlLabel>
                <CreateItemTitleText required>pack name</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="pack_name"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
          </>
        )}
        {adNetwork?.code === "PTBWA" && (
          <>
            <Form.Group controlId="tag">
              <Form.ControlLabel>
                <CreateItemTitleText required>tag</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="tag"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="bid_floor">
              <Form.ControlLabel>
                <CreateItemTitleText required>bid floor</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="bid_floor"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="bundle">
              <Form.ControlLabel>
                <CreateItemTitleText required>bundle</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="bundle"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="app_name">
              <Form.ControlLabel>
                <CreateItemTitleText required>app name</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="app_name"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
            <Form.Group controlId="ifa_type">
              <Form.ControlLabel>
                <CreateItemTitleText required>ifa type</CreateItemTitleText>
              </Form.ControlLabel>
              <Form.Control
                name="ifa_type"
                accepter={InputCount}
                maxLength={60}
                style={{ width: 500, display: "inline-block" }}
                plaintext={editMode}
              />
            </Form.Group>
          </>
        )}
        {editMode && (
          <>
            <Divider />
            <CreateItemContainer style={{ padding: 0 }}>
              <CreateItemTitle>
                <CreateItemTitleText>게재위치</CreateItemTitleText>
              </CreateItemTitle>
              <CreateItemValue>
                {
                  <List bordered style={{ maxHeight: 200 }}>
                    {placements?.map((item: any, index) => (
                      <List.Item key={index} index={index}>
                        <Link
                          to={`/inventory/placement/edit/${item.id}`}
                          target={"_blank"}
                        >
                          {item.name}
                        </Link>
                      </List.Item>
                    ))}
                    {placements?.length === 0 && (
                      <div
                        style={{
                          height: 200,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        데이터가 없습니다.
                      </div>
                    )}
                  </List>
                }
              </CreateItemValue>
            </CreateItemContainer>
          </>
        )}
        <Divider />
        {props.renderFooter?.()}
      </Form>
    </>
  );
};

export default AdNetworkUnitForm;

export type AdNetworkUnitFormProps = {
  formValue: {
    id?: number;
    name: string;
    screen: ScreenType;
    platform: PlatformType;
    mobile_os: MobileOSType | null;
    width: string;
    height: string;
    type: CreativeType;
    skip_option: SkipOptionType;
    pub_key: string;
    media_id: string;
    section_id: string;
    app_id: string;
    unit_id: string;
    mc: string;
    site_id: string;
    ad_id: string;
    store_url: string;
    bundle: string;
    mcode: string;
    pkgname: string;
    media_key: string;
    adunit_id: string;
    pack_name: string;
    tag: string;
    bid_floor: string;
    app_name: string;
    ifa_type: string;
  };
  onChange: (formValue: AdNetworkUnitFormProps["formValue"]) => void;
  renderFooter?: () => ReactElement;
  editMode?: boolean;
  adNetwork?: {
    id: number;
    enabled: boolean;
    code: AdNetworkCodeType;
    type: AdNetworkType;
    publisher: {
      id: number;
      name: string;
    };
  };
  placements?: any[];
  onSubmit?: (validate: boolean) => Promise<void>;
  formRef: React.RefObject<HTMLFormElement>;
};

const checkExistName = async (
  value: string,
  adNetworkId?: number,
  adNetworkUnitId?: number
) => {
  if (!adNetworkId) return true;
  const { data } = await new AdNetworkUnitService().existName({
    name: value,
    "ad-network-id": adNetworkId,
    "ad-network-unit-id": adNetworkUnitId,
  });

  return !data.result;
};
