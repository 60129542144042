import React, { useCallback, useEffect, useMemo, useState } from "react";
import { PageHeader, TableActionsContainer } from "../../../components/layout";
import { Button, CellProps } from "rsuite";
import { Search, Table } from "../../../components";
import { DateCell, LinkCell } from "../../../components/table/Cells";
import { SortType } from "../../../utils/types";
import { Link } from "react-router-dom";
import { CompanyService } from "../../../utils/api";
import { underToUpper } from "../../../utils";
import { getComparatorsString } from "front-utils/filter/dynamicFilter";
import _ from "lodash";

const Companies = () => {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  }>({
    sortColumn: "created_at",
    sortType: "desc",
  });
  const [searchKey, setSearchKey] = useState<
    "name" | "businessRegistrationNumber"
  >("name");
  const [searchValue, setSearchValue] = useState<string>("");
  const [appliedSearchValue, setAppliedSearchValue] =
    useState<string>(searchValue);
  const [tableLoading, setTableLoading] = useState(false);

  const columns = useMemo(
    () => [
      {
        title: "회사명",
        key: "name",
        sortable: true,
        width: 200,
        flexGrow: 1,
        Cell: (props: CellProps) => (
          <LinkCell
            to={`/account/company/edit/${props.rowData.id}`}
            {...props}
          />
        ),
      },
      {
        title: "사업자등록번호",
        key: "business_registration_number",
        sortable: true,
        width: 100,
        flexGrow: 1,
      },

      {
        title: "생성일",
        key: "created_at",
        sortable: true,
        width: 150,
        flexGrow: 1,
        Cell: DateCell,
      },
      {
        title: "마지막 수정일",
        key: "updated_at",
        sortable: true,
        width: 150,
        flexGrow: 1,
        Cell: DateCell,
      },
    ],
    []
  );

  const fetchData = useCallback(async () => {
    try {
      setTableLoading(true);
      const { data } = await new CompanyService().get({
        page,
        size,
        sort: underToUpper(sort.sortColumn) + "," + sort.sortType,
        filter:
          appliedSearchValue !== ""
            ? getComparatorsString("~", searchKey, `%${appliedSearchValue}%`)
            : "",
      });
      setData(data.content);
      setTotal(data.total_elements);
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }, [
    searchKey,
    appliedSearchValue,
    page,
    size,
    sort.sortColumn,
    sort.sortType,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPage(1);
  }, [size, appliedSearchValue]);

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  const handleSearchKeyChange = useCallback(
    (v: "name" | "businessRegistrationNumber") => {
      setSearchKey(v);
      setSearchValue("");
    },
    []
  );

  const delayedSetSearchValue = useMemo<any>(
    () => _.debounce((v: string) => setAppliedSearchValue(v), 500),
    []
  );

  const handleSearchValueChange = useCallback((v: string) => {
    setSearchValue(v);
  }, []);

  useEffect(() => {
    delayedSetSearchValue(searchValue);
  }, [searchValue, delayedSetSearchValue]);

  return (
    <>
      <PageHeader title={"회사"} />
      <TableActionsContainer>
        <Link to={{ pathname: "/account/company/new" }}>
          <Button appearance={"primary"}>생성</Button>
        </Link>
        <Search
          data={[
            { label: "회사명", value: "name" },
            { label: "사업자등록번호", value: "businessRegistrationNumber" },
          ]}
          searchKey={searchKey}
          onSearchKeyChange={handleSearchKeyChange}
          searchValue={searchValue}
          onSearchValueChange={handleSearchValueChange}
        />
      </TableActionsContainer>
      <Table
        data={data}
        columns={columns}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
        onSortColumn={handleSortChange}
        loading={tableLoading}
      />
    </>
  );
};

export default Companies;
