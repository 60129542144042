import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Input, InputGroup, InputProps } from "rsuite";

interface InputCountProps extends InputProps {
  maxLength: number;
}

export const CurrentLengthText = styled.span`
  color: var(--rs-gray-800);
  font-size: 12px;
`;
export const TotalLengthText = styled.span`
  color: var(--rs-gray-500);
  font-size: 12px;
`;

export const StyledInputGroup = styled(InputGroup)`
  &.rs-input-group.rs-input-group-inside .rs-input-group-addon {
    padding: 11px 13px;
  }
`;
const StyledInput = styled(Input)`
  &.rs-input {
    ${({ maxLength }) => {
      return maxLength
        ? `padding-right:${maxLength.toString().length * 2}em !important`
        : "";
    }};
  }

  & ~ .rs-input-group-addon {
    border-left: none;
    left: auto;
    right: 0;
    top: auto !important;
    bottom: 0;
  }

  font-size: 12px;
`;

const InputCount = (props: InputCountProps) => {
  const [count, setCount] = useState(0);
  const { plaintext } = props;
  const propsValue = useMemo(() => props.value?.toString(), [props.value]);

  useEffect(() => {
    setCount(propsValue?.length || 0);
  }, [propsValue]);

  if (plaintext) {
    return <span>{propsValue}</span>;
  }
  return (
    <StyledInputGroup inside style={props.style}>
      <StyledInput
        {...props}
        style={
          props.style?.width
            ? { width: `calc(${props.style?.width} - 2px)` }
            : undefined
        }
      />
      <StyledInputGroup.Addon>
        <CurrentLengthText
          style={{ color: count === 0 ? "var(--rs-gray-500)" : "" }}
        >
          {count}
        </CurrentLengthText>
        <TotalLengthText>{`/${props.maxLength}`}</TotalLengthText>
      </StyledInputGroup.Addon>
    </StyledInputGroup>
  );
};

export default InputCount;
