import styled from "styled-components";
import {
  PlusCircleFilled,
  MinusCircleFilled,
  CloseCircleFilled as AntCloseCircleFilled,
} from "@ant-design/icons";

export const CustomPlusCircleFilled = styled(PlusCircleFilled)`
  color: var(--rs-gray-600);
  background-color: #fff;
  font-size: 17px;
  vertical-align: middle;
`;

export const CustomMinusCircleFilled = styled(MinusCircleFilled)`
  color: var(--rs-gray-600);
  background-color: #fff;
  font-size: 17px;
  vertical-align: middle;
`;

export const CloseCircleFilled = styled(AntCloseCircleFilled)`
  color: var(--rs-gray-600);
  background-color: #fff;
  font-size: 17px;
  vertical-align: middle;
`;
