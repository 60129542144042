import React, { ReactElement, useCallback, useMemo, useState } from "react";
import {
  CreateItemContainer,
  CreateItemTitle,
  CreateItemValue,
} from "../../../components/layout";
import {
  CreateItemTitleText,
  formatDate,
  LinkText,
} from "../../../components/text";
import { Form, InputCount, TextAreaCount } from "components";
import {
  Button,
  Divider,
  FormControlProps,
  Schema,
  Stack,
  Uploader,
} from "rsuite";
import { FileType } from "rsuite/cjs/Uploader/Uploader";
import { EmailControl, PhoneControl } from "components/form/FormControls";
import { CompanyService } from "../../../utils/api";
import { CompanyType } from "../../../utils/types";
import fileDownload from "js-file-download";
import { regexpForEmail, regexpForPhoneNumber } from "../../../utils/variables";

const BusinessRegistrationNumberFormControl = (
  props: BusinessRegistrationNumberFormControlProps
) => {
  return <Form.Control {...props}>{props.children}</Form.Control>;
};

const BusinessRegistrationFileFormControl = (
  props: BusinessRegistrationFileFormControlProps
) => {
  return <Form.Control {...props}>{props.children}</Form.Control>;
};

const SettleManagerFormControl = (props: SettleManagerFormControlProps) => {
  return <Form.Control {...props}>{props.children}</Form.Control>;
};

const CompanyForm = (props: CompanyFormProps) => {
  const {
    onChange,
    formValue,
    mode,
    originFileName,
    onSubmit,
    isCheckedDuplicated,
    setIsCheckedDuplicated,
    isDuplicated,
    setIsDuplicated,
  } = props;
  const [showOriginFileName, setShowOriginFileName] = useState(
    mode !== "CREATE"
  );
  const model = useMemo(
    () =>
      Schema.Model({
        name: Schema.Types.StringType()
          .isRequired("회사명을 입력하세요.")
          .rangeLength(2, 16, "회사명은 2~16자로 입력하세요.")
          .pattern(
            /^[ㄱ-ㅎ가-힣ㅏ-ㅣa-zA-Z0-9\s]*$/,
            "영문(대소), 한글, 숫자, 띄어쓰기 사용 가능합니다."
          ),
        business_registration_number: Schema.Types.StringType()
          .addRule((v) => {
            return v !== "--" && v !== "";
          }, "사업자등록번호를 입력하세요.")
          .pattern(
            /[0-9]{3}-[0-9]{2}-[0-9]{5}/,
            "사업자등록번호를 정확히 입력하세요."
          ),
        business_registration_certificate: Schema.Types.ArrayType()
          //@ts-ignore
          .addRule((value: FileType[]) => {
            if (showOriginFileName) return true;
            else return value.length > 0;
          }, "사업자등록증을 업로드하세요."),
        address: Schema.Types.StringType().pattern(
          /^[ㄱ-ㅎ가-힣ㅏ-ㅣa-zA-Z0-9\s]*$/,
          "영문(대소), 한글, 숫자, 띄어쓰기 사용 가능합니다."
        ),
        business_type: Schema.Types.StringType().pattern(
          /^[ㄱ-ㅎ가-힣ㅏ-ㅣa-zA-Z,\s]*$/,
          "영문(대소), 한글, 띄어쓰기 사용 가능합니다."
        ),
        business_item: Schema.Types.StringType().pattern(
          /^[ㄱ-ㅎ가-힣ㅏ-ㅣa-zA-Z,\s]*$/,
          "영문(대소), 한글, 띄어쓰기 사용 가능합니다."
        ),
        site_address: Schema.Types.StringType().pattern(
          /^[a-zA-Z0-9_.\-=/:,\s]*$/,
          "영문(대소), 숫자, 언더바(_), ., -, =, /, :, ,, 띄어쓰기 사용 가능합니다."
        ),
        settlement_manager_name: Schema.Types.StringType().pattern(
          /^[ㄱ-ㅎ가-힣ㅏ-ㅣa-zA-Z\s]*$/,
          "영문(대소), 한글, 띄어쓰기 사용 가능합니다."
        ),
        settlement_manager_email: Schema.Types.StringType()
          .pattern(
            /^[a-zA-Z0-9_.@\\-]*$/,
            "영문(대소), 숫자, 언더바(_), ., - 사용 가능합니다."
          )
          .addRule((v) => {
            if (v === "" || v === "@") return true;
            return regexpForEmail.test(v);
          }, "이메일 형식에 맞게 입력하세요."),
        settlement_manager_phone: Schema.Types.StringType().addRule((v) => {
          if (v === "" || v === "--") return true;
          return regexpForPhoneNumber.test(v);
        }, "연락처 형식에 맞게 입력하세요."),
      }),
    [showOriginFileName]
  );

  const handleFormChange = useCallback(
    (formValue: any) => {
      onChange({
        ...formValue,
        business_registration_certificate_file_name:
          formValue.business_registration_certificate.length > 0
            ? formValue.business_registration_certificate[0].name
            : "",
      });
    },
    [onChange]
  );

  const checkDuplicatedRegistrationNumber = useCallback(async () => {
    if (!props.formValue?.business_registration_number) return;

    try {
      const {
        data: { result },
      } = await new CompanyService().existRegistrationNumber(
        props.formValue.business_registration_number
      );
      setIsCheckedDuplicated?.(true);
      setIsDuplicated?.(result);
    } catch (e) {
      console.log(e);
    }
  }, [
    props.formValue?.business_registration_number,
    setIsCheckedDuplicated,
    setIsDuplicated,
  ]);

  return (
    <>
      <CreateItemContainer style={{ paddingBottom: 0, marginBottom: -1 }}>
        <CreateItemTitle>
          <CreateItemTitleText>회사 유형</CreateItemTitleText>
        </CreateItemTitle>
        <CreateItemValue>매체사</CreateItemValue>
      </CreateItemContainer>
      <Form
        layout="horizontal"
        model={model}
        formValue={formValue}
        onChange={handleFormChange}
        onSubmit={onSubmit}
      >
        <Form.Group controlId="name">
          <Form.ControlLabel>
            <CreateItemTitleText required>회사명</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="name"
            accepter={InputCount}
            maxLength={16}
            style={{ width: 500, display: "inline-block" }}
            plaintext={mode === "VIEW"}
          />
          {mode !== "VIEW" && (
            <Form.HelpText>
              영문(대소), 한글, 숫자, 띄어쓰기 사용 가능
            </Form.HelpText>
          )}
        </Form.Group>
        <Form.Group controlId="business_registration_number">
          <Form.ControlLabel>
            <CreateItemTitleText required>사업자등록번호</CreateItemTitleText>
          </Form.ControlLabel>
          <BusinessRegistrationNumberFormControl
            name="business_registration_number"
            accepter={RegistrationNumberControl}
            onClickDuplication={checkDuplicatedRegistrationNumber}
            isDuplicated={isDuplicated}
            setIsDuplicated={setIsDuplicated}
            isCheckedDuplicated={isCheckedDuplicated}
            setIsCheckedDuplicated={setIsCheckedDuplicated}
            mode={mode}
          />
        </Form.Group>
        <Form.Group controlId="business_registration_certificate">
          <Form.ControlLabel style={{ alignSelf: "flex-start" }}>
            <CreateItemTitleText required>사업자등록증</CreateItemTitleText>
          </Form.ControlLabel>
          <BusinessRegistrationFileFormControl
            name="business_registration_certificate"
            accepter={RegistrationCertificateControl}
            mode={mode}
            originFileName={originFileName}
            showOriginFileName={showOriginFileName}
            setShowOriginFileName={setShowOriginFileName}
            companyId={formValue?.id}
          />
        </Form.Group>
        <Form.Group controlId="address">
          <Form.ControlLabel>
            <CreateItemTitleText>사업자 주소</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="address"
            accepter={InputCount}
            maxLength={128}
            style={{ width: 500, display: "inline-block" }}
            plaintext={mode === "VIEW"}
          />
          {mode !== "VIEW" && (
            <Form.HelpText>
              영문(대소), 한글, 숫자, 띄어쓰기 사용 가능
            </Form.HelpText>
          )}
        </Form.Group>
        <Form.Group controlId="business_type">
          <Form.ControlLabel>
            <CreateItemTitleText>업태</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="business_type"
            accepter={InputCount}
            maxLength={50}
            style={{ width: 500, display: "inline-block" }}
            plaintext={mode === "VIEW"}
            placeholder={"복수 개일 경우 쉼표(,)로 구분"}
          />
          {mode !== "VIEW" && (
            <Form.HelpText>영문(대소), 한글, 띄어쓰기 사용 가능</Form.HelpText>
          )}
        </Form.Group>
        <Form.Group controlId="business_item">
          <Form.ControlLabel>
            <CreateItemTitleText>업종</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="business_item"
            accepter={InputCount}
            maxLength={50}
            style={{ width: 500, display: "inline-block" }}
            plaintext={mode === "VIEW"}
            placeholder={"복수 개일 경우 쉼표(,)로 구분"}
          />
          {mode !== "VIEW" && (
            <Form.HelpText>영문(대소), 한글, 띄어쓰기 사용 가능</Form.HelpText>
          )}
        </Form.Group>
        <Form.Group controlId="site_address">
          <Form.ControlLabel>
            <CreateItemTitleText>사이트 주소</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="site_address"
            accepter={InputCount}
            maxLength={512}
            style={{
              width: 500,
              display: "inline-block",
            }}
            plaintext={mode === "VIEW"}
          />
          {mode !== "VIEW" && (
            <Form.HelpText>
              영문(대소), 숫자, 언더바(_), ., -, =, /, :, ,, 띄어쓰기 사용 가능
            </Form.HelpText>
          )}
        </Form.Group>
        <Divider />
        <Form.Group controlId="settlement_manager_name">
          <Form.ControlLabel>
            <CreateItemTitleText>정산 담당자</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="settlement_manager_name"
            accepter={InputCount}
            maxLength={16}
            style={{ width: 500, display: "inline-block" }}
            plaintext={mode === "VIEW"}
          />
          {mode !== "VIEW" && (
            <Form.HelpText>영문(대소), 한글, 띄어쓰기 사용 가능</Form.HelpText>
          )}
        </Form.Group>
        <Form.Group controlId="settlement_manager_email">
          <Form.ControlLabel>
            <CreateItemTitleText>정산 담당자 이메일</CreateItemTitleText>
          </Form.ControlLabel>
          <SettleManagerFormControl
            name="settlement_manager_email"
            accepter={EmailControl}
            mode={mode}
          />
        </Form.Group>
        <Form.Group controlId="settlement_manager_phone">
          <Form.ControlLabel>
            <CreateItemTitleText>정산 담당자 연락처</CreateItemTitleText>
          </Form.ControlLabel>
          <SettleManagerFormControl
            name="settlement_manager_phone"
            accepter={PhoneControl}
            mode={mode}
          />
        </Form.Group>
        <Divider />
        <Form.Group controlId="memo">
          <Form.ControlLabel>
            <CreateItemTitleText>메모</CreateItemTitleText>
          </Form.ControlLabel>
          <Form.Control
            name="memo"
            accepter={TextAreaCount}
            maxLength={1000}
            style={{ width: 500 }}
            plaintext={mode === "VIEW"}
          />
        </Form.Group>
        {mode !== "CREATE" && (
          <>
            <Divider />
            <CreateItemContainer style={{ padding: 0 }}>
              <CreateItemTitle>
                <CreateItemTitleText>생성일</CreateItemTitleText>
              </CreateItemTitle>
              <CreateItemValue>
                <span>{formatDate(formValue?.created_at)}</span>
              </CreateItemValue>
            </CreateItemContainer>
            <CreateItemContainer style={{ padding: "25px 0 0 0" }}>
              <CreateItemTitle>
                <CreateItemTitleText>마지막 수정일</CreateItemTitleText>
              </CreateItemTitle>
              <CreateItemValue>
                <span>{formatDate(formValue?.updated_at)}</span>
              </CreateItemValue>
            </CreateItemContainer>
          </>
        )}

        <Divider />
        {props.renderFooter()}
      </Form>
    </>
  );
};

export default CompanyForm;

export type CompanyFormProps = {
  formValue?: {
    //required
    name: string;
    type: CompanyType;
    business_registration_number: string;
    business_registration_certificate: FileType[];
    business_registration_certificate_file_name: string;
    address: string;
    business_type: string;
    business_item: string;
    site_address: string;
    settlement_manager_name: string;
    settlement_manager_email: string;
    settlement_manager_phone: string;
    memo: string;
    //option
    id?: number;
    enabled?: boolean;
    created_at?: string | Date;
    updated_at?: string | Date;
  };
  onChange: (formValue: CompanyFormProps["formValue"]) => void;
  renderFooter: () => ReactElement;
  mode: "CREATE" | "VIEW" | "EDIT";
  originFileName?: string;
  onSubmit?: (validate: boolean) => Promise<void>;
  isCheckedDuplicated?: boolean;
  setIsCheckedDuplicated?: React.Dispatch<React.SetStateAction<boolean>>;
  isDuplicated?: boolean;
  setIsDuplicated?: React.Dispatch<React.SetStateAction<boolean | undefined>>;
};

const RegistrationNumberControl = (props: RegistrationNumberControlProps) => {
  const {
    onChange,
    value = "--",
    onClickDuplication,
    isDuplicated,
    setIsDuplicated,
    setIsCheckedDuplicated,
    isCheckedDuplicated,
    mode,
  } = props;
  const splitByHyphen = value.split("-");

  const handleChange = useCallback(
    (order: number, changedValue: string) => {
      if (!checkNumber.test(changedValue)) return;
      const newValue =
        order === 0
          ? `${changedValue}-${value.split("-")[1]}-${value.split("-")[2]}`
          : order === 1
          ? `${value.split("-")[0]}-${changedValue}-${value.split("-")[2]}`
          : `${value.split("-")[0]}-${value.split("-")[1]}-${changedValue}`;

      onChange(newValue);
      setIsDuplicated?.(false);
      setIsCheckedDuplicated?.(false);
    },
    [onChange, setIsCheckedDuplicated, setIsDuplicated, value]
  );
  const regexpForNumber = /[0-9]{3}-[0-9]{2}-[0-9]{5}/;

  return mode === "VIEW" ? (
    <span>{value === "--" ? "" : value}</span>
  ) : (
    <>
      <Stack spacing={8}>
        <InputCount
          maxLength={3}
          value={splitByHyphen[0]}
          onChange={(v) => handleChange(0, v)}
        />
        -
        <InputCount
          maxLength={2}
          value={splitByHyphen[1]}
          onChange={(v) => handleChange(1, v)}
        />
        -
        <InputCount
          maxLength={5}
          value={splitByHyphen[2]}
          onChange={(v) => handleChange(2, v)}
        />
        <Button
          appearance={"primary"}
          disabled={!regexpForNumber.test(value) || isCheckedDuplicated}
          onClick={onClickDuplication}
        >
          중복 확인
        </Button>
      </Stack>
      {isDuplicated && (
        <Form.ErrorMessage show placement={"bottomStart"}>
          이미 사용중인 사업자등록번호입니다. 다른 사업자등록번호를 입력하세요.
        </Form.ErrorMessage>
      )}
      {!isCheckedDuplicated && regexpForNumber.test(value) && (
        <Form.ErrorMessage show placement={"bottomStart"}>
          사업자등록번호 중복 확인을 진행하세요.
        </Form.ErrorMessage>
      )}
      {isDuplicated === false && isCheckedDuplicated && (
        <Form.ErrorMessage show placement={"bottomStart"}>
          <span style={{ color: "var(--rs-state-success)" }}>
            사용 가능한 사업자등록번호입니다.
          </span>
        </Form.ErrorMessage>
      )}
    </>
  );
};

const RegistrationCertificateControl = (
  props: RegistrationCertificateControlProps
) => {
  const {
    value,
    onChange,
    mode,
    originFileName = "",
    showOriginFileName,
    setShowOriginFileName,
    companyId,
  } = props;

  const handleFileChange = useCallback(
    async (f: FileType[]) => {
      onChange(f);
      setShowOriginFileName(false);
    },
    [onChange, setShowOriginFileName]
  );

  const handleClickDownload = useCallback(async () => {
    try {
      const { data } =
        await new CompanyService().downloadRegistrationCertificate(
          companyId as number
        );
      fileDownload(data, originFileName);
    } catch (e) {
      console.log(e);
    }
  }, [companyId, originFileName]);

  return (
    <Stack spacing={showOriginFileName ? 8 : undefined}>
      {showOriginFileName && (
        <LinkText onClick={handleClickDownload}>{originFileName}</LinkText>
      )}

      {mode !== "VIEW" && (
        <Uploader
          disabled={value?.length === 1}
          action={""}
          autoUpload={false}
          accept={"image/jpg, image/png, .pdf"}
          fileList={value}
          onChange={handleFileChange}
          fileListVisible={value?.length > 0}
        >
          <Button style={{ fontSize: "12px" }}>업로드</Button>
        </Uploader>
      )}
    </Stack>
  );
};

type RegistrationNumberControlProps = {
  value: string;
  onChange: (value: string) => void;
  onClickDuplication: () => void;
  isDuplicated?: boolean;
  isCheckedDuplicated?: boolean;
  setIsDuplicated?: (v: boolean) => void;
  setIsCheckedDuplicated?: (v: boolean) => void;
  mode: CompanyFormProps["mode"];
};

type RegistrationCertificateControlProps = {
  value: FileType[];
  onChange: (value: FileType[]) => void;
  mode: CompanyFormProps["mode"];
  originFileName?: string;
  showOriginFileName: boolean;
  setShowOriginFileName: (v: boolean) => void;
  companyId?: number;
};

interface BusinessRegistrationNumberFormControlProps extends FormControlProps {
  onClickDuplication: () => void;
  isDuplicated?: boolean;
  isCheckedDuplicated?: boolean;
  setIsDuplicated?: (v: boolean) => void;
  setIsCheckedDuplicated?: (v: boolean) => void;
  mode: CompanyFormProps["mode"];
}

interface BusinessRegistrationFileFormControlProps extends FormControlProps {
  mode: CompanyFormProps["mode"];
  originFileName?: string;
  showOriginFileName: boolean;
  setShowOriginFileName: (v: boolean) => void;
  companyId?: number;
}

interface SettleManagerFormControlProps extends FormControlProps {
  mode: CompanyFormProps["mode"];
}

const checkNumber = /^[0-9]*$/;
