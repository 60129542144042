import React, { useCallback, useContext } from "react";
import { Nav, Navbar } from "rsuite";
import { MenuOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { AuthService } from "utils/api";
import { AuthContext } from "../../utils/context/AuthContext";
import Logo from "assets/logo.png";
import { useNavigate } from "react-router-dom";
import { FlexDiv } from "components/layout";

const StyledNavbar = styled(Navbar)`
  &.rs-navbar-subtle {
    color: var(--rs-text-heading);
  }
`;

const GNB = (props: { handleChangeExpanded: () => void }) => {
  const { myInfo, setMyInfoHandler } = useContext(AuthContext);
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    try {
      const service = new AuthService();
      await service.logout();
      setMyInfoHandler(null);
    } catch (e: any) {
      console.log(e);
    }
  }, [setMyInfoHandler]);

  return (
    <StyledNavbar
      appearance="subtle"
      style={{ borderBottom: `1px solid var(--rs-border-primary)` }}
    >
      <Nav>
        <Nav.Item
          style={{ padding: "18px 20px" }}
          onClick={props.handleChangeExpanded}
        >
          <MenuOutlined
            style={{
              fontSize: "15px",
              cursor: "pointer",
            }}
          />
        </Nav.Item>
      </Nav>
      <Navbar.Brand
        style={{
          cursor: "default",
          padding: 0,
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <FlexDiv>
          <img src={Logo} alt={"logo"} style={{ width: 70, marginRight: 10 }} />
          <span
            style={{
              fontFamily: "'lgsmart'",
              fontSize: "28px",
              fontWeight: "bold",
              color: "#6d6e71",
              lineHeight: "18px",
              alignSelf: "flex-end",
            }}
          >
            SSP
          </span>
        </FlexDiv>
      </Navbar.Brand>

      <Nav pullRight>
        <Nav.Item
          onClick={() => {
            navigate(
              `/account/user/${myInfo?.role === "VIEWER" ? "view" : "edit"}/${
                myInfo?.id
              }`,
              {
                state: { companyType: myInfo?.company_type },
              }
            );
          }}
        >
          {myInfo?.login_id}
        </Nav.Item>
        <Nav.Item onClick={logout}>로그아웃</Nav.Item>
      </Nav>
    </StyledNavbar>
  );
};

export default GNB;
