import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CreateFooter, PageHeader } from "components/layout";
import PlacementForm, { PlacementFormProps } from "./PlacementForm";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Loader } from "rsuite";
import { AdNetworkPriorityGroupControlProps } from "./AdNetworkPriorityGroupControl";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { AdNetworkUnitService, PlacementService } from "../../../utils/api";
import { MobileOSType } from "../../../utils/types";
import {
  getComparatorsString,
  getOperatorsString,
} from "../../../front-utils/filter/dynamicFilter";

const CreatePlacement = () => {
  const location = useLocation();
  const { publisher, placementGroup } =
    location.state as CreatePlacementLocationState;
  const [formValue, setFormValue] = useState<PlacementFormProps["formValue"]>({
    ...initFormValue,
    placement_group: placementGroup || null,
  });
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const navigate = useNavigate();
  const [adNetworkUnits, setAdNetworkUnits] = useState<
    AdNetworkPriorityGroupControlProps["adNetworkUnits"]
  >([]);
  const formRef = useRef<HTMLFormElement>(null);

  const fetchAdNetworkUnits = useCallback(async () => {
    if (!publisher) return;

    const { data } = await new AdNetworkUnitService().getIdNameMeta({
      filter: getOperatorsString(
        "and",
        [
          `adNetwork.enabled : true`,
          getComparatorsString(":", "adNetwork.publisher.id", publisher),
          getComparatorsString(
            ":",
            "platform",
            formValue.screen === "MOBILE"
              ? (formValue.mobile_os as MobileOSType)
              : formValue.screen
          ),
          getComparatorsString(":", "application", formValue.platform),
          formValue.width !== ""
            ? getComparatorsString("<:", "width", formValue.width)
            : "",
          formValue.height !== ""
            ? getComparatorsString("<:", "height", formValue.height)
            : "",
        ].filter((v) => v !== "")
      ),
      sort: ["adNetwork.name,asc", "name,asc"],
    });

    setAdNetworkUnits(
      data.map(
        (d: {
          id: number;
          name: string;
          ad_network_code: string;
          ad_network_name: string;
          ad_network_type: string;
          type: string;
        }) => ({
          id: d.id,
          name: d.name,
          ad_network_code: d.ad_network_code,
          ad_network_type: d.ad_network_type,
          ad_network_name: d.ad_network_name,
          enabled: true,
          type: d.type,
        })
      )
    );
  }, [
    formValue.height,
    formValue.mobile_os,
    formValue.platform,
    formValue.screen,
    formValue.width,
    publisher,
  ]);

  useEffect(() => {
    fetchAdNetworkUnits();
  }, [fetchAdNetworkUnits]);

  const handleSubmit = useCallback(async () => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { hasError } = await formRef?.current?.checkAsync();
    formRef?.current?.checkForField("priority_groups");

    if (hasError) return;
    try {
      setLoading(true);
      if (!formValue) return;
      const {
        placement_group,
        code,
        name,
        screen,
        platform,
        mobile_os,
        width,
        height,
        ad_network,
        priority_groups,
      } = formValue;

      await new PlacementService().create({
        publisher_id: publisher,
        placement_group_id: placement_group as number,
        code,
        name,
        platform: screen === "MOBILE" ? (mobile_os as MobileOSType) : screen,
        application: platform,
        width,
        height,
        is_using_ad_network: ad_network.includes("true"),
        ad_network_priority_groups: ad_network.includes("true")
          ? priority_groups.map((pg) => ({
              creative_type: pg.creative_type,
              priority_type: pg.priority_type,
              ad_network_priorities: pg.options.map((op) => ({
                priority: op.priority ? parseInt(op.priority) : undefined,
                impressions: op.impressions
                  ? parseInt(op.impressions)
                  : undefined,
                limit_impression:
                  pg.priority_type === "RATIO"
                    ? undefined
                    : op.limit_impression,
                ad_network_unit_id: op.ad_network_unit_id,
                ratio:
                  pg.priority_type === "RATIO" ? parseInt(op.ratio) : undefined,
              })),
            }))
          : undefined,
      });

      navigate(`/inventory/placements?selected-tab=placement`, {
        state: { publisher },
      });
    } catch (e) {
      setErrorModalOpen(true);
    } finally {
      setLoading(false);
    }
  }, [formValue, navigate, publisher, setErrorModalOpen]);

  return (
    <>
      <PageHeader title={"게재위치 생성"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}
      <PlacementForm
        formValue={formValue}
        onFormChange={setFormValue}
        renderFooter={() => (
          <CreateFooter>
            <Link
              to={"/inventory/placements?selected-tab=placement"}
              state={{ publisher }}
            >
              <Button>취소</Button>
            </Link>
            <Button appearance={"primary"} onClick={handleSubmit}>
              생성
            </Button>
          </CreateFooter>
        )}
        publisher={publisher}
        adNetworkUnits={adNetworkUnits}
        formRef={formRef}
      />
    </>
  );
};

export default CreatePlacement;

export type CreatePlacementLocationState = {
  publisher: number;
  placementGroup?: number;
};

export const initFormValue: PlacementFormProps["formValue"] = {
  placement_group: null,
  code: "",
  name: "",
  screen: "PC",
  platform: "WEB",
  mobile_os: null,
  width: "",
  height: "",
  ad_network: [],
  priority_groups: [
    {
      creative_type: "ALL",
      priority_type: "RATIO",
      ad_network_unit_ids: [],
      options: [],
    },
  ],
};
