import React from "react";
import { DatePicker as RsuiteDatePicker } from "rsuite";
import styled from "styled-components";

const StyledDatePicker: typeof RsuiteDatePicker = styled(RsuiteDatePicker)`
  &.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  &.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: var(--rs-text-heading);
  }

  &.error.rs-picker-default .rs-picker-toggle {
    border-color: var(--rs-form-errormessage-text);
  }
`;

const DatePicker: typeof RsuiteDatePicker = React.forwardRef((props, ref) => {
  return (
    <StyledDatePicker
      cleanable={false}
      locale={{
        ok: "적용",
      }}
      ref={ref}
      ranges={[]}
      {...props}
    />
  );
});
DatePicker.displayName = "DatePicker";

export default DatePicker;
