import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import CompanyForm, { CompanyFormProps } from "./CompanyForm";
import { CreateFooter, PageHeader } from "../../../components/layout";
import { Button } from "rsuite";
import { CompanyService } from "../../../utils/api";
import { AuthContext } from "../../../utils/context/AuthContext";

const ViewCompany = () => {
  const { id = "" } = useParams<{ id: string }>();
  const [formValue, setFormValue] =
    useState<CompanyFormProps["formValue"]>(undefined);
  const [originFileName, setOriginFileName] = useState("");
  const { myInfo } = useContext(AuthContext);

  const fetchData = useCallback(async () => {
    const { data } = await new CompanyService().getDetail(id);

    setFormValue({
      id: data.id,
      type: "PUBLISHER",
      enabled: data.enabled,
      name: data.name,
      business_registration_number: data.business_registration_number,
      business_registration_certificate: [],
      business_registration_certificate_file_name: "",
      address: data.address,
      business_type: data.business_type,
      business_item: data.business_item,
      site_address: data.site_address,
      settlement_manager_name: data.settlement_manager_name,
      settlement_manager_email: data.settlement_manager_email,
      settlement_manager_phone: data.settlement_manager_phone,
      memo: data.memo,
      created_at: data.created_at,
      updated_at: data.updated_at,
    });
    setOriginFileName(data.business_registration_certificate_file_name);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <PageHeader title={"회사 조회"} />
      <CompanyForm
        formValue={formValue}
        originFileName={originFileName}
        onChange={setFormValue}
        renderFooter={() => (
          <CreateFooter>
            <Link to={`/account/company/edit/${id}`}>
              <Button disabled={myInfo?.role === "VIEWER"}>수정</Button>
            </Link>
          </CreateFooter>
        )}
        mode={"VIEW"}
      />
    </>
  );
};

export default ViewCompany;
