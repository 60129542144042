import React, {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PageHeader, TableActionsContainer } from "../../../components/layout";
import {
  Button,
  CellProps,
  Divider,
  Notification,
  Popover,
  Stack,
  Table as RsuiteTable,
  toaster,
  Whisper,
} from "rsuite";
import {
  DateRangePicker,
  EllipsisPopup,
  MultiplePicker,
  Search,
  SelectPicker,
  Table,
} from "components";
import moment from "moment";
import { DateRange } from "rsuite/cjs/DateRangePicker/types";
import { AdReviewStatusType, SortType } from "../../../utils/types";
import {
  DateCell,
  MapCell,
  ReviewStatusCell,
  TextCell,
} from "../../../components/table/Cells";
import { CREATIVE_TYPE } from "../../../utils/variables";
import {
  AccountService,
  PlacementGroupService,
  ReviewService,
} from "../../../utils/api";
import { underToUpper } from "../../../utils";
import _ from "lodash";
import {
  getComparatorsString,
  getOperatorsString,
} from "../../../front-utils/filter/dynamicFilter";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { AuthContext } from "../../../utils/context/AuthContext";
import { StarFilled, StarOutlined } from "@ant-design/icons";
const { Cell, Column, HeaderCell } = RsuiteTable;

const ReviewAd = () => {
  const [date, setDate] = useState<DateRange | null>(initialFilterStates.date);
  const [status, setStatus] = useState(initialFilterStates.status);
  const [creativeType, setCreativeType] = useState(
    initialFilterStates.creativeType
  );
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  }>({
    sortColumn: "created_at",
    sortType: "desc",
  });
  const [searchKey, setSearchKey] = useState<
    "contents" | "adNetwork.name" | "reviewer"
  >("contents");
  const [searchValue, setSearchValue] = useState<string>("");
  const [appliedSearchValue, setAppliedSearchValue] =
    useState<string>(searchValue);
  const [appliedParams, setAppliedParams] = useState({
    date,
    status,
    creativeType,
  });
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const [tableLoading, setTableLoading] = useState(false);
  const [placementGroup, setPlacementGroup] = useState<number | null>(null);
  const [placementGroups, setPlacementGroups] = useState<
    { label: string; value: number; isDefault: boolean }[]
  >([]);
  const { myInfo } = useContext(AuthContext);
  const [publisher, setPublisher] = useState<any>(
    myInfo?.company_type === "PUBLISHER" ? myInfo?.company_id : null
  );

  useEffect(() => {
    if (myInfo?.company_type === "PUBLISHER" && !publisher) {
      setPublisher(myInfo?.company_id);
    }

    if (myInfo?.default_review_placement_group_id && placementGroup === null) {
      setPlacementGroup(myInfo?.default_review_placement_group_id);
    }
  }, [
    myInfo?.company_id,
    myInfo?.company_type,
    myInfo?.default_review_placement_group_id,
    placementGroup,
    publisher,
  ]);

  const fetchData = useCallback(async () => {
    if (!placementGroup) return;
    try {
      setTableLoading(true);
      const { data } = await new ReviewService().get({
        page,
        size,
        sort: underToUpper(sort.sortColumn) + "," + sort.sortType,
        filter: getOperatorsString(
          "and",
          [
            getComparatorsString(":", "placementGroup.id", placementGroup),
            getComparatorsString(
              ">:",
              "createdDate",
              moment(appliedParams.date?.[0]).format("YYYY-MM-DD")
            ),
            getComparatorsString(
              "<:",
              "createdDate",
              moment(appliedParams.date?.[1]).format("YYYY-MM-DD")
            ),
            getComparatorsString("in", "status", appliedParams.status),
            getComparatorsString(
              "in",
              "adNetworkUnit.type",
              appliedParams.creativeType
            ),
            appliedSearchValue !== "" &&
            (searchKey === "contents" || searchKey === "adNetwork.name")
              ? getComparatorsString("~", searchKey, `%${appliedSearchValue}%`)
              : "",
            appliedSearchValue !== "" && searchKey === "reviewer"
              ? `(${getOperatorsString("or", [
                  getComparatorsString(
                    "~",
                    "reviewer.name",
                    `%${appliedSearchValue}%`
                  ),
                  getComparatorsString(
                    "~",
                    "reviewer.loginId",
                    `%${appliedSearchValue}%`
                  ),
                ])})`
              : "",
          ].filter((v) => v !== "")
        ),
      });

      setData(data.content);
      setTotal(data.total_elements);
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }, [
    appliedParams.creativeType,
    appliedParams.date,
    appliedParams.status,
    appliedSearchValue,
    page,
    placementGroup,
    searchKey,
    size,
    sort.sortColumn,
    sort.sortType,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    setPage(1);
  }, [size, appliedParams, appliedSearchValue]);

  const fetchPlacementGroups = useCallback(async () => {
    const { data } = await new PlacementGroupService().getIdNameMeta({
      filter: `${publisher ? `publisher.id : ${publisher}` : ""}`,
      sort: "name,asc",
    });

    setPlacementGroups(
      data.map((pg: { id: number; name: string }) => ({
        label: pg.name,
        value: pg.id,
        isDefault: myInfo?.default_review_placement_group_id === pg.id,
      }))
    );
  }, [myInfo?.default_review_placement_group_id, publisher]);

  useEffect(() => {
    fetchPlacementGroups();
  }, [fetchPlacementGroups]);

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  const columns = useMemo(
    () => [
      {
        title: "검수 상태",
        key: "status",
        sortable: true,
        width: 120,
        Cell: ReviewStatusCell,
      },
      {
        title: "소재타입",
        key: "ad_network_unit.type",
        sortable: true,
        width: 100,
        Cell: (props: CellProps) => (
          <MapCell mapper={CREATIVE_TYPE} {...props} />
        ),
      },
      {
        title: "소재",
        key: "creative_url",
        sortable: true,
        width: 300,
        flexGrow: 3,
        Cell: (props: CellProps) => (
          <Cell {...props}>
            <EllipsisPopup
              text={
                props.rowData.creative_url ? (
                  <a
                    href={props.rowData.creative_url}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {props.rowData.creative_url}
                  </a>
                ) : (
                  "-"
                )
              }
            />
          </Cell>
        ),
      },
      {
        title: "랜딩",
        key: "landing_url",
        sortable: true,
        width: 300,
        flexGrow: 3,
        Cell: (props: CellProps) => (
          <Cell {...props}>
            <EllipsisPopup
              text={
                props.rowData.landing_url ? (
                  <a
                    href={props.rowData.landing_url}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {props.rowData.landing_url}
                  </a>
                ) : (
                  "-"
                )
              }
            />
          </Cell>
        ),
      },
      {
        title: "네트워크",
        key: "ad_network",
        sortable: true,
        width: 120,
        Cell: (props: CellProps) => (
          <TextCell
            {...props}
            text={`${props.rowData.ad_network.name}(${props.rowData.ad_network.type})`}
          />
        ),
      },
      {
        title: "접수일",
        key: "created_at",
        sortable: true,
        width: 150,
        Cell: DateCell,
      },
      {
        title: "업데이트일",
        key: "updated_at",
        sortable: true,
        width: 150,
        Cell: DateCell,
      },
      {
        title: "검수자",
        key: "reviewer.name",
        sortable: true,
        width: 130,
        flexGrow: 1,
        Cell: (props: CellProps) => (
          <TextCell
            {...props}
            text={
              props.rowData.reviewer
                ? `${props.rowData.reviewer.name}(${props.rowData.reviewer.login_id})`
                : "-"
            }
          />
        ),
      },
    ],
    []
  );

  const handleClickReview = useCallback(
    async (id: number, status: AdReviewStatusType) => {
      try {
        await new ReviewService().editStatus(id, { status });

        const message: { [key in AdReviewStatusType]: string } = {
          AUTO_APPROVED: "차단이 해제되었습니다.",
          BLOCKED: "차단되었습니다.",
          WAITING_REVIEW: "",
          APPROVED: "승인되었습니다",
          DECLINED: "반려되었습니다.",
        };

        const type: {
          [key in AdReviewStatusType]: "success" | "warning" | undefined;
        } = {
          AUTO_APPROVED: "success",
          BLOCKED: "warning",
          WAITING_REVIEW: undefined,
          APPROVED: "success",
          DECLINED: "warning",
        };

        if (status !== "WAITING_REVIEW")
          toaster.push(toast(message[status], type[status]), {
            placement: "bottomEnd",
          });

        fetchData();
      } catch {
        setErrorModalOpen(true);
      }
    },
    [fetchData, setErrorModalOpen]
  );

  const handleSearchKeyChange = useCallback(
    (v: "contents" | "adNetwork.name" | "reviewer") => {
      setSearchKey(v);
      setSearchValue("");
    },
    []
  );

  const delayedSetSearchValue = useMemo<any>(
    () => _.debounce((v: string) => setAppliedSearchValue(v), 500),
    []
  );

  const handleSearchValueChange = useCallback((v: string) => {
    setSearchValue(v);
  }, []);

  useEffect(() => {
    delayedSetSearchValue(searchValue);
  }, [searchValue, delayedSetSearchValue]);

  const handleClickFetch = useCallback(() => {
    setAppliedParams({
      date,
      status,
      creativeType,
    });
  }, [date, status, creativeType]);

  const handleClickStar = useCallback(
    async (e: any, value: number) => {
      e.stopPropagation();
      try {
        await new AccountService().editDefaultReviewPlacementGroupId(value);
        setPlacementGroups((pgs) =>
          pgs.map((pg) => ({
            ...pg,
            isDefault: value === pg.value,
          }))
        );
      } catch {
        setErrorModalOpen(true);
      }
    },
    [setErrorModalOpen]
  );

  const handleChangePlacementGroup = useCallback((v: number) => {
    setPlacementGroup(v);
    setDate(initialFilterStates.date);
    setStatus(initialFilterStates.status);
    setCreativeType(initialFilterStates.creativeType);

    setAppliedParams({
      date: initialFilterStates.date,
      status: initialFilterStates.status,
      creativeType: initialFilterStates.creativeType,
    });
  }, []);

  return (
    <>
      <PageHeader title={"광고 검수"}>
        <SelectPicker
          placeholder={
            placementGroups.length === 0
              ? "게재위치그룹이 없습니다"
              : "게재위치그룹을 선택하세요"
          }
          data={placementGroups}
          cleanable={false}
          searchable={true}
          style={{ marginLeft: 10 }}
          value={placementGroup}
          onChange={handleChangePlacementGroup}
          renderValue={
            placementGroups.find((pg) => pg.value === placementGroup)
              ? (value: number, item: any, selectedElement: ReactNode) => (
                  <div>
                    {selectedElement}
                    <Whisper
                      trigger={"hover"}
                      placement={"top"}
                      speaker={
                        <Popover>
                          선택한 게재위치그룹이 광고 검수 기본 페이지가 됩니다,
                        </Popover>
                      }
                    >
                      <span
                        style={{
                          marginLeft: 10,
                          float: "right",
                          color: "var(--rs-yellow-500)",
                        }}
                      >
                        {item?.isDefault ? (
                          <StarFilled />
                        ) : (
                          <StarOutlined
                            onClick={(e) => handleClickStar(e, item.value)}
                          />
                        )}
                      </span>
                    </Whisper>
                  </div>
                )
              : undefined
          }
        />
      </PageHeader>
      <Stack
        spacing={8}
        style={{
          padding: "9px 25px",
          borderBottom: "1px solid var(--rs-border-primary)",
        }}
      >
        <DateRangePicker value={date} onChange={setDate} />
        <MultiplePicker
          data={reviewStatusData}
          label={"검수 상태"}
          value={status}
          onValueChange={setStatus}
        />
        <MultiplePicker
          data={creativeTypeData}
          label={"소재타입"}
          value={creativeType}
          onValueChange={setCreativeType}
        />
        <Button appearance={"primary"} onClick={handleClickFetch}>
          조회
        </Button>
      </Stack>
      <TableActionsContainer>
        <Stack>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "var(--rs-gray-800)",
              height: "50px",
            }}
          >
            광고 현황
          </span>
          <Divider vertical />
          <span>총 {total}개</span>
        </Stack>
        <Search
          data={searchKeys}
          searchKey={searchKey}
          onSearchKeyChange={handleSearchKeyChange}
          searchValue={searchValue}
          onSearchValueChange={handleSearchValueChange}
        />
      </TableActionsContainer>
      <Table
        data={data}
        columns={columns}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        sortColumn={sort.sortColumn}
        sortType={sort.sortType}
        onSortColumn={handleSortChange}
        loading={tableLoading}
      >
        <Column width={130} align={"center"} fixed={"right"}>
          <HeaderCell>검수</HeaderCell>
          <Cell style={{ padding: "8px 10px" }}>
            {(rowData) =>
              rowData.status === "AUTO_APPROVED" ? (
                <Button
                  size={"sm"}
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid var(--rs-border-primary)",
                  }}
                  onClick={() => handleClickReview(rowData.id, "BLOCKED")}
                >
                  차단
                </Button>
              ) : rowData.status === "BLOCKED" ? (
                <Button
                  size={"sm"}
                  style={{
                    backgroundColor: "var(--rs-gray-900)",
                    width: 90,
                    fontWeight: 400,
                  }}
                  appearance={"primary"}
                  onClick={() => handleClickReview(rowData.id, "AUTO_APPROVED")}
                >
                  차단 취소
                </Button>
              ) : rowData.status === "WAITING_REVIEW" ? (
                <Stack spacing={5} style={{ display: "inline-flex" }}>
                  <Button
                    size={"sm"}
                    color="red"
                    appearance={"primary"}
                    style={{ fontWeight: 400 }}
                    onClick={() => handleClickReview(rowData.id, "DECLINED")}
                  >
                    반려
                  </Button>
                  <Button
                    size={"sm"}
                    color="green"
                    appearance={"primary"}
                    style={{ fontWeight: 400 }}
                    onClick={() => handleClickReview(rowData.id, "APPROVED")}
                  >
                    승인
                  </Button>
                </Stack>
              ) : (
                <Button
                  size={"sm"}
                  style={{ width: 90 }}
                  onClick={() =>
                    handleClickReview(rowData.id, "WAITING_REVIEW")
                  }
                >
                  검수 취소
                </Button>
              )
            }
          </Cell>
        </Column>
      </Table>
    </>
  );
};

export default ReviewAd;

const reviewStatusData = [
  {
    label: "검수 대기",
    value: "WAITING_REVIEW",
  },
  {
    label: "반려",
    value: "DECLINED",
  },
  {
    label: "승인",
    value: "APPROVED",
  },
  {
    label: "자동 승인",
    value: "AUTO_APPROVED",
  },
  {
    label: "차단",
    value: "BLOCKED",
  },
];

const creativeTypeData = [
  {
    label: "이미지",
    value: "IMAGE",
  },
  {
    label: "동영상",
    value: "VIDEO",
  },
];

const searchKeys = [
  {
    label: "내용",
    value: "contents",
  },
  {
    label: "네트워크",
    value: "adNetwork.name",
  },
  {
    label: "검수자",
    value: "reviewer",
  },
];

const toast = (message: string, type?: "success" | "warning") => (
  <Notification
    style={{ width: 200 }}
    type={type}
    header={type === "success" ? "성공" : "주의"}
  >
    {message}
  </Notification>
);

const initialFilterStates: {
  date: DateRange;
  status: string[];
  creativeType: string[];
} = {
  date: [moment().subtract(89, "days").toDate(), moment().toDate()],
  status: [
    "WAITING_REVIEW",
    "DECLINED",
    "APPROVED",
    "AUTO_APPROVED",
    "BLOCKED",
  ],
  creativeType: ["IMAGE", "VIDEO"],
};
