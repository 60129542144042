import React, { createContext, useState } from "react";

export const ErrorModalContext = createContext<{
  open: boolean;
  setErrorModalOpen: (open: boolean) => void;
}>({
  open: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorModalOpen: (open: boolean) => {},
});

const ErrorModalContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const setErrorModalOpen = (open: boolean) => setOpen(open);

  return (
    <ErrorModalContext.Provider value={{ open, setErrorModalOpen }}>
      {children}
    </ErrorModalContext.Provider>
  );
};

export default ErrorModalContextProvider;
