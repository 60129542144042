import React, { ReactElement, useCallback, useMemo } from "react";
import {
  CellProps,
  Table as RsuiteTable,
  TableProps as RsuiteTableProps,
  Checkbox,
  Pagination,
  PaginationProps,
} from "rsuite";
import { TextCell, CheckCell } from "./Cells";
import { ValueType } from "rsuite/Checkbox";
import styled from "styled-components";

const { Column, HeaderCell } = RsuiteTable;

const StyledRsuiteTable = styled(RsuiteTable)`
  &.rs-table-bordered {
    border-color: var(--rs-border-primary);
  }
  .rs-table-affix-header .rs-checkbox-inline {
    display: none;
  }
  .rs-table-affix-header.fixed .rs-checkbox-inline {
    display: inline-block;
  }
  .rs-table-row.error .rs-table-cell {
    background-color: var(--rs-red-100);
  }
  .rs-table-header-row-wrapper {
    color: var(--rs-text-primary);
    font-weight: bold;
  }
  .rs-table-cell-first .rs-table-cell-content {
    padding-left: 25px;
  }
  .rs-table-cell-last .rs-table-cell-content,
  .rs-table-column-group-header-content:last-child {
    padding-right: 25px;
  }

  .rs-table-row {
    border-bottom-color: var(--rs-border-primary);
    &.rs-table-row-header {
      border-bottom: 2px solid var(--rs-gray-600);
    }
    &.total,
    &.total .rs-table-cell-group,
    &.total .rs-table-cell {
      background: #f5f6f7;
    }
  }

  .rs-table-column-group-header {
    border-bottom-color: var(--rs-border-primary);
  }
`;

const Table = (props: TableProps) => {
  const {
    checkedKeys = [],
    paginationLayout = ["pager", "|", "limit"],
    renderEmpty = () => (
      <div className="rs-table-body-info">데이터가 없습니다.</div>
    ),
    columns,
    checkable,
    onCheckAll,
    onCheck,
    pagination,
    activePage,
    displayLength,
    total,
    onChangePage,
    onChangeLength,
    children,
    disabledKeys,
    ...restProps
  } = props;

  const handleCheckAll = useCallback(
    (value: ValueType | undefined, checked: boolean) => {
      if (props.loading) return;
      if (onCheckAll) onCheckAll(value, checked);
    },
    [onCheckAll, props.loading]
  );

  const dataLength = useMemo(
    () =>
      props.data?.filter((value) => {
        return !(value.type && value.type === "TOTAL");
      }).length || 0,
    [props.data]
  );

  const checked = useMemo(
    () =>
      dataLength > 0 && checkedKeys.length === dataLength
        ? true
        : checkedKeys.length === 0
        ? false
        : false,
    [checkedKeys.length, dataLength]
  );
  const indeterminate = useMemo(
    () => checkedKeys.length > 0 && checkedKeys.length < dataLength,
    [checkedKeys.length, dataLength]
  );

  return (
    <>
      <StyledRsuiteTable
        bordered
        affixHeader
        affixHorizontalScrollbar
        autoHeight
        renderEmpty={renderEmpty}
        {...restProps}
      >
        {checkable && (
          <Column width={50} align="center" fixed>
            <HeaderCell style={{ padding: 0 }}>
              <div style={{ lineHeight: "40px" }}>
                <Checkbox
                  inline
                  checked={checked}
                  indeterminate={indeterminate}
                  onChange={handleCheckAll}
                />
              </div>
            </HeaderCell>
            <CheckCell
              dataKey="id"
              checkedKeys={checkedKeys}
              onChange={onCheck}
              disabledKeys={disabledKeys}
            />
          </Column>
        )}
        {columns && (
          <>
            {columns.map((column) => (
              <Column
                key={column.key}
                minWidth={column.flexGrow ? column.width : undefined}
                width={column.flexGrow ? undefined : column.width}
                align={column.align}
                fixed={column.fixed}
                sortable={column.sortable}
                flexGrow={column.flexGrow}
              >
                <HeaderCell style={column.headerStyle}>
                  {column.title}
                </HeaderCell>
                {column.Cell ? (
                  <column.Cell dataKey={column.key} />
                ) : (
                  <TextCell dataKey={column.key} />
                )}
              </Column>
            ))}
          </>
        )}
        {children}
      </StyledRsuiteTable>
      {pagination && (
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size="sm"
            layout={paginationLayout}
            total={total || 0}
            limitOptions={[10, 25, 50, 75, 100]}
            limit={displayLength || 25}
            activePage={activePage}
            onChangePage={onChangePage}
            onChangeLimit={onChangeLength}
            style={{ justifyContent: "center" }}
            linkAs={"span"}
          />
        </div>
      )}
    </>
  );
};

interface TableProps extends RsuiteTableProps {
  columns?: TableColumns[];
  checkable?: boolean;
  onCheckAll?: (value: ValueType | undefined, checked: boolean) => void;
  onCheck?: (value: ValueType | undefined, checked: boolean) => void;
  checkedKeys?: ValueType[];
  pagination?: boolean;
  activePage?: number;
  displayLength?: number;
  total?: number;
  showLengthMenu?: boolean;
  onChangePage?: (value: number) => void;
  onChangeLength?: (value: number) => void;
  paginationLayout?: PaginationProps["layout"];
  disabledKeys?: ValueType[];
}

export type TableColumns = {
  title: string | ReactElement;
  key: string;
  width?: number;
  sortable?: boolean;
  fixed?: boolean;
  flexGrow?: number;
  align?: "left" | "center" | "right";
  Cell?: (props: CellProps) => ReactElement;
  headerStyle?: any;
};

export default Table;
