import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CreateFooter, PageHeader } from "components/layout";
import PlacementGroupForm, {
  PlacementGroupFormProps,
} from "./PlacementGroupForm";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Loader } from "rsuite";
import { initFormValue } from "./CreatePlacementGroup";
import { PlacementGroupService } from "../../../utils/api";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { PublisherState } from "../../../utils/types";

const EditPlacementGroup = () => {
  const { id = "" } = useParams<{ id: string }>();
  const state = useLocation().state || {};
  const { publisher } = state as PublisherState;
  const [formValue, setFormValue] =
    useState<PlacementGroupFormProps["formValue"]>(initFormValue);
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const navigate = useNavigate();
  const [placementCount, setPlacementCount] = useState(0);
  const [originName, setOriginName] = useState("");
  const formRef = useRef<HTMLFormElement>(null);

  const fetchData = useCallback(async () => {
    const { data } = await new PlacementGroupService().getDetail(id);

    setFormValue({
      id: data.id,
      publisher_id: data.publisher.id,
      name: data.name,
      representative_address: data.representative_address,
      pc_address: data.pc_address,
      mobile_address: data.mobile_address,
      android_address: data.android_address,
      ios_address: data.ios_address,
      account_ids: data.accounts.map(
        (a: { id: number; name: string; login_id: string }) => a.id
      ),
      memo: data.memo,
    });
    setPlacementCount(data.placement_count);
    setOriginName(data.name);
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = useCallback(
    async (validate: boolean) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { hasError } = await formRef?.current?.checkAsync();
      if (!validate || hasError) return;

      try {
        setLoading(true);
        if (!formValue) return;
        const {
          publisher_id,
          name,
          representative_address,
          pc_address,
          mobile_address,
          android_address,
          ios_address,
          account_ids,
          memo,
        } = formValue;

        await new PlacementGroupService().edit(id, {
          id: parseInt(id),
          publisher_id: publisher_id as number,
          name,
          representative_address: representative_address as string,
          pc_address,
          mobile_address,
          android_address,
          ios_address,
          account_ids,
          memo,
        });

        navigate(`/inventory/placements?selected-tab=placement-group`, {
          state: { publisher },
        });
      } catch (e) {
        setErrorModalOpen(true);
      } finally {
        setLoading(false);
      }
    },
    [formValue, id, navigate, publisher, setErrorModalOpen]
  );

  return (
    <>
      <PageHeader title={"게재위치 그룹 조회"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}
      <PlacementGroupForm
        formValue={formValue}
        onChange={setFormValue}
        publisher={formValue.publisher_id}
        onSubmit={handleSubmit}
        placementCount={placementCount}
        originName={originName}
        mode={"EDIT"}
        renderFooter={() => (
          <CreateFooter>
            <Link
              to={"/inventory/placements?selected-tab=placement-group"}
              state={{ publisher }}
            >
              <Button>취소</Button>
            </Link>
            <Button appearance={"primary"} type="submit">
              저장
            </Button>
          </CreateFooter>
        )}
        formRef={formRef}
      />
    </>
  );
};

export default EditPlacementGroup;
