import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { AccountRoleType, CompanyType } from "../../../utils/types";
import { Button } from "rsuite";
import { CreateFooter, PageHeader } from "../../../components/layout";
import UserForm, { UserFormProps } from "./UserForm";
import { AccountService } from "../../../utils/api";

const ViewUser = () => {
  const location = useLocation();
  const { id = "" } = useParams<{ id: string }>();
  const { companyType = "PUBLISHER" } = (location.state as LocationState) || {};
  const [formValue, setFormValue] =
    useState<UserFormProps["formValue"]>(undefined);
  const [placementGroupNames, setPlacementGroupNames] = useState<string[]>([]);

  const fetchData = useCallback(async () => {
    const { data } = await new AccountService().getDetail(id);

    setFormValue({
      id: data.id,
      company_id: data.company_id,
      login_id: data.login_id,
      password: "",
      password_check: "",
      name: data.name,
      email: data.email,
      phone: data.phone,
      role: data.role as AccountRoleType,
      placement_group_ids: data.account_placement_groups.map(
        (item: { id: number; name: string }) => item.id
      ),
      enabled: data.enabled,
      created_at: data.created_at,
      updated_at: data.updated_at,
      last_login_at: data.last_login_at,
    });

    setPlacementGroupNames(
      data.account_placement_groups.map(
        (item: { id: number; name: string }) => item.name
      )
    );
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <PageHeader title={"사용자 조회"} />
      <UserForm
        formValue={formValue}
        onChange={setFormValue}
        mode={"VIEW"}
        companyType={companyType}
        placementGroupNames={placementGroupNames}
        renderFooter={() => (
          <CreateFooter>
            <Link to={`/account/user/edit/${id}`} state={{ companyType }}>
              <Button>수정</Button>
            </Link>
          </CreateFooter>
        )}
      />
    </>
  );
};

export default ViewUser;

type LocationState = {
  companyType: CompanyType;
};
