import React, { useState, useRef, useCallback } from "react";
import { Whisper, Popover } from "rsuite";

const EllipsisPopup = (props: EllipsisPopupProps) => {
  const spanRef = useRef<HTMLSpanElement>(null);
  const [parentWidth, setParentWidth] = useState<number | null>(0);
  const [parentPadding, setParentPadding] = useState<number | null>(0);
  const [childWidth, setChildWidth] = useState<number | null>(0);
  const { type = "table" } = props;

  const handleNormalSpanHover = useCallback(
    (e) => {
      const spanEle = e.currentTarget;
      const parentEle: HTMLElement | null = spanEle
        ? spanEle.closest(
            type === "table" ? ".rs-table-cell-content" : ".ellipsis"
          )
        : null;

      const parentWidthVal: any = parentEle
        ? parentEle.getBoundingClientRect().width
        : null;

      const parentPaddingVal = parentEle
        ? parseInt(
            window
              .getComputedStyle(parentEle, null)
              .getPropertyValue("padding-left")
          ) * 2
        : null;

      const childWidthVal = spanEle
        ? spanEle.getBoundingClientRect().width
        : null;

      if (parentWidth !== parentWidthVal) {
        setParentWidth(parentWidthVal);
      }

      if (parentPadding !== parentPaddingVal) {
        setParentPadding(parentPaddingVal);
      }

      if (childWidth !== childWidthVal) {
        setChildWidth(childWidthVal);
      }
    },
    [childWidth, parentPadding, parentWidth, type]
  );

  if (
    parentWidth !== null &&
    parentPadding !== null &&
    childWidth !== null &&
    childWidth > parentWidth - parentPadding
  ) {
    return (
      <Whisper
        trigger={"hover"}
        placement={"bottomStart"}
        speaker={<Popover style={props.style}>{props.text}</Popover>}
      >
        <span ref={spanRef} onMouseOver={handleNormalSpanHover}>
          {props.text}
        </span>
      </Whisper>
    );
  } else {
    return (
      <span ref={spanRef} onMouseOver={handleNormalSpanHover}>
        {props.text}
      </span>
    );
  }
};

export default EllipsisPopup;

type EllipsisPopupProps = {
  text: string | React.ReactNode;
  type?: "table" | "text";
  style?: React.CSSProperties;
};
