import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { FlexDiv } from "../components/layout";
import { Form } from "components";
import { Input, Button, Schema } from "rsuite";
import { useNavigate, Navigate } from "react-router-dom";
import { AuthService } from "../utils/api";
import { ErrorMessage } from "../components/text";
import { AuthContext } from "utils/context/AuthContext";
import Logo from "../assets/logo.png";

const Login = () => {
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({
    login_id: "",
    password: "",
  });
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { myInfo, setMyInfoHandler } = useContext(AuthContext);

  const handleClickLogin = useCallback(
    async (validate: boolean) => {
      if (!validate) return;
      else {
        try {
          const service = new AuthService();
          await service.login({
            login_id: formValue.login_id,
            password: formValue.password,
          });
          const { data: myInfo } = await service.getMe();
          setMyInfoHandler(myInfo);

          navigate("/");
        } catch (e: any) {
          if (e.response.data?.error_code === "CHECK_ID_PASSWORD") {
            setShowError(true);
            setErrorMessage(
              "아이디 혹은 비밀번호가 일치하지 않습니다. 다시 확인해주세요."
            );
          }
          if (e.response.data?.error_code === "ACCOUNT_DISABLED") {
            setShowError(true);
            setErrorMessage("비활성화된 계정입니다. 관리자에게 문의해주세요.");
          }
        }
      }
    },
    [formValue.login_id, formValue.password, navigate, setMyInfoHandler]
  );

  if (myInfo) return <Navigate to={"/"} />;

  return (
    <LoginWrapper>
      <FlexDiv
        style={{
          alignItems: "flex-end",
        }}
      >
        <img src={Logo} alt={"logo"} style={{ width: 80, marginRight: 10 }} />
        <span
          style={{
            fontFamily: "'lgsmart'",
            fontSize: "30px",
            fontWeight: "bold",
            color: "#6d6e71",
            lineHeight: "19px",
          }}
        >
          SSP
        </span>
      </FlexDiv>
      <LoginForm
        model={model}
        formValue={formValue}
        onChange={setFormValue}
        onSubmit={handleClickLogin}
      >
        <Form.Control
          name={"login_id"}
          accpter={Input}
          style={{ marginBottom: 10, width: "100%", height: 50 }}
          placeholder={"아이디"}
        />
        <Form.Control
          name={"password"}
          accpter={Input}
          style={{ marginBottom: 24, width: "100%", height: 50 }}
          placeholder={"비밀번호"}
          type={"password"}
        />
        {showError && (
          <div style={{ margin: "10px 0" }}>
            <ErrorMessage>{errorMessage}</ErrorMessage>
          </div>
        )}
        <Button
          appearance={"primary"}
          type={"submit"}
          style={{ width: "100%", height: 50 }}
        >
          로그인
        </Button>
      </LoginForm>
      <RecommendedBrowserText>
        계정 발급, 로그인 문의 : 광고상품기획팀 이상오(leesangoh@uplus.co.kr)
      </RecommendedBrowserText>
      <RecommendedBrowserText>
        본 사이트는 데스크탑 크롬 브라우저에 최적화 되어 있습니다.
        <br />
        안정적인 서비스 이용을 위해 가급적 크롬 브라우저로 접속해주시기
        바랍니다.
      </RecommendedBrowserText>
    </LoginWrapper>
  );
};

export default Login;

const model = Schema.Model({
  login_id: Schema.Types.StringType().isRequired("아이디를 입력하세요."),
  password: Schema.Types.StringType().isRequired("비밀번호를 입력하세요."),
});

const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 240px;
`;

const LoginForm = styled(Form)`
  width: 400px;
  margin-top: 40px;
  margin-bottom: 40px;
`;
const RecommendedBrowserText = styled.p`
  margin-top: 40px;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
`;
