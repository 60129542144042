import React, { useCallback, useContext, useState } from "react";
import { CreateFooter, PageHeader } from "../../../components/layout";
import CompanyForm, { CompanyFormProps } from "./CompanyForm";
import { Link, useNavigate } from "react-router-dom";
import { Button, Loader } from "rsuite";
import { CompanyService } from "../../../utils/api";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";

export const initFormValue: CompanyFormProps["formValue"] = {
  name: "",
  type: "PUBLISHER",
  business_registration_number: "--",
  business_registration_certificate: [],
  business_registration_certificate_file_name: "",
  address: "",
  business_type: "",
  business_item: "",
  site_address: "",
  settlement_manager_name: "",
  settlement_manager_email: "@",
  settlement_manager_phone: "--",
  memo: "",
};
const CreateCompany = () => {
  const [formValue, setFormValue] =
    useState<CompanyFormProps["formValue"]>(initFormValue);
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const navigate = useNavigate();
  const [isCheckedDuplicated, setIsCheckedDuplicated] = useState(false);
  const [isDuplicated, setIsDuplicated] = useState<boolean>();

  const handleSubmit = useCallback(
    async (validate: boolean) => {
      if (!validate || isDuplicated || !isCheckedDuplicated) return;

      try {
        setLoading(true);
        if (!formValue) return;
        const {
          name,
          business_registration_number,
          address,
          business_type,
          business_item,
          site_address,
          settlement_manager_name,
          settlement_manager_email,
          settlement_manager_phone,
          memo,
          business_registration_certificate,
        } = formValue;

        const { data } = await new CompanyService().create({
          type: "PUBLISHER",
          name: name,
          business_registration_number,
          address,
          business_type,
          business_item,
          site_address,
          settlement_manager_name,
          settlement_manager_email:
            settlement_manager_email === "@" ? "" : settlement_manager_email,
          settlement_manager_phone:
            settlement_manager_phone === "--" ? "" : settlement_manager_phone,
          memo,
        });

        if (business_registration_certificate[0]) {
          const formData = new FormData();
          formData.append(
            "file",
            business_registration_certificate[0].blobFile as File
          );
          await new CompanyService().uploadRegistrationCertificate(
            data.id,
            formData
          );
        }

        navigate("/account/company");
      } catch (e) {
        setErrorModalOpen(true);
      } finally {
        setLoading(false);
      }
    },
    [formValue, isCheckedDuplicated, isDuplicated, navigate, setErrorModalOpen]
  );

  return (
    <>
      <PageHeader title={"회사 생성"} />
      {loading && <Loader backdrop center style={{ zIndex: 999 }} />}
      <CompanyForm
        formValue={formValue}
        onChange={setFormValue}
        renderFooter={() => (
          <CreateFooter>
            <Link to={"/account/company"}>
              <Button>취소</Button>
            </Link>
            <Button appearance={"primary"} type="submit">
              생성
            </Button>
          </CreateFooter>
        )}
        mode={"CREATE"}
        onSubmit={handleSubmit}
        isCheckedDuplicated={isCheckedDuplicated}
        setIsCheckedDuplicated={setIsCheckedDuplicated}
        isDuplicated={isDuplicated}
        setIsDuplicated={setIsDuplicated}
      />
    </>
  );
};

export default CreateCompany;
