import React, { useCallback } from "react";
import styled from "styled-components";
import SearchIcon from "@rsuite/icons/Search";
import { InputGroup, Input, SelectPickerProps } from "rsuite";
import { SelectPicker } from "components/picker";

export const StyledSelectPicker = styled(SelectPicker)`
  .rs-btn.rs-btn-default.rs-picker-toggle.rs-btn-md {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;
export const StyledInputGroup = styled(InputGroup)`
  &.rs-input-group,
  .rs-input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;

interface SearchProps extends SelectPickerProps<any> {
  searchKey?: string | number;
  searchValue?: string | number;
  onSearchKeyChange?: (value: any, event: any) => void;
  onSearchValueChange?: (value: any, event: any) => void;
  onSearch?: (searchKey: any, searchValue: any) => void;
  maxLength?: number;
  placeholder?: string;
}

const Search = (props: SearchProps) => {
  const {
    data,
    searchKey,
    searchValue,
    disabled,
    onSearchKeyChange,
    onSearchValueChange,
    onSearch,
    placeholder,
    maxLength,
  } = props;

  const handleSearch = useCallback(() => {
    if (onSearch) onSearch(searchKey, searchValue);
  }, [onSearch, searchKey, searchValue]);

  return (
    <div>
      <StyledSelectPicker
        data={data}
        size="md"
        style={{ minWidth: 85 }}
        searchable={false}
        cleanable={false}
        value={searchKey}
        disabled={disabled}
        onChange={onSearchKeyChange}
      />
      <StyledInputGroup
        inside
        size={"md"}
        style={{ display: "inline-block", width: 195, verticalAlign: "middle" }}
        disabled={disabled}
      >
        <Input
          size={"md"}
          placeholder={placeholder || "검색"}
          onChange={onSearchValueChange}
          value={searchValue}
          onPressEnter={handleSearch}
          maxLength={maxLength ? maxLength : undefined}
        />
        <InputGroup.Button onClick={handleSearch}>
          <SearchIcon />
        </InputGroup.Button>
      </StyledInputGroup>
    </div>
  );
};

export default Search;
