import React, { useCallback, useContext, useEffect, useState } from "react";
import { PageHeader } from "components/layout";
import PlacementGroupTable from "./PlacementGroupTable";
import PlacementTable from "./PlacementTable";
import { SelectPicker, Tab, Tabs } from "components";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { CompanyService } from "../../../utils/api";
import { AuthContext } from "../../../utils/context/AuthContext";
import { PublisherState } from "../../../utils/types";

const Placements = () => {
  const query: {
    "selected-tab"?: string;
  } = queryString.parse(useLocation().search);
  const activeKeyFromQuery = query["selected-tab"];
  const [activeKey, setActiveKey] = useState(
    activeKeyFromQuery || "placement-group"
  );
  const state = useLocation().state || {};
  const { publisher: publisherByLocation } = state as PublisherState;

  const [publishers, setPublishers] = useState<{ label: string; value: any }[]>(
    []
  );
  const { myInfo } = useContext(AuthContext);
  const [publisher, setPublisher] = useState<any>(
    myInfo?.company_type === "PUBLISHER"
      ? myInfo?.company_id
      : publisherByLocation || null
  );
  const navigate = useNavigate();

  const fetchPublishers = useCallback(async () => {
    const { data } = await new CompanyService().getIdNameMeta({
      filter: "type : 'PUBLISHER'",
    });

    setPublishers(
      data.map((d: { id: number; name: string }) => ({
        label: d.name,
        value: d.id,
      }))
    );
  }, []);

  useEffect(() => {
    fetchPublishers();
  }, [fetchPublishers]);

  useEffect(() => {
    if (myInfo?.company_type === "PUBLISHER") {
      setPublisher(myInfo?.company_id);
    }
  }, [myInfo]);

  useEffect(() => {
    if (activeKeyFromQuery) setActiveKey(activeKeyFromQuery);
  }, [activeKeyFromQuery]);

  useEffect(() => {
    navigate(`/inventory/placements?selected-tab=${activeKey}`);
  }, [activeKey, navigate]);

  return (
    <>
      <PageHeader title={"게재위치"}>
        {myInfo?.role === "MASTER" && (
          <SelectPicker
            placeholder={"매체사 선택"}
            data={publishers}
            style={{ width: 200, marginLeft: 10 }}
            value={publisher}
            onChange={setPublisher}
          />
        )}
      </PageHeader>
      <Tabs
        activeKey={activeKey}
        onSelect={setActiveKey}
        style={{ marginTop: 5 }}
      >
        <Tab eventKey={"placement-group"}>게재위치 그룹(매체)</Tab>
        <Tab eventKey={"placement"}>게재위치(지면)</Tab>
      </Tabs>

      {activeKey === "placement-group" && (
        <PlacementGroupTable publisher={publisher} />
      )}
      {activeKey === "placement" && <PlacementTable publisher={publisher} />}
    </>
  );
};

export default Placements;
