import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AccountRoleType, CompanyType } from "../../../utils/types";
import { Button, Loader, Modal } from "rsuite";
import { CreateFooter, PageHeader } from "../../../components/layout";
import UserForm, { UserFormProps } from "./UserForm";
import { AccountService } from "../../../utils/api";
import { ErrorModalContext } from "../../../utils/context/ErrorModalContext";
import { AuthContext } from "../../../utils/context/AuthContext";

const EditUser = () => {
  const { id = "" } = useParams<{ id: string }>();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [formValue, setFormValue] =
    useState<UserFormProps["formValue"]>(undefined);
  const [loading, setLoading] = useState(false);
  const { setErrorModalOpen } = useContext(ErrorModalContext);
  const { myInfo } = useContext(AuthContext);
  const navigate = useNavigate();
  const [openInactiveDisableModal, setOpenInactiveDisableModal] =
    useState(false);
  const [placementGroupNames, setPlacementGroupNames] = useState<string[]>([]);
  const [companyType, setCompanyType] = useState<CompanyType | null>(null);

  const fetchData = useCallback(async () => {
    const { data } = await new AccountService().getDetail(id);

    setFormValue({
      id: data.id,
      company_id: data.company_id,
      login_id: data.login_id,
      password: "",
      password_check: "",
      name: data.name,
      email: data.email,
      phone: data.phone,
      role: data.role as AccountRoleType,
      placement_group_ids: data.account_placement_groups.map(
        (item: { id: number; name: string }) => item.id
      ),
      enabled: data.enabled,
      created_at: data.created_at,
      updated_at: data.updated_at,
      last_login_at: data.last_login_at,
    });
    setCompanyType(data.role === "MASTER" ? "SYSTEM_PROVIDER" : "PUBLISHER");

    setPlacementGroupNames(
      data.account_placement_groups.map(
        (item: { id: number; name: string }) => item.name
      )
    );
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSubmit = useCallback(
    async (validate: boolean) => {
      if (!validate) return;

      try {
        setLoading(true);
        if (!formValue) return;
        const {
          id,
          enabled,
          password,
          login_id,
          name,
          email,
          phone,
          role,
          placement_group_ids,
          company_id,
        } = formValue;

        await new AccountService().edit(id as number, {
          id: id as number,
          enabled: enabled as boolean,
          password,
          name,
          login_id,
          email: email === "@" ? "" : email,
          phone: phone === "--" ? "" : phone,
          role,
          placement_group_ids: role === "ADMIN" ? [] : placement_group_ids,
          company_id: company_id || undefined,
          company_type: companyType as CompanyType,
        });

        if (myInfo?.role === "MASTER" || myInfo?.role === "ADMIN")
          navigate(`/account/user?selected-tab=${companyType}`);
        else if (myInfo?.role === "VIEWER")
          navigate(`/account/user/view/${id}`);
      } catch (e: any) {
        if (e.response.data?.error_code === "ALL_INACTIVE") {
          setOpenInactiveDisableModal(true);
        } else {
          setErrorModalOpen(true);
        }
      } finally {
        setLoading(false);
      }
    },
    [companyType, formValue, myInfo?.role, navigate, setErrorModalOpen]
  );

  return (
    <>
      <PageHeader title={"사용자 수정"} />
      {(loading || !companyType) && (
        <Loader backdrop center style={{ zIndex: 999 }} />
      )}
      <UserForm
        formValue={formValue}
        onChange={setFormValue}
        mode={"EDIT"}
        companyType={companyType as CompanyType}
        onSubmit={handleSubmit}
        placementGroupNames={placementGroupNames}
        renderFooter={() => (
          <CreateFooter>
            {myInfo?.role === "VIEWER" ? (
              <Button onClick={() => setOpenCancelModal(true)}>취소</Button>
            ) : (
              <Link to={`/account/user?selected-tab=${companyType}`}>
                <Button>취소</Button>
              </Link>
            )}
            <Button appearance={"primary"} type="submit">
              저장
            </Button>
          </CreateFooter>
        )}
      />
      <Modal
        size={"xs"}
        open={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        role="alertdialog"
      >
        <Modal.Header>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          사용자 수정을 취소 하시겠습니까? 수정한 내용은 저장되지 않습니다.
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpenCancelModal(false)}
            style={{ marginRight: 5 }}
          >
            취소
          </Button>
          <Link to={`/account/user/view/${id}`}>
            <Button appearance="primary">확인</Button>
          </Link>
        </Modal.Footer>
      </Modal>
      <Modal
        size={"xs"}
        open={openInactiveDisableModal}
        onClose={() => setOpenInactiveDisableModal(false)}
        role="alertdialog"
      >
        <Modal.Header>
          <Modal.Title>알림</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {`활성 상태인 ${
            companyType === "SYSTEM_PROVIDER" ? "운영사" : "관리자"
          } 계정이 단 1개일 경우 비활성화할 수 없습니다.`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpenInactiveDisableModal(false)}
            appearance="primary"
          >
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditUser;
