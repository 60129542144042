import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import {
  CurrentLengthText,
  TotalLengthText,
  StyledInputGroup,
} from "./InputCount";

const StyledTextArea = styled.textarea`
  &.rs-input {
    ${({ maxLength }) => {
      return maxLength
        ? `padding-right:${maxLength.toString().length * 2}em !important`
        : "";
    }};
  }

  & ~ .rs-input-group-addon {
    border-left: none;
    left: auto;
    right: 0;
    top: auto !important;
    bottom: 0;
  }
`;

const TextAreaCount = (props: any) => {
  const [count, setCount] = useState(0);
  const propsValue = useMemo(() => props.value?.toString(), [props.value]);
  const { plaintext } = props;

  useEffect(() => {
    setCount(propsValue?.length || 0);
  }, [propsValue]);

  const handleChange = (e: BaseSyntheticEvent) => {
    if (props.onChange) props.onChange(e.target.value);
  };

  if (plaintext) {
    return <span>{propsValue}</span>;
  }
  return (
    <StyledInputGroup inside style={props.style}>
      <StyledTextArea
        className={"rs-input"}
        style={
          props.style?.width
            ? { width: `calc(${props.style?.width} - 2px)` }
            : undefined
        }
        rows={3}
        {...props}
        onChange={handleChange}
      />
      <StyledInputGroup.Addon>
        <CurrentLengthText
          style={{ color: count === 0 ? "var(--rs-gray-500)" : "" }}
        >
          {count}
        </CurrentLengthText>
        <TotalLengthText>{`/${props.maxLength}`}</TotalLengthText>
      </StyledInputGroup.Addon>
    </StyledInputGroup>
  );
};

export default TextAreaCount;
