import React, { ReactNode } from "react";
import {
  Button,
  Checkbox,
  CheckPickerProps as RsuiteCheckPickerProps,
} from "rsuite";
import { CheckPicker } from "components";
import { ItemDataType } from "rsuite/cjs/@types/common";
import { PickerInstance } from "rsuite/esm/Picker";

interface MultiplePickerProps extends RsuiteCheckPickerProps<any> {
  label?: string;
  onValueChange?: (value: any) => void;
}

interface MultiplePickerState {
  originValue: any;
  value: any;
  indeterminate: boolean;
  checkAll: boolean;
}

class MultiplePicker extends React.Component<
  MultiplePickerProps,
  MultiplePickerState
> {
  private checkPickerRef = React.createRef<PickerInstance>();
  state = {
    originValue: [],
    value: [],
    indeterminate: false,
    checkAll: false,
  };

  allValue = this.props.data.map((item) => item.value);

  static getDerivedStateFromProps(
    props: MultiplePickerProps,
    state: MultiplePickerState
  ) {
    if (props.value && props.value !== state.originValue) {
      return {
        originValue: props.value,
        value: props.value,
        indeterminate:
          props.value.length > 0 && props.value.length < props.data.length,
        checkAll:
          props.value.length > 0 && props.value.length === props.data.length,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: MultiplePickerProps) {
    if (prevProps.data !== this.props.data) {
      this.allValue = this.props.data.map((item) => item.value);
    }
  }

  renderStatusValue = (value: any[], items: ItemDataType[]) => {
    const { label, data } = this.props;
    return (
      <div>
        {label && <span>{`${label} : `}</span>}
        {value.length === 1 && items[0]?.label}
        {value.length > 1 &&
          value.length < data.length &&
          `${items[0]?.label} 외 ${value.length - 1}개`}
        {data.length > 1 && data.length === value.length && "전체"}
      </div>
    );
  };

  handleChange = (value: any) => {
    const { allValue } = this;
    this.setState({
      value,
      indeterminate: value.length > 0 && value.length < allValue.length,
      checkAll: value.length === allValue.length,
    });
  };

  handleCheckAll = (value: any, checked: boolean) => {
    const { allValue } = this;
    const nextValue = checked ? allValue : [];
    this.setState({
      value: nextValue,
      indeterminate: false,
      checkAll: checked,
    });
  };

  handleClickApply = () => {
    const { cleanable = false } = this.props;
    if (!this.state.value.length && !cleanable) {
      window.alert("하나 이상 선택하세요.");
      return;
    }
    if (this.props.onValueChange) this.props.onValueChange(this.state.value);
    if (this.checkPickerRef) {
      this.checkPickerRef?.current?.close?.();
    }
  };

  handleExit = () => {
    const { originValue } = this.state;
    const { allValue } = this;

    this.setState({
      value: originValue,
      indeterminate:
        originValue.length > 0 && originValue.length < allValue.length,
      checkAll: originValue.length === allValue.length,
    });
  };

  render() {
    const { props } = this;
    const { onValueChange, ...restProps } = props;
    const { indeterminate, checkAll, value } = this.state;
    return (
      <CheckPicker
        {...restProps}
        ref={this.checkPickerRef}
        disabledItemValues={
          props.disabledItemValues ? props.disabledItemValues : undefined
        }
        renderValue={this.renderStatusValue}
        renderMenu={(menu: ReactNode) => {
          return (
            <div>
              <div
                className="rs-check-item"
                style={{ borderBottom: `1px solid var(--rs-border-primary)` }}
              >
                <Checkbox
                  disabled={
                    !!(
                      props.disabledItemValues &&
                      props.disabledItemValues.length !== 0
                    )
                  }
                  indeterminate={indeterminate}
                  checked={checkAll}
                  onChange={this.handleCheckAll}
                >
                  전체
                </Checkbox>
              </div>
              {menu}
            </div>
          );
        }}
        renderExtraFooter={() => (
          <div
            style={{
              padding: "11px 27px",
              textAlign: "center",
              borderTop: `1px solid var(--rs-border-primary)`,
            }}
          >
            <Button
              size="sm"
              onClick={this.handleClickApply}
              appearance={"primary"}
            >
              적용
            </Button>
          </div>
        )}
        onChange={this.handleChange}
        value={value}
        onExit={this.handleExit}
      />
    );
  }
}

export default MultiplePicker;
