import React from "react";
import { Button, Modal } from "rsuite";
import RemindFill from "@rsuite/icons/RemindFill";

const ErrorModal = (props: { open: boolean; onClose: () => void }) => {
  return (
    <Modal
      size={"xs"}
      open={props.open}
      onClose={props.onClose}
      role="alertdialog"
    >
      <Modal.Header>
        <Modal.Title>알림</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RemindFill
            style={{
              color: "#ffb300",
              fontSize: 24,
              marginRight: 10,
            }}
          />
          <div>
            <div>에러가 발생했습니다.</div>
            <div>잠시 후 다시 시도해주세요.</div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="primary" onClick={props.onClose}>
          확인
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
