import React, { useCallback, useContext, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Container, Content, Header, Loader, Sidebar } from "rsuite";
import { GNB, LNB } from "components";
import { AuthContext } from "utils/context/AuthContext";

const PublicLayout = () => {
  const [expanded, setExpanded] = useState(true);
  const handleChangeExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);
  const { myInfo } = useContext(AuthContext);

  if (myInfo === undefined) {
    // 아직 응답이 오지 않음
    return <Loader center />;
  }
  if (myInfo === null) {
    // 로그인 정보가 없음
    return <Navigate to={"/login"} />;
  }
  return (
    <Container>
      <Header>
        <GNB handleChangeExpanded={handleChangeExpanded} />
      </Header>
      <Container style={{ minHeight: "calc(100vh - 56px)" }}>
        <Sidebar
          style={{
            width: expanded ? 210 : 56,
            flex: expanded ? "0 0 210px" : "0 0 56px",
          }}
        >
          <LNB expanded={expanded} />
        </Sidebar>
        <Content>
          <Outlet />
        </Content>
      </Container>
    </Container>
  );
};

export default PublicLayout;
