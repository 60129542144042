import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PageHeader, TableActionsContainer } from "../../../components/layout";
import {
  DatePicker,
  InputNumber,
  Search,
  SelectPicker,
  Table,
} from "../../../components";
import { Button, Divider, Stack, Table as RsuiteTable } from "rsuite";
import moment from "moment";
import { Icon } from "@rsuite/icons";
import { RiExchangeDollarLine } from "react-icons/ri";
import { InfoMessage } from "../../../components/text";
import { CurrencyType, SortType } from "../../../utils/types";
import {
  DateCell,
  FloatCell,
  NumberCell,
  TextCell,
} from "../../../components/table/Cells";
import { AuthContext } from "../../../utils/context/AuthContext";
import { CompanyService, SettlementService } from "../../../utils/api";
import _ from "lodash";
import { underToUpper } from "../../../utils";
import {
  getComparatorsString,
  getOperatorsString,
} from "../../../front-utils/filter/dynamicFilter";
import fileDownload from "js-file-download";

const { Cell, Column, HeaderCell, ColumnGroup } = RsuiteTable;

const Settlements = () => {
  const [startDate, setStartDate] = useState<Date | null>(
    moment().subtract(1, "month").startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState<Date | null>(
    moment().subtract(1, "month").startOf("month").toDate()
  );
  const [data, setData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(0);
  const [sort, setSort] = useState<{
    sortColumn: string;
    sortType: SortType;
  } | null>(null);
  const [exchangeRate, setExchangeRate] = useState<string>("");
  const [appliedExchangeRate, setAppliedExchangeRate] =
    useState<string>(exchangeRate);
  const [isInitClicked, setIsInitClicked] = useState(false);
  const [publishers, setPublishers] = useState<{ label: string; value: any }[]>(
    []
  );
  const { myInfo } = useContext(AuthContext);
  const [publisher, setPublisher] = useState<any>(
    myInfo?.company_type === "PUBLISHER" ? myInfo?.company_id : null
  );
  const [searchKey, setSearchKey] =
    useState<"adNetwork.name">("adNetwork.name");
  const [searchValue, setSearchValue] = useState<string>("");
  const [appliedSearchValue, setAppliedSearchValue] =
    useState<string>(searchValue);
  const [appliedParams, setAppliedParams] = useState({
    startDate,
    endDate,
  });
  const [tableLoading, setTableLoading] = useState(false);

  const fetchPublishers = useCallback(async () => {
    if (myInfo?.role !== "MASTER") return;
    const { data } = await new CompanyService().getIdNameMeta({
      filter: "type : 'PUBLISHER'",
    });

    setPublishers(
      data.map((d: { id: number; name: string }) => ({
        label: d.name,
        value: d.id,
      }))
    );
  }, [myInfo?.role]);

  useEffect(() => {
    fetchPublishers();
  }, [fetchPublishers]);

  useEffect(() => {
    setPage(1);
  }, [size, appliedParams, publisher, appliedSearchValue]);

  useEffect(() => {
    if (moment(startDate).isAfter(moment(endDate), "month")) {
      setEndDate(startDate);
    }
  }, [endDate, startDate]);

  const handleSortChange = useCallback((sortColumn, sortType) => {
    setSort({ sortColumn, sortType });
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setTableLoading(true);
      const { data } = await new SettlementService().get({
        page,
        size,
        sort: sort
          ? underToUpper(sort.sortColumn) + "," + sort.sortType
          : ["settlementDate,desc", "adNetwork.name,asc"],
        filter: getOperatorsString(
          "and",
          [
            getComparatorsString(
              ">:",
              "settlementDate",
              moment(appliedParams.startDate).format("YYYY-MM-DD")
            ),
            getComparatorsString(
              "<:",
              "settlementDate",
              moment(appliedParams.endDate).format("YYYY-MM-DD")
            ),
            publisher
              ? getComparatorsString(":", "adNetwork.publisher.id", publisher)
              : "",
            appliedSearchValue !== ""
              ? getComparatorsString("~", searchKey, `%${appliedSearchValue}%`)
              : "",
          ].filter((v) => v !== "")
        ),
      });

      const count = Math.pow(10, 2);
      setData(
        data.content.map(
          (d: {
            currency: CurrencyType;
            revenue: number;
            settlement_amount: number;
          }) => ({
            ...d,
            revenue_usd: d.currency === "USD" ? d.revenue : null,
            revenue_krw:
              d.currency === "KRW"
                ? d.revenue
                : appliedExchangeRate
                ? (Math.floor(d.revenue * count) / count) *
                  parseInt(appliedExchangeRate)
                : null,
            settlement_amount_usd:
              d.currency === "USD" ? d.settlement_amount : null,
            settlement_amount_krw:
              d.currency === "KRW"
                ? d.settlement_amount
                : appliedExchangeRate
                ? (Math.floor(d.settlement_amount * count) / count) *
                  parseInt(appliedExchangeRate)
                : null,
          })
        )
      );
      setTotal(data.total_elements);
    } catch (e) {
      console.log(e);
    } finally {
      setTableLoading(false);
    }
  }, [
    size,
    page,
    sort,
    appliedParams.startDate,
    appliedParams.endDate,
    publisher,
    appliedSearchValue,
    searchKey,
    appliedExchangeRate,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleApplyExchangeRate = useCallback(() => {
    if (exchangeRate === "") return;
    setAppliedExchangeRate(exchangeRate);
  }, [exchangeRate]);

  const handleInitExchangeRate = useCallback(() => {
    setExchangeRate("");
    setAppliedExchangeRate("");
    setIsInitClicked(true);
  }, []);

  const handleSearchKeyChange = useCallback((v: "adNetwork.name") => {
    setSearchKey(v);
    setSearchValue("");
  }, []);

  const delayedSetSearchValue = useMemo<any>(
    () => _.debounce((v: string) => setAppliedSearchValue(v), 500),
    []
  );

  const handleSearchValueChange = useCallback((v: string) => {
    setSearchValue(v);
  }, []);

  useEffect(() => {
    delayedSetSearchValue(searchValue);
  }, [searchValue, delayedSetSearchValue]);

  const handleClickFetch = useCallback(() => {
    setAppliedParams({
      startDate,
      endDate,
    });
  }, [startDate, endDate]);

  const handleClickDownload = useCallback(async () => {
    try {
      const { data } = await new SettlementService().download({
        sort: sort
          ? underToUpper(sort.sortColumn) + "," + sort.sortType
          : ["settlementDate,desc", "adNetwork.name,asc"],
        filter: getOperatorsString(
          "and",
          [
            getComparatorsString(
              ">:",
              "settlementDate",
              moment(appliedParams.startDate).format("YYYY-MM-DD")
            ),
            getComparatorsString(
              "<:",
              "settlementDate",
              moment(appliedParams.endDate).format("YYYY-MM-DD")
            ),
            publisher
              ? getComparatorsString(":", "adNetwork.publisher.id", publisher)
              : "",
            appliedSearchValue !== ""
              ? getComparatorsString("~", searchKey, `%${appliedSearchValue}%`)
              : "",
          ].filter((v) => v !== "")
        ),
        "exchange-rate": appliedExchangeRate
          ? parseInt(appliedExchangeRate)
          : undefined,
        "start-date": moment(appliedParams.startDate).format("YYYY-MM-DD"),
        "end-date": moment(appliedParams.endDate).format("YYYY-MM-DD"),
      });

      fileDownload(
        data,
        `정산내역-${
          myInfo?.role !== "MASTER"
            ? myInfo?.company_name
            : publisher
            ? publishers.find((pub) => pub.value === publisher)?.label
            : "매체사전체"
        }-${moment(appliedParams.startDate).format("YYYYMM")}-${moment(
          appliedParams.endDate
        ).format("YYYYMM")}.xlsx`
      );
    } catch (e) {
      console.log(e);
    }
  }, [
    sort,
    appliedParams.startDate,
    appliedParams.endDate,
    publisher,
    appliedSearchValue,
    searchKey,
    appliedExchangeRate,
    myInfo?.role,
    myInfo?.company_name,
    publishers,
  ]);

  return (
    <>
      <PageHeader title={"정산 내역"}>
        {myInfo?.role === "MASTER" && (
          <SelectPicker
            placeholder={"매체사 선택"}
            data={publishers}
            value={publisher}
            onChange={setPublisher}
            style={{ width: 200, marginLeft: 10 }}
          />
        )}
      </PageHeader>
      <Stack
        spacing={8}
        style={{
          padding: "9px 25px",
          borderBottom: "1px solid var(--rs-border-primary)",
        }}
      >
        <DatePicker
          format="yyyy-MM"
          value={startDate}
          onChange={setStartDate}
          disabledDate={(date) => moment(date).isSameOrAfter(moment(), "month")}
        />
        ~
        <DatePicker
          format="yyyy-MM"
          value={endDate}
          onChange={setEndDate}
          disabledDate={(date) =>
            moment(date).isBefore(moment(startDate), "month") ||
            moment(date).isSameOrAfter(moment(), "month")
          }
        />
        <Button appearance={"primary"} onClick={handleClickFetch}>
          조회
        </Button>
      </Stack>
      <Stack
        spacing={8}
        style={{
          padding: "9px 25px",
          borderBottom: "1px solid var(--rs-border-primary)",
        }}
      >
        <Icon
          as={RiExchangeDollarLine}
          style={{
            fontSize: "18px",
            color: "var(--rs-gray-600)",
            marginLeft: 4,
            verticalAlign: "middle",
          }}
        />
        <span>1달러 기준 환율</span>
        <InputNumber
          maxLength={4}
          value={exchangeRate}
          onChange={setExchangeRate}
          updateToPropValue={isInitClicked}
          setUpdateToPropValue={setIsInitClicked}
        />
        <span>원</span>
        <Button appearance={"primary"} onClick={handleApplyExchangeRate}>
          적용
        </Button>
        <Button onClick={handleInitExchangeRate}>초기화</Button>
      </Stack>
      <TableActionsContainer>
        <Stack>
          <span
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "var(--rs-gray-800)",
              height: "50px",
            }}
          >
            조회 결과
          </span>
          <Divider vertical />
          <span>총 {total}개</span>
          <InfoMessage style={{ marginLeft: 8 }}>
            *실제 네트워크에서 지급하는 정산 금액과 차이가 있을 수 있습니다.
          </InfoMessage>
        </Stack>
        <Stack spacing={8}>
          <Search
            data={searchKeys}
            searchKey={searchKey}
            onSearchKeyChange={handleSearchKeyChange}
            searchValue={searchValue}
            onSearchValueChange={handleSearchValueChange}
          />
          <Button onClick={handleClickDownload}>다운로드</Button>
        </Stack>
      </TableActionsContainer>
      <Table
        data={data}
        headerHeight={80}
        pagination={true}
        total={total}
        activePage={page}
        onChangePage={setPage}
        displayLength={size}
        onChangeLength={setSize}
        sortColumn={sort?.sortColumn}
        sortType={sort?.sortType}
        onSortColumn={handleSortChange}
        loading={tableLoading}
      >
        {myInfo?.role === "MASTER" && (
          <Column width={100} sortable>
            <HeaderCell style={{ padding: "30px 10px 30px 25px" }}>
              매체사
            </HeaderCell>
            <TextCell dataKey="ad_network.publisher.name" />
          </Column>
        )}
        <Column width={100} sortable>
          <HeaderCell style={{ padding: "30px 10px 30px 25px" }}>
            정산월
          </HeaderCell>
          <DateCell dataKey="settlement_date" format={"YYYY-MM"} />
        </Column>
        <Column width={100} flexGrow={1} sortable>
          <HeaderCell style={{ padding: "30px 10px" }}>네트워크</HeaderCell>
          <Cell dataKey="ad_network.name" />
        </Column>

        <ColumnGroup header="수익" align={"right"}>
          <Column width={110} flexGrow={1} align={"right"}>
            <HeaderCell>USD</HeaderCell>
            <FloatCell dataKey="revenue_usd" />
          </Column>

          <Column width={110} flexGrow={1} align={"right"}>
            <HeaderCell>KRW</HeaderCell>
            <NumberCell dataKey="revenue_krw" />
          </Column>
        </ColumnGroup>
        <Column width={110} flexGrow={1} sortable align={"right"}>
          <HeaderCell style={{ padding: "30px 10px" }}>조정금액</HeaderCell>
          <NumberCell dataKey="adjusted_amount" />
        </Column>

        <Column width={110} flexGrow={1} sortable align={"right"}>
          <HeaderCell style={{ padding: "30px 10px" }}>이월수익</HeaderCell>
          <NumberCell dataKey="carry_over_revenue" />
        </Column>
        <ColumnGroup header="최종 정산 금액" align={"right"}>
          <Column width={110} flexGrow={1} align={"right"}>
            <HeaderCell>USD</HeaderCell>
            <FloatCell dataKey="settlement_amount_usd" />
          </Column>

          <Column width={110} flexGrow={1} align={"right"}>
            <HeaderCell>KRW</HeaderCell>
            <NumberCell dataKey="settlement_amount_krw" />
          </Column>
        </ColumnGroup>
      </Table>
    </>
  );
};

export default Settlements;

const searchKeys = [
  {
    label: "네트워크",
    value: "adNetwork.name",
  },
];
